import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { v4 } from "uuid";

//
import Card from "../../../components/card/Card";
import DocItem from "./components/DocItem";
import Select from "../../../components/select/Select";
import SearchIcon from "../../../components/search/Search";
import Input from "../../../components/input/Input";
import Loader from "../../../components/loader/Loader";
import Modal from "../../../components/modal/Modal";
import DocumentModal from "../../../components/doc-modal/DocumentModal";
import EmptyText from "../../../components/epmty-text/EmptyText";
import { msToNormTime } from "../../../util/date";
import { useGetDocs, useGetAct } from "../../../store/doc/hook";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { useAppContext } from "../../../store/Context";

//
const select = [
  { id: v4(), title: "Все типы", value: "all" },
  { id: v4(), title: "Выплаты", value: "act" },
  { id: v4(), title: "Отчёты", value: "report" },
  { id: v4(), title: "Договоры", value: "contract" },
  { id: v4(), title: "Заказ-наряды", value: "service" },
];

const dateList = [
  { id: v4(), value: 0, title: "По дате возрастания" },
  { id: v4(), value: 1, title: "По дате убывания" },
];

export default function Doc() {
  // helper
  const { state } = useLocation();
  const { width } = useWindowDimensions();

  // context
  const {
    state: { navH },
  } = useAppContext();

  // state
  const [searchInput, setSearchInput] = useState(false);
  const [typeValue, setTypeValue] = useState("all");
  const [dateValue, setDateValue] = useState(0);
  const [search, setSearch] = useState("");
  const [modal, setModal] = useState(null);
  const [nav_title, setNavTitle] = useState(0);

  // query
  let { isLoading, data: list } = useGetDocs();
  list = list?.data?.data || [];
  let { data: act } = useGetAct(modal?.doc_id, modal?.date_created, {
    enabled: !!modal,
  });
  act = act?.data?.data;

  // lifecycle
  useEffect(() => {
    window.addEventListener("scroll", onScrollWindow);
    if (state?.data) {
      setTypeValue(state.data);
    }
    return () => {
      window.removeEventListener("scroll", onScrollWindow);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // method
  function onScrollWindow(e) {
    document.querySelectorAll(".doc__date__section").forEach((i, idx) => {
      if (i.getBoundingClientRect().y < 190) {
        setNavTitle(idx);
      }
    });
  }

  const getReadyList = useMemo(() => {
    if (!list.length) return {};

    let searchedList = [...list].filter((i) => i.status);
    if (search) {
      searchedList = searchedList.filter(
        (i) =>
          (i.name && i.name.includes(search)) ||
          (i.note && i.note.includes(search))
      );
    }

    let filterType = [];
    if (typeValue === "all") {
      filterType = searchedList;
    } else {
      filterType = searchedList.filter((i) => i.type === typeValue);
    }

    if (dateValue === 0) {
      filterType = filterType.sort((a, b) => b.date_created - a.date_created);
    } else {
      filterType = filterType.sort((a, b) => a.date_created - b.date_created);
    }

    let tempObj = {};
    filterType.forEach((i) => {
      let date = msToNormTime(i.date_created, 6);
      tempObj[date] = tempObj[date] ? [...tempObj[date], i] : [i];
    });

    return tempObj;
  }, [dateValue, list, search, typeValue]);

  return (
    <StyledDoc searchInput={searchInput} navH={navH}>
      <h1>Мои документы</h1>
      <main>
        <Card rentbron>
          <div className="doc__main__content">
            <nav id="table__nav">
              <div className="nav__left">
                <div className="mobile__search">
                  <h3>
                    {searchInput
                      ? "Поиск"
                      : width < 769
                      ? "Мои документы"
                      : Object.keys(getReadyList)[nav_title]}
                  </h3>
                  {width < 769 && (
                    <SearchIcon
                      isOpen={searchInput}
                      changeSearchIsOpen={(v) => {
                        if (!v) {
                          setSearch("");
                        }
                        setSearchInput(v);
                      }}
                    />
                  )}
                </div>
              </div>

              <div className="nav__right">
                {searchInput ? (
                  <Input
                    fill={width < 769 ? 1 : 0}
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                ) : (
                  <>
                    <Select
                      options={select}
                      value={typeValue}
                      onChange={(v) => setTypeValue(v)}
                    />
                    <Select
                      dateSort={1}
                      options={dateList}
                      value={dateValue}
                      onChange={(v) => setDateValue(v)}
                    />
                  </>
                )}

                {width > 768 && (
                  <SearchIcon
                    isOpen={searchInput}
                    changeSearchIsOpen={(v) => {
                      if (!v) {
                        setSearch("");
                      }
                      setSearchInput(v);
                    }}
                  />
                )}
              </div>
            </nav>

            <section className="docs__list__wrapper">
              {isLoading && <Loader />}
              {Object.entries(getReadyList).length > 0 ? (
                Object.entries(getReadyList).map((i, idx) => (
                  <div className="doc__date__section" key={i[0]}>
                    {idx !== 0 && <h1>{i[0]}</h1>}
                    <ul>
                      {i[1].map((j) => (
                        <DocItem
                          key={j.doc_id}
                          doc={j}
                          onOpenPdfModal={() =>
                            setModal({
                              doc_id: j.doc_id,
                              date_created: j.date_created,
                            })
                          }
                        />
                      ))}
                    </ul>
                  </div>
                ))
              ) : (
                <EmptyText />
              )}
            </section>
          </div>
        </Card>
      </main>
      <Modal
        title="АКТ"
        isOpen={modal}
        onClose={() => setModal(false)}
        topSm={1}
      >
        <DocumentModal act={act} />
      </Modal>
    </StyledDoc>
  );
}

const StyledDoc = styled.div`
  position: relative;

  & > h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    color: var(--dark);
    margin-bottom: 30px;
  }

  .doc__main__content {
    padding-bottom: 30px;
    position: relative;

    & > nav {
      background-color: var(--light);
      z-index: 2;
      max-height: 108px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 30px 30px 30px 60px;
      position: sticky;
      position: -webkit-sticky;
      top: ${({ navH }) => navH + "px"};
      border-radius: 30px 30px 0 0;

      .nav__left {
        h3 {
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }

      .nav__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
      }
    }

    .docs__list__wrapper {
      padding: 0 30px;
      position: relative;
      min-height: 300px;

      .not__found__text {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark-50);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      .doc__date__section {
        & > h1 {
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          padding-left: 30px;
          margin-bottom: 30px;
          color: var(--dark);
        }
        & > ul {
          margin-bottom: 43px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 10px;

          & > li {
            &:nth-child(2n) {
              background-color: var(--dark-3);
            }
          }
        }
      }
    }

    .download {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (max-width: 768px) {
    .modal__act {
      width: 100%;
      border-radius: 0;

      &.active__modal {
        right: 0;
      }
    }

    & > h1 {
      display: none;
    }

    .doc__main__content {
      & > nav {
        position: unset;
        max-height: initial;
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr;
        gap: 22px;
        background-color: var(--light);
        box-shadow: 0px 0px 10px var(--primary-dark-5);
        border-radius: 0px 0px 30px 30px;
        margin: 0 0 20px 0;
        padding: 44px 30px 30px 30px;
        .nav__left {
          padding: 0 5px;
          .mobile__search {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            & > h3 {
              font-size: 16px;
              font-weight: 600;
              line-height: 20px;
              letter-spacing: 0em;
            }
          }
        }
        .nav__right {
          display: -ms-grid;
          display: grid;
          grid-template-columns: ${({ searchInput }) =>
            searchInput ? "1fr" : "1fr 1fr"};
          gap: 10px;
        }
      }

      .docs__list__wrapper {
        padding: 0;

        .doc__date__section {
          background-color: var(--light);
          box-shadow: 0px 0px 10px var(--primary-dark-5);
          margin: 0 0 20px 0;
          border-radius: 30px;
          padding: 30px 0;
          & > h1 {
            text-align: center;
            margin: 0 0 30px 0;
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
          }
          & > ul {
            margin: 0 0 10px 0;
            & > li {
              &:nth-child(2n) {
                border-radius: 0;
              }
            }
          }
        }
      }
    }
  }
  @media (max-width: 576px) {
    .doc__main__content {
      & > nav {
        .nav__right {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media (max-width: 440px) {
    .doc__main__content {
      & > nav {
        padding: 44px 15px 30px 15px;
      }
    }
  }
`;
