import React from "react";
import styled, { css } from "styled-components";

//
import Button from "../button/Button";

export default function ResetCacheModal({
  open,
  onClose,
  onSubmit,
  lastUpdate,
}) {
  return (
    <StyledResetCacheModal open={open}>
      <main>
        <nav>
          <h1>Обновление</h1>
          <i className="icon icon-lg icon-close" onClick={onClose}></i>
        </nav>
        <p>
          Последнее обновление <br /> данных {lastUpdate}
        </p>
        <footer>
          <Button color="primary" p={[16, 30]} textUpperCase={1} onClick={onSubmit}>
            Обновить
          </Button>
        </footer>
      </main>
    </StyledResetCacheModal>
  );
}

const StyledResetCacheModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 6;
  transition: 0.25s linear;
  visibility: hidden;
  background-color: transparent;

  & > main {
    background: var(--light);
    box-shadow: 0px 0px 20px var(--primary-dark-40);
    border-radius: 30px;
    padding: 30px;
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 100%);
    transition: 0.4s linear;

    nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 150px;
      margin-bottom: 60px;

      h1 {
        font-family: Montserrat;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
      }

      i {
        background-color: var(--dark);
      }
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      text-align: center;
      margin-bottom: 60px;
    }

    footer {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  ${({ open }) =>
    open &&
    css`
      visibility: visible;
      background-color: var(--dark-30);
      & > main {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    `}
`;
