const obj = {
  BASE_URL: "https://api.almak.rent:62228/prokat8/hs/api_partner",
  CACHE_NAME: "APP_CACHE",
  ANAL_FROM_TO: "ANAL_FROM_TO",
  APP_TOKEN: "APP_TOKEN",
  USER_PHONE: "USER_PHONE",
  DOC_DATE: "DOC_DATE",
  EXPIRY_DAYS: 7, // 0.00069 means 1 min
};

export default obj;
