import React from "react";
import styled, { css } from "styled-components";

//
export default function CardTwo({ children, ...props }) {
  return <StyleCardTwo {...props}>{children}</StyleCardTwo>;
}

const StyleCardTwo = styled.div`
  background: var(--light);
  box-shadow: 0px 2px 10px rgba(26, 43, 70, 0.05);
  border-radius: 30px;
  position: relative;

  ${({ multiStage }) =>
    multiStage &&
    css`
      border-radius: 0 0 30px 30px;
    `}

  ${({ inforeject }) =>
    inforeject &&
    css`
      @media (max-width: 768px) {
        border-radius: 30px 30px 0 0;
      }
    `}
 
  ${({ reject }) =>
    reject &&
    css`
      @media (max-width: 768px) {
        padding: 30px 30px 30px 30px;
        border-radius: 0 0 30px 30px;
      }
    `}
`;
