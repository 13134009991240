import React from "react";
import styled from "styled-components";

//
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { msToNormTime } from "../../../../util/date";

export default function JournalTo({ to, load }) {
  const { width } = useWindowDimensions();

  return (
    <StyledJournalTo>
      <nav className="to__top">
        <section className="to__recent">
          <Card mobile={1}>
            {load && <Loader />}
            <div className="to__recent__main">
              <section>
                <div className="i__tool__wrap">
                  <i className="icon icon-lg icon-calendar-tool"></i>
                </div>
                <div>
                  <h1>Последнее ТО</h1>
                  <p>
                    2 сентября 2022 на пробеге{" "}
                    <NumberFormat value={to?.recent.run_last} suffix=" км" />
                  </p>
                </div>
              </section>
              {width < 769 && (
                <>
                  <section>
                    <div className="i__tool__wrap">
                      <i className="icon icon-lg icon-calendar"></i>
                    </div>
                    <div>
                      <h1>Следующее ТО</h1>
                      <p>
                        На пробеге{" "}
                        <NumberFormat
                          value={to?.recent.run_next}
                          suffix=" км"
                        />
                      </p>
                    </div>
                  </section>
                  <footer className="to__footer">
                    ТО каждые{" "}
                    <NumberFormat value={to?.recent.run_each} suffix=" км" />
                  </footer>
                </>
              )}
            </div>
          </Card>
        </section>
        {width > 768 && (
          <section className="to__next">
            <Card mobile={1}>
              <div className="to__next__desc">
                <p>Следующее ТО на</p>
                <h1>
                  <NumberFormat value={to?.recent.run_next} suffix=" км" />
                </h1>
              </div>
              <footer className="to__footer">
                ТО каждые{" "}
                <NumberFormat value={to?.recent.run_each} suffix=" км" />
              </footer>
            </Card>
          </section>
        )}
      </nav>
      <main className="to__list">
        <Card mobile={1}>
          {load && <Loader />}
          <ul>
            <li className="to__list__nav">
              <p>Исполнитель</p>
              <div>
                <p>Дата</p>
                <p>Пробег на момент ТО</p>
              </div>
            </li>
            {to?.history
              ?.sort((a, b) => b.date - a.date)
              .map((i, idx) => (
                <li className="to__item" key={idx}>
                  <div className="to__item__left">
                    <div className="i__tool__wrap">
                      <i className="icon icon-tool"></i>
                    </div>
                    <h1>{i.who || "-"}</h1>
                  </div>
                  <div className="to__item__right">
                    <div className="to__item__date">
                      <p>Дата</p>
                      <h1>{msToNormTime(i.date, 3)}</h1>
                    </div>
                    <div className="to__item__probeg">
                      <p>Пробег на момент ТО</p>
                      <h1>
                        <NumberFormat value={i.run} suffix=" км" />
                      </h1>
                    </div>
                  </div>
                </li>
              ))}
          </ul>
        </Card>
      </main>
    </StyledJournalTo>
  );
}

const StyledJournalTo = styled.div`
  .to {
    &__top {
      display: -ms-grid;
      display: grid;
      grid-template-columns: auto 280px;
      gap: 20px;
    }

    &__recent {
      &__main {
        padding: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
        height: 100%;

        section {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;
          height: 100%;
        }

        .i__tool__wrap {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          background-color: var(--primary-10);

          i {
            background-color: var(--primary);
          }
        }

        h1 {
          font-size: 18px;
          font-weight: 500;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
    }

    &__next {
      &__desc {
        padding: 25px;
        text-align: center;

        p {
          font-size: 10px;
          font-weight: 400;
          line-height: 12px;
          letter-spacing: 0em;
          color: var(--dark-70);
          text-transform: uppercase;
          transform: translateY(4px);
        }

        h1 {
          font-size: 20px;
          font-weight: 600;
          line-height: 24px;
          letter-spacing: 0em;
          color: var(--dark);
          transform: translateY(4px);
        }
      }
    }

    &__footer {
      padding: 12px;
      text-align: center;
      background-color: var(--primary-5);
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      letter-spacing: 0em;
      color: var(--dakr-70);
      border-radius: 0 0 30px 30px;
    }

    &__list {
      margin-top: 20px;

      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
        padding: 30px;
        min-height: 250px;
      }

      &__nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 0 20px 30px;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          text-transform: uppercase;
          color: var(--dark);
        }

        div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 116px;
        }
      }
    }

    &__item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 24px 30px;
      border-radius: 20px;

      &:nth-child(2n + 1) {
        background-color: var(--dark-3);
      }

      &__left {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 30px;

        .i__tool__wrap {
          width: 52px;
          height: 52px;
          border-radius: 50%;
          background-color: var(--primary-20);
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;

          i {
            background-color: var(--primary);
          }
        }

        h1 {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }

      &__right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 60px;
      }

      &__date {
        padding: 16px 37px;
        background: var(--dark-5);
        border-radius: 14px;

        p {
          display: none;
        }

        h1 {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }

      &__probeg {
        padding: 16px 30px;
        p {
          display: none;
        }

        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }
  }

  @media (max-width: 992px) {
    .to {
      &__top {
        grid-template-columns: 1fr;
      }

      &__list {
        &__nav {
          div {
            gap: 70px;
          }
        }
      }

      &__item {
        &__left {
          flex-direction: column;
          gap: 20px;
        }

        &__right {
          gap: 15px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    .to {
      &__recent {
        &__main {
          padding: 30px 0 0 0;

          section {
            gap: 20px;
            padding: 0 30px;
          }

          .i__tool__wrap {
            width: 48px;
            height: 48px;

            i {
              width: 24px;
              height: 24px;
            }
          }

          h1 {
            font-size: 16px;
            line-height: 20px;
            margin-bottom: 5px;
          }

          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      &__footer {
        padding: 20px;
        font-size: 14px;
        line-height: 14px;
      }

      &__list {
        ul {
          padding: 0;
        }

        &__nav {
          display: none;
        }
      }

      &__item {
        padding: 30px;
        border-radius: 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;

        &__left {
          gap: 20px;
          flex-direction: row;

          .i__tool__wrap {
            width: 40px;
            height: 40px;

            i {
              width: 20px;
              height: 20px;
            }
          }
        }

        &__right {
          gap: 20px;
        }

        &__date,
        &__probeg {
          background-color: transparent;
          padding: 14px 16px;
          border: 1px solid var(--primary-dark-15);
          border-radius: 14px;

          h1 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          p {
            display: block;
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dakr-70);
            margin-bottom: 5px;
          }
        }
      }
    }
  }
`;
