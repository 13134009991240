import React from "react";
import styled from "styled-components";
import cl from "classnames";

//
import Card from "../../../../components/card/Card";
import FilterModal from "./FilterModal";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import YearsModal from "./YearsModal";
import MonthsModal from "./MonthsModal";
import { useState } from "react";

export default function FilterDate({
  date,
  setDate,
  fromDate,
  toDate,
  sumDays,
  chart_loading
}) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [open, setOpen] = useState("");

  // method
  function changeOpen(o) {
    setOpen(o);
  }

  return (
    <StyledFilterDate>
      <Card>
        <main className="filter__date__content">
          <section>
            <h1>{width < 769 ? "Статистика за период" : "Период"}</h1>
            <div>
              <p>С {fromDate}</p>
              <i className="icon icon-sm icon-arrow-right"></i>
              <p>по {toDate}</p>
            </div>
            <p>Выбрано {sumDays} дней</p>
          </section>
          <section>
            <div
              className={cl("time__card", {
                active: date.type === "all",
              })}
              onClick={!chart_loading ? () => setDate((p) => ({ ...p, type: "all" })): null}
            >
              <p>Все время</p>
            </div>
            <div
              className={cl("time__card", {
                active: date.type === "month",
              })}
              onClick={!chart_loading ? () => {
                changeOpen("month");
              }:null}
            >
              <p>Месяц</p>
              <FilterModal
                isOpen={open === "month"}
                month={1}
                onClose={() => {
                  changeOpen("");
                }}
              >
                <MonthsModal
                  from={date.mFrom}
                  to={date.mTo}
                  year={date.year}
                  setDate={setDate}
                  onClose={() => {
                    changeOpen("");
                  }}
                />
              </FilterModal>
            </div>
            <div
              className={cl("time__card", {
                active: date.type === "year",
              })}
              onClick={!chart_loading ? () => {
                changeOpen("year");
              }:null}
            >
              <p>Год</p>
              <FilterModal
                isOpen={open === "year"}
                onClose={() => {
                  changeOpen("");
                }}
                year={1}
              >
                <YearsModal
                  from={date.yFrom}
                  to={date.yTo}
                  setDate={setDate}
                  onClose={() => {
                    changeOpen("");
                  }}
                />
              </FilterModal>
            </div>
          </section>
        </main>
      </Card>
    </StyledFilterDate>
  );
}

const StyledFilterDate = styled.div`
  .filter__date__content {
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 35px;

    & > section {
      &:nth-child(1) {
        padding: 0 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }

      &:nth-child(2) {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 10px;
      }

      & > .time__card {
        border-radius: 14px;
        background: var(--dark-3);
        padding: 14px 20px;
        cursor: pointer;
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 0;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          text-align: center;
          color: var(--dark);
          width: 100%;
        }

        &.active {
          background: var(--primary-10);
          p {
            color: var(--primary);
          }
        }
      }

      h1 {
        font-family: Montserrat;
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 14px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-50);
      }

      & > div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        i {
          background-color: var(--dark);
        }
      }
    }
  }

  @media (max-width: 768px) {
    background-color: var(--light);
    box-shadow: 0px 0px 10px var(--dark-5);
    border-radius: 0px 0px 30px 30px;

    .filter__date__content {
      gap: 30px;

      & > section {
        &:nth-child(1) {
          flex-direction: column;
          justify-content: center;
          gap: 20px;
          padding: 0;

          h1 {
            font-size: 16px;
            line-height: 20px;
            font-weight: 600;
          }
        }
      }
    }
  }

  @media (max-width: 500px) {
    .filter__date__content {
      & > section {
        &:nth-child(2) {
          display: -ms-grid;
          display: grid;
          grid-template-columns: 1fr;
          gap: 10px;
        }
      }
    }
  }
`;
