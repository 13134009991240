import React, { useState } from "react";
import styled from "styled-components";
import { useMutation } from "react-query";

//
import InputPhone from "../../../../components/input-phone/InputPhone";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";
import AuthApi from "../../../../store/auth/api";

export default function ResetMain({ onBack, onNext }) {
  // state
  const [phone, setPhone] = useState("");
  const [phoneErr, setPhoneErr] = useState("");

  // query
  const mutation = useMutation((p) => AuthApi.newPassReq(p), {
    onSuccess,
    onError,
  });

  // method
  function onSuccess(res) {
    if (res.data.data.Status === "ОК") {
      onNext("reset_phone", phone);
      return;
    }
    if (res.data.data.Status === "Нет контрагентов") {
      setPhoneErr("Пользователь с таким номером не найден");
    }
    if (res.data.data.Status === "Несколько контрагентов") {
      setPhoneErr(
        "Найдено несколько контрагентов с таким номером. Пожалуйста, свяжитесь с администратором."
      );
    }
  }

  function onError() {
    setPhoneErr("Пользователь с таким номером не найден");
  }

  function handleReqCode() {
    if (phone.length < 11) {
      setPhoneErr(
        "Неверно введен номер: номер должен содержать 10 цифр (формат +7 (xxx) xxx xx xx"
      );
      return;
    }
    mutation.mutate(phone);
  }

  return (
    <StyledResetMain>
      <h1>Сбросить пароль</h1>
      <p>
        Введите ваш номер телефона <br /> который был указан в договоре.
      </p>
      <div className="input__phone__wrap">
        <InputPhone
          value={phone}
          setValue={setPhone}
          fill={1}
          placeholder="+7"
          error={phoneErr}
          onResetErr={() => setPhoneErr("")}
        />
      </div>
      <footer>
        <i className="icon icon-lg icon-arrow-narrow-left" onClick={onBack}></i>
        <Button
          type="submit"
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          fW={500}
          onClick={handleReqCode}
        >
          {mutation.isLoading && (
            <Loader bg="primary" color="light" w={24} h={24} size={3} />
          )}
          Далее
        </Button>
      </footer>
    </StyledResetMain>
  );
}

const StyledResetMain = styled.div`
  h1 {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--dark);
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 20px;
  }

  & > p {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--dark-70);
    margin-bottom: 50px;
  }

  .input__phone__wrap {
    width: 320px;
    margin-bottom: 83px;
  }

  footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    i {
      background-color: var(--dark);
    }
  }
`;
