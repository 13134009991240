import React, { useState, useMemo } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

//
import Card from "../card/Card";
import CarTable from "./components/CarTable";
import DougHunt from "./components/DougHunt";
import Modal from "../modal/Modal";
import Loader from "../loader/Loader";
import CarPaymentModal from "./components/CarPaymentModal";
import PayInfoModal from "./components/PayInfoModal";
import CurrencyTab from "./components/CurrencyTab";
import MobileChart from "./components/MobileChart";
import NumberFormat from "../number-format/NumberFormat";
import Tab from "../tab/Tab";
import EmptyText from "../epmty-text/EmptyText";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { doughuntTabOptions, doughuntTabTitle } from "../../util/lists";

export default function CarTableChart({
  car_list,
  curCar,
  changeCurCar,
  car_operation,
  loading,
  car_oper_loading,
  analytic,
  noModal,
  tab,
  setTab,
  title,
}) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [hoverId, setHoverId] = useState(null);
  const [showType, setShowType] = useState("rub");
  const [modal, setModal] = useState(null);
  const [operation, setOperation] = useState(null);
  const [sort, setSort] = useState(analytic ? { name: tab, type: "up" } : null);

  // method
  const arr = useMemo(() => {
    if (!sort) {
      return car_list;
    } else {
      let tempArr = [...car_list].sort((a, b) => {
        if (sort.type === "up") {
          return (
            b[analytic ? sort.name : "profit"] -
            a[analytic ? sort.name : "profit"]
          );
        } else {
          return (
            a[analytic ? sort.name : "profit"] -
            b[analytic ? sort.name : "profit"]
          );
        }
      });
      return tempArr;
    }
  }, [car_list, sort, analytic]);

  const getIncomeAmount = useMemo(() => { 
    let sum = car_list.reduce((total, i) => {
      total +=
        i[analytic ? tab : "profit"] -
        (tab === "income" ? i["comission"] || 0 : 0);
      return total;
    }, 0);
    if (sum === 0) return 0;

    if (tab === "load") return Math.round(sum / car_list.length);

    return sum.toFixed();
  }, [car_list, tab, analytic]);

  const getCurAmount = useMemo(() => {
    if (!hoverId) return;
    let el = car_list.find((i) => i.car_id === hoverId);
    console.log(el);

    if (!el) return 0;
    return (
      el[analytic ? tab : "profit"] -
        (tab === "income" ? el["comission"] || 0 : 0) || 0
    ).toFixed();
  }, [car_list, hoverId, tab, analytic]);

  function changeSort(name) {
    if (!sort || sort.name !== name) {
      setSort({ name, type: "up" });
    } else {
      if (name === sort.name) {
        if (sort.type === "up") {
          setSort({ name, type: "down" });
        } else {
          setSort(null);
        }
      }
    }
  }

  function getTitle() {
    if (hoverId) {
      return doughuntTabOptions.find((i) => i.value === tab).title;
    }

    if (analytic) return doughuntTabTitle[tab];

    return "Общий прибыль";
  }

  function changeHoverId(id) {
    setHoverId(id);
  }

  function changeModal(m) {
    setModal(m);
  }

  function changeOperation(o) {
    setOperation(o);
  }

  function changeTab(t) {
    setTab(t);
  }

  return (
    <StyledCarTableChart loading={loading ? 1 : 0} analytic={analytic}>
      <Card mobile={1}>
        {loading && <Loader />}
        {analytic && (
          <div className="anal__tab__wrap">
            <Tab
              options={doughuntTabOptions}
              onChange={changeTab}
              value={tab}
              analytic={analytic}
            />
          </div>
        )}
        <header className="top__title__sort__wrapper">
          <h1>{title}</h1>
          {width < 1201 && analytic ? (
            <MobileChart
              setShowType={setShowType}
              showType={showType}
              analytic={analytic}
              tab={tab}
              cars={arr}
              sum={getIncomeAmount}
              onSort={() =>
                changeSort(
                  doughuntTabOptions.find((i) => i.value === tab).value
                )
              }
              changeHoverId={changeHoverId}
              hoverId={hoverId}
            />
          ) : (
            <ul className="sort__list">
              <li
                onClick={() =>
                  changeSort(
                    doughuntTabOptions.find((i) => i.value === tab).value
                  )
                }
              >
                <p>
                  {analytic
                    ? doughuntTabOptions.find((i) => i.value === tab).title
                    : "Прибыль"}
                </p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== tab,
                    "icon-chevron-right rotate--90 active":
                      sort?.name === tab && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === tab && sort?.type === "down",
                  })}
                ></i>
              </li>
              {!analytic && (
                <li onClick={() => changeSort("load")}>
                  <p>Загрузка</p>
                  <i
                    className={cl("icon icon-xs", {
                      "icon-chevron-selector": sort?.name !== "load",
                      "icon-chevron-right rotate--90 active":
                        sort?.name === "load" && sort?.type === "up",
                      "icon-chevron-right rotate-90 active":
                        sort?.name === "load" && sort?.type === "down",
                    })}
                  ></i>
                </li>
              )}
            </ul>
          )}
        </header>

        {car_list.length ? (
          <>
            <main className="car__table__chart__content">
              <CarTable
                hoverId={hoverId}
                changeHoverId={changeHoverId}
                showType={showType}
                getIncomeAmount={getIncomeAmount}
                changeModal={changeModal}
                cars={arr}
                changeCurCar={changeCurCar}
                curCar={curCar}
                tab={tab}
                analytic={analytic}
              />
              {width < 769 && analytic ? null : (
                <section className="table__chart__right">
                  <DougHunt
                    cars={arr}
                    changeHoverId={changeHoverId}
                    hoverId={hoverId}
                    curCar={curCar}
                    tab={tab}
                    analytic={analytic}
                  />
                  <div className="centered__title">
                    <h1>
                      {showType === "%" && hoverId ? (
                        <>
                          {getCurAmount > 0
                            ? ((getCurAmount / getIncomeAmount) * 100).toFixed(
                                1
                              )
                            : 0}{" "}
                          %
                        </>
                      ) : (
                        <>
                          <NumberFormat
                            value={
                              hoverId
                                ? getCurAmount
                                : showType === "%"
                                ? "100"
                                : getIncomeAmount
                            }
                            suffix={
                              showType === "%" || tab === "load" ? "%" : "₽"
                            }
                          />
                        </>
                      )}
                    </h1>
                    <p dangerouslySetInnerHTML={{ __html: getTitle() }} />
                  </div>
                  <CurrencyTab
                    setShowType={setShowType}
                    showType={showType}
                    analytic={analytic}
                    tab={tab}
                    position={1}
                  />
                </section>
              )}
            </main>
          </>
        ) : (
          <EmptyText />
        )}
      </Card>
      {!noModal && (
        <Modal
          isOpen={modal}
          onClose={() => {
            changeModal(false);
            changeCurCar(null);
            changeOperation(null);
          }}
          topCurCar={operation}
          changeCurCar={() => changeOperation(null)}
          title={operation ? operation.operation_name : curCar?.brand_model}
          carNum={curCar?.gos_num}
          isLoading={car_oper_loading}
        >
          {operation ? (
            <PayInfoModal operation={operation} />
          ) : (
            <CarPaymentModal
              changeOperation={changeOperation}
              car={car_operation}
            />
          )}
        </Modal>
      )}
    </StyledCarTableChart>
  );
}

const StyledCarTableChart = styled.section`
  margin-top: 20px;

  .anal__tab__wrap {
    padding: 30px 0 0 30px;
  }

  .top__title__sort__wrapper {
    width: calc(100% - 415px);
    padding: 30px 62px 0 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    .sort__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 35px;

      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        p {
          font-size: 14px;
          font-weight: 500;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        i {
          background-color: var(--dark-50);

          &.active {
            background-color: var(--dark);
          }
        }
      }
    }
  }

  .car__table__chart__content {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 320px;
    gap: 100px;
    padding: 30px 50px 90px 30px;
    position: relative;

    .table__chart__right {
      position: relative;
      height: max-content;
      position: sticky;
      position: -webkit-sticky;
      top: 110px;

      .centered__title {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        color: var(--dark);

        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          white-space: nowrap;
        }
      }
    }

    ${({ loading }) =>
      loading &&
      css`
        max-height: 400px;
        overflow: hidden;
      `}
  }

  @media (max-width: 1200px) {
    .top__title__sort__wrapper {
      width: 100%;
    }

    .car__table__chart__content {
      grid-template-columns: 1fr;
      gap: 100px;
      padding: 30px;

      & > section {
        &:nth-child(2) {
          width: 80%;
          min-width: 300px;
          margin: 0 auto;
          max-width: 400px;
        }
      }
    }

    .top__title__sort__wrapper {
      padding: 20px 30px 0px;
      flex-direction: column;
      gap: 20px;
      align-items: flex-start;

      h1 {
        display: none;
      }

      .sort__list {
        margin-left: auto;
      }
    }
  }

  @media (max-width: 768px) {
    .car__table__chart__content {
      padding: 30px 0 60px;
    }
  }
`;
