import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

//
import Card from "../card/Card";
import Zoom from "./components/Zoom";
import Loader from "../loader/Loader";
import EmptyText from "../epmty-text/EmptyText";
import { months } from "../../util/lists";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

//

export default function Chart({ type = "rub", loading, closed, list }) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [lines, setLines] = useState([]);
  const [size, setSize] = useState(type === "%" ? 12 : 6);
  const [page, setPage] = useState(0);
  const [max, setMax] = useState(300);
  const [step, setStep] = useState(type === "%" ? 25 : 50);
  const [arr, setArr] = useState([]);

  // watcher
  useEffect(() => {
    if (!closed) {
      setArr([...list]);
    } else {
      setArr(list.filter((i) => i.closed));
    }
    setPage(0);
  }, [closed, list]);

  useEffect(() => {
    let tempArr = [];
    let i = 0;
    while (i <= max) {
      tempArr.push(i);
      i = i + step;
    }
    if (i - max < step / 2) {
      tempArr.push(i);
    }
    setLines([...tempArr.reverse()]);
  }, [type, max, step]);

  useEffect(() => {
    let max = 0;
    arr.forEach((i) => {
      if (i.sum > max) {
        max = i.sum;
      }
    });

    max = Math.floor((max < 50000 ? 50000 : max) / 1000);

    if (max > 500) {
      setStep(200);
    }

    setMax(max);
  }, [arr]);

  useEffect(() => {
    if (width < 993) {
      setSize(6);
      return;
    }
    setSize(arr.length > 12 ? 12 : arr.length);
    setPage(0);
  }, [arr, width]);

  // method
  function incSize() {
    if (size <= 1) return;
    setSize((p) => p - 1);
    setPage(0);
  }

  function decSize() {
    if (size >= getDecSize() || size === arr.length) return;
    setSize((p) => p + 1);
    setPage(0);
  }

  function getDecSize() {
    if (width < 993) return 6;
    return 12;
  }

  const listForRender = useMemo(() => {
    return arr.slice(page * size, size * page + size);
  }, [arr, page, size]);

  function nextPage() {
    if (page + 1 === getCountPages()) return;
    setPage((p) => p + 1);
  }

  function getCountPages() {
    return Math.ceil(arr.length / size);
  }

  function prevPage() {
    if (page === 0) return;
    setPage((p) => p - 1);
  }

  function getHLine(v) {
    v = v / 1000;
    const count = max / step;
    let gap = type === "%" ? 26 : 5;
    let line = 37;
    let hWrapper = count * (gap + line) + line / 2;
    return (hWrapper * v) / max;
  }

  function checkCurDate(m, y) {
    const curDate = new Date();
    if (+m === curDate.getMonth() + 1 && +y === curDate.getFullYear())
      return "cur";
    if (+m > curDate.getMonth() + 1 && +y === curDate.getFullYear())
      return "fut";
  }

  function getPercentCurrentMonth() {
    const now = new Date();
    let lastDate =
      new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        1,
        "00",
        "00",
        "00"
      ).getTime() - 1000;
    lastDate = new Date(lastDate).getDate();
    let today = now.getDate();
    today = Math.round((today / lastDate) * 100);
    return today;
  }

  return (
    <StyledChart type={type} size={size} load={loading}>
      <Card mobile={1}>
        {loading && <Loader />}
        {list.length > 0 ? (
          <main className="chart__main">
            <section className="lines__wrapper">
              <ul>
                {lines.map((i) => (
                  <li key={i}>
                    <p className="money__display">
                      {i}
                      {type === "%" ? "%" : <>{i === 0 ? "" : "К"} ₽</>}
                    </p>
                    <div className="horizontal__line"></div>
                  </li>
                ))}
              </ul>
            </section>
            {type !== "%" && <Zoom inc={incSize} dec={decSize} />}
            <section className="vertical__line__wrap">
              <div
                className={cl("icon__wrapper prev", {
                  disabled: page === 0,
                })}
                onClick={prevPage}
              >
                <i className="icon icon-arrow-right"></i>
              </div>

              <ul className="vertical__lines">
                {listForRender.map((i) => {
                  let exRes = checkCurDate(i.month, i.year);

                  return (
                    <li key={i.month + i.year}>
                      {exRes === "cur" && <div className="cur__date__circle" />}
                      <h1>
                        {exRes === "cur" && new Date().getDate()}{" "}
                        {months[i.month - 1].split("").slice(0, 3).join("")}
                      </h1>
                      <p>{i.year}</p>
                      <VerticalLine
                        h={getHLine(exRes === "fut" ? step * 1000 : i.sum)}
                        type={exRes}
                        title={
                          type === "%" ? i.sum / 1000 + " %" : i.sum + " ₽"
                        }
                        gray={!i.closed}
                        percent={
                          exRes === "cur" ? getPercentCurrentMonth() : null
                        }
                      />
                    </li>
                  );
                })}
              </ul>

              <div
                className={cl("icon__wrapper next", {
                  disabled: page + 1 === getCountPages(),
                })}
                onClick={nextPage}
              >
                <i className="icon icon-arrow-right"></i>
              </div>
            </section>
          </main>
        ) : (
          <EmptyText />
        )}
      </Card>
    </StyledChart>
  );
}

const StyledChart = styled.div`
  overflow: hidden;

  ${({ load }) =>
    load &&
    css`
      height: 400px;
    `}

  .chart__main {
    padding: 30px;
    position: relative;

    .lines__wrapper {
      margin-right: 70px;

      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 5px;

        ${({ type }) =>
          type === "%"
            ? css`
                gap: 26px;
              `
            : null}

        li {
          display: -ms-grid;
          display: grid;
          -webkit-box-align: center;
          -ms-flex-align: center;
                  align-items: center;
          grid-template-columns: 100px auto;

          .money__display {
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark);
            background: var(--dark-3);
            border-radius: 20px;
            text-align: center;
            padding: 10px;
            white-space: nowrap;
          }

          .horizontal__line {
            height: 1px;
            background-color: var(--primary-dark-15);
          }
        }
      }
    }

    .vertical__line__wrap {
      display: -ms-grid;
      display: grid;
      -webkit-box-align: center;
      -ms-flex-align: center;
              align-items: center;
      grid-template-columns: 75px auto 48px;
      gap: 25px;
      margin-top: 27px;

      .icon__wrapper {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid var(--primary-dark-15);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        cursor: pointer;

        &.prev {
          transform: rotate(180deg);
          margin-left: 26px;
        }

        i {
          background-color: var(--dark);
        }

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }

      .vertical__lines {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;

        li {
          width: calc(100% / ${({ size }) => size});
          text-align: center;
          padding: 15px 7px;
          position: relative;
          max-width: 160px;

          .cur__date__circle {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--primary);
            position: absolute;
            top: 7px;
            left: 50%;
            transform: translateX(-50%);
          }

          h1 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0em;
            text-transform: uppercase;
            color: var(--dark);
            white-space: nowrap;
          }

          p {
            font-size: 12px;
            font-weight: 400;
            line-height: 15px;
            letter-spacing: 0em;
            color: var(--dark-50);
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .chart__main {
      padding: 30px 30px 110px 30px;

      .lines__wrapper {
        margin-right: 0;
      }

      .vertical__line__wrap {
        grid-template-columns: 1fr;
        gap: 0;
        margin-left: 100px;
        position: relative;

        .icon__wrapper {
          position: absolute;
          bottom: -78px;

          &.prev {
            left: -100px;
          }

          &.next {
            right: 0px;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .chart__main {
      padding: 30px 0px 110px 30px;

      .lines__wrapper {
        ul {
          li {
            grid-template-columns: 55px auto;

            .money__display {
              font-size: 12px;
              padding: 10px 5px;
            }
          }
        }
      }

      .vertical__line__wrap {
        margin-left: 55px;

        .vertical__lines {
          gap: 10px;

          li {
            background: var(--primary-10);
            border-radius: 14px;
            padding: 16px 9px;

            h1,
            p {
              font-size: 12px;
              line-height: 14px;
              color: var(--primary);
            }
          }
        }
        .icon__wrapper {
          &.next {
            right: 30px;
          }

          &.prev {
            left: -80px;
          }
        }
      }
    }
  }

  @media (max-width: 450px) {
    .chart__main {
      padding: 30px 0px 110px 10px;
      .lines__wrapper {
        ul {
          li {
            .money__display {
              font-size: 11px;
            }
          }
        }
      }

      .vertical__line__wrap {
        margin-top: 20px;

        .vertical__lines {
          gap: 5px;

          li {
            background: var(--primary-10);
            border-radius: 14px;
            padding: 12px 5px;

            h1,
            p {
              font-size: 11px;
              line-height: 14px;
              color: var(--primary);
            }
          }
        }
      }
    }
  }
`;

const VerticalLine = styled.div`
  position: absolute;
  width: 65%;
  background: var(--primary);
  border-radius: 17px;
  top: -27px;
  left: 50%;
  transform: translate(-50%, -100%);
  height: ${({ h }) => h + "px"};
  overflow: hidden;

  ${({ type, gray, percent }) =>
    type === "cur"
      ? css`
          background: var(--light);

          &::before,
          &::after {
            content: "";
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            width: ${percent + "%"};
            background: var(--secondary);
          }
          &::after {
            left: auto;
            right: 0;
            width: ${100 - percent + "%"};
            background: var(--secondary-40);
          }
        `
      : type === "fut" || gray
      ? css`
          background: var(--primary-dark-15);
        `
      : null}

  @media (max-width : 450px) {
    top: -20px;
  }
`;
