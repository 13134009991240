import React, { useState } from "react";
import styled from "styled-components";
import cl from "classnames";

const checkList = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", null];

export default function SmsCodeInput({ value, setValue, err, onResetErr }) {
  const [focusIdx, setFocusIdx] = useState(null);

  function handleFocus(index) {
    setFocusIdx(index);
  }

  function changeValue(e, index) {
    e = e.nativeEvent.data;
    if (!checkList.includes(e)) return;
    let tempVal = [...value];
    if (e === null) {
      if (tempVal[index]) {
        tempVal[index] = "";
      }
      if (index > 0) {
        document.getElementById("input-" + (index - 1)).focus();
      }
    } else {
      tempVal[index] = e;
      if (index < 3) {
        document.getElementById("input-" + (index + 1)).focus();
      }
    }
    onResetErr();
    setValue(tempVal);
  }

  return (
    <StyledSmsCodeInput>
      {new Array(4).fill("").map((i, idx) => (
        <li key={idx}>
          <input
            type="text"
            value={value[idx]}
            id={"input-" + idx}
            className={cl({
              active: value[idx],
              err,
            })}
            onFocus={() => handleFocus(idx)}
            onBlur={() => setFocusIdx(null)}
            onChange={(e) => changeValue(e, idx)}
          />
          {value[idx] ? null : focusIdx !== idx ? (
            <div
              className="circle"
              onClick={() => {
                document.getElementById("input-" + idx).focus();
              }}
            ></div>
          ) : null}
        </li>
      ))}
    </StyledSmsCodeInput>
  );
}

const StyledSmsCodeInput = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 20px;

  li {
    position: relative;

    .circle {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--primary-dark-15);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      cursor: pointer;
    }

    input {
      display: inline-block;
      width: 38px;
      height: 52px;
      background: var(--light);
      border: 1px solid var(--primary-dark-15);
      border-radius: 14px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: center;
      transition: 0.2s linear;

      &:hover {
        border-color: var(--dark-30);
      }

      &.disabled {
        pointer-events: none;
      }

      &:focus,
      &.active {
        outline: none;
        border-color: var(--primary);
      }

      &.err {
        border-color: var(--danger);
      }
    }
  }
`;
