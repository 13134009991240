/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import styled from "styled-components";

//
import CarTableChart from "../../../../components/car-table-doughunt/CarTableChart";

export default function TableCarAnal({ loading, cars, filter, search }) {
  // state
  const [tab, setTab] = useState("income");

  // method
  const list = useMemo(() => {
    return cars.filter((i) => {
      if (search) {
        search = search.toLowerCase();
        let ex =
          i.brand_model.toLowerCase().includes(search) ||
          i.gos_num.toLowerCase().includes(search);
        return filter.includes(i.brand_model) && ex;
      }
      return filter.includes(i.brand_model);
    });
  }, [cars, filter, search]);

  return (
    <StyledTableCarAnal>
      <CarTableChart
        car_list={list}
        analytic={1}
        noModal={1}
        loading={loading}
        tab={tab}
        setTab={setTab}
        title="За выбранный период"
      />
    </StyledTableCarAnal>
  );
}

const StyledTableCarAnal = styled.section``;
