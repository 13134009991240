import React from "react";
import styled from "styled-components";

//
import CarNumber from "../../../../components/car-number/CarNumber";

export default function ModelGos({ model, gos }) {
  return (
    <StyledModelGos>
      <h1>{model || "Car model"}</h1>
      <CarNumber title={gos} size="lg" mobileSize='lg'  />
    </StyledModelGos>
  );
}

const StyledModelGos = styled.div`
  background: var(--light);
  box-shadow: 0px 0px 10px var(--primary-dark-5);
  border-radius: 0px 0px 30px 30px;
  padding: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;

  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
  }
`;
