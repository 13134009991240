import { useMutation, useQuery } from "react-query";

//
import { GET_USER_INFO } from "./type";
import Api from "./api";

export function useGetUserInfo(options) {
  return useMutation(GET_USER_INFO, Api.getUserInfo, { ...options });
}

export function useGetUserInfoQuery(options) {
  return useQuery(GET_USER_INFO, Api.getUserInfo, { ...options });
}
