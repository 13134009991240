import React from "react";
import styled from "styled-components";

//
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";

export default function IncomeCard({
  chart_loading,
  profitMonth,
  profitTotal,
}) {
  return (
    <StyledIncomeCard>
      <Card mobile={1}>
        {chart_loading && <Loader />}
        <main className="income__card__content">
          <section>
            <div className="icon__wrap__vert">
              <i className="icon icon-currency-ruble"></i>
            </div>
          </section>
          <section>
            <h1>Заработано за период </h1>
            <p>
              <NumberFormat value={profitTotal} />
            </p>
            <h6>Из них прогноз. заработок</h6>
            <h4>
              <NumberFormat value={profitMonth} />
            </h4>
          </section>
        </main>
      </Card>
    </StyledIncomeCard>
  );
}

const StyledIncomeCard = styled.div`
  .income__card__content {
    padding: 25px 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 30px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    section {
      .icon__wrap__vert {
        background-color: var(--success-20);
        width: 45px;
        height: 115px;
        border-radius: 15px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        i {
          background-color: var(--success);
        }
      }

      h1 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-bottom: 5px;
      }

      p {
        font-size: 24px;
        font-weight: 600;
        line-height: 29px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 8px;
      }

      h6 {
        font-size: 10px;
        font-weight: 400;
        line-height: 12px;
        letter-spacing: 0em;
        margin-bottom: 3px;
        color: var(--dark);
        opacity: 0.6;
      }

      h4 {
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 768px) {
    .income__card__content {
      padding: 25px 30px 30px;
    }
  }
`;
