import { createGlobalStyle } from "styled-components";

//
import "./color.css";
import "./font.css";
import "./icon.css";

export const GlobalStyles = createGlobalStyle`
    *, *::after, *::before {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    html {
        scroll-behavior: smooth;
    }

    body {
        background-color: var(--bg);
        overflow-x: hidden;
    }

    .kill-scroll {
        height: 100vh;
        overflow: hidden;
    }

    ul {
        list-style: none;
    }

    a {
        text-decoration: none;
    }

    .container {
        max-width: 1330px;
        margin: 0 auto;
        padding: 0 15px;

        @media (max-width : 768px) {
            padding: 0;
        }
    }

    .ReactCollapse--collapse {
        transition: height 200ms;
    }

    .rotate-90 {
        transform: rotate(90deg);
    }

    .rotate--90 {
        transform: rotate(-90deg);
    }

    .p-r {
        position: relative;
    }

    .c-p {
        cursor: pointer;
    }
`;
