import React from "react";
import styled from "styled-components";
import { Outlet } from "react-router-dom";

//
import Header from "../../components/header/Header";
import Navbar from "../../components/navbar/Navbar";

export default function CabinetLayout() {
  return (
    <StyledCabinetLayout>
      <Header />
      <main className="cabinet__layout__main">
        <Navbar />
        <section className="outlet">
          <div className="container">
            <Outlet />
          </div>
        </section>
      </main>
    </StyledCabinetLayout>
  );
}

const StyledCabinetLayout = styled.div`
  .cabinet__layout__main {
    position: relative;

    .outlet {
      margin: 30px 0 110px;
    }
  }

  @media (max-width: 768px) {
    .cabinet__layout__main {
      .outlet {
        margin-top: 0;
      }
    }
  }
`;
