import React from "react";
import styled, { css } from "styled-components";
//
//
export default function CardForecast({ children, ...props }) {
  return (
    <StyleCardForecast {...props}>
      <div className="currency">
        <i className="icon icon-currency-ruble" />
      </div>
      <div className="text">{children}</div>
    </StyleCardForecast>
  );
}

const StyleCardForecast = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: var(--success-10);
  border-radius: 15px;

  & > .currency {
    padding: 34px 11px;
    background-color: var(--success);
    border-radius: 15px;
  }

  .text {
    padding: 0 30px;

    .top__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }

    .number {
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }

  ${({ warning }) =>
    warning &&
    css`
      background-color: var(--warning-10);

      & > .currency {
        background-color: var(--warning);
      }
    `}
`;
