import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

//
import logo from "../../asset/img/almak-logo-partner.svg";
import avatar from "../../asset/img/avatar.png";
import Modal from "../modal/Modal";
import Loader from "../loader/Loader";
import UserInfoModal from "./components/UserInfoModal";
import ResetCacheModal from "../reset-cache-modal/ResetCacheModal";
import Api from "../../store/auth/api";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";
import { hidePhoneNumberGen } from "../../util/formatters";
import { useGetUserInfoQuery } from "../../store/user/hook";
import { useAppContext } from "../../store/Context";
import cookies from "../../util/cookie";
import Consts from "../../util/const";

export default function Header() {
  const {
    state: { notif_count },
    fn: { resetCache, changeIsAuth },
  } = useAppContext();
  const { data, isLoading } = useGetUserInfoQuery({ enabled: true });
  const user = data?.data?.data;
  const navigate = useNavigate();
  const { search, pathname } = useLocation();
  const { width } = useWindowDimensions();
  const [modal, setModal] = useState(false);
  const [resetModal, setResetModal] = useState(false);
  const [lastUpdate, setLastUpdate] = useState(null);
  const queryCache = useQueryClient();

  const mutation = useMutation(() => Api.logout(), { onSuccess });

  useEffect(() => {
    resetTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLogOut() {
    mutation.mutate();
  }

  function onSuccess() {
    changeIsAuth(false);
    cookies.setCookie(Consts.APP_TOKEN,"", 0);
    cookies.setCookie(Consts.USER_PHONE,"", 0);
    localStorage.clear();
    queryCache.clear();
  }

  function clickRefresh() {
    if (width < 769) {
      changeResetModal(true);
    } else {
      resetCache();
      resetTime();
    }
  }

  function resetTime() {
    const now = new Date();
    let hours = now.getHours();
    hours = hours < 10 ? "0" + hours : hours;
    let min = now.getMinutes();
    min = min < 10 ? "0" + min : min;
    setLastUpdate(`сегодня в ${hours}:${min}`);
  }

  function changeModal(m) {
    setModal(m);
  }

  function changeResetModal(rm) {
    setResetModal(rm);
  }

  if (search.includes("selected_car_id")) return null;

  if (pathname.includes("doc")) {
    let url = pathname.split("/");
    if (url.length === 4 && width < 769) {
      return null;
    }
  }

  return (
    <StyledHeader>
      <div className="container">
        <main className="header">
          <section className="header__car">
            <i className="icon icon-car"></i>
          </section>
          <section className="header__logo">
            <img src={logo} alt="logo" />
            <div className="vertical-border"></div>
            <p>Кабинет партнера</p>
          </section>
          <section className="header__right">
            <div className="header__lastupdate">
              <p>
                Последнее обновление <br /> данных {lastUpdate}
              </p>
              <div className="refresh__wrapper" onClick={clickRefresh}>
                <i className="icon icon-refresh"></i>
              </div>
            </div>
            <div
              className="header__notif"
              onClick={() => navigate("/cabinet/notif")}
            >
              <i className="icon icon-bell"></i>
              {notif_count > 0 && <div className="red-circle"></div>}
            </div>
            <div
              className="header__user"
              onClick={() => {
                if (isLoading) return;
                changeModal(true);
              }}
            >
              {isLoading && <Loader w={24} h={24} size={3} />}
              {user ? (
                <>
                  <p>{hidePhoneNumberGen(user.phone)}</p>
                  <img
                    src={
                      user.avatar ??
                      "https://freepikpsd.com/file/2019/10/default-avatar-png-Transparent-Images.png"
                    }
                    alt="avatar"
                  />
                </>
              ) : (
                <>
                  <p>+7 *** 77-77</p>
                  <img src={avatar} alt="avatar" />
                </>
              )}
            </div>
          </section>
        </main>
      </div>
      <Modal
        isOpen={modal}
        onClose={() => changeModal(false)}
        title="Мои данные"
      >
        <UserInfoModal
          user={user || {}}
          onLogOut={onLogOut}
          logout_loading={mutation.isLoading}
        />
      </Modal>
      {width < 769 && (
        <ResetCacheModal
          open={resetModal}
          onClose={() => changeResetModal(false)}
          onSubmit={() => {
            resetCache();
            resetTime();
            changeResetModal(false);
          }}
          lastUpdate={lastUpdate}
        />
      )}
    </StyledHeader>
  );
}

const StyledHeader = styled.div`
  background: var(--primary);
  padding: 20px 0;

  .header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    &__car,
    &__notif {
      display: none;
    }

    &__logo {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      gap: 20px;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .vertical-border {
        display: inline-block;
        width: 1px;
        height: 30px;
        background-color: var(--light-70);
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--light-70);
      }
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
    }

    &__lastupdate {
      background: var(--light-10);
      border-radius: 24px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
      padding-left: 30px;

      p {
        font-size: 12px;
        font-weight: 300;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--light);
      }

      .refresh__wrapper {
        width: 48px;
        height: 48px;
        border-radius: 50px;
        border: 1px solid var(--light-10);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    &__user {
      cursor: pointer;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      padding: 10px;
      background: var(--light-10);
      border-radius: 24px;
      position: relative;
      overflow: hidden;
      min-width: 170px;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--light);
        padding-left: 10px;
      }

      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 30px;

    .header {
      &__logo {
        display: none;
      }

      &__lastupdate {
        background-color: transparent;
        padding: 0;
        p {
          display: none;
        }
      }

      &__car {
        display: block;

        i {
          width: 42px;
        }
      }

      &__notif {
        display: block;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid var(--light-10);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        position: relative;
        cursor: pointer;

        .red-circle {
          position: absolute;
          top: 2px;
          right: 2px;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: var(--danger);
          border: 1px solid var(--light);
        }
      }

      &__user {
        padding: 0;
        background: var(--light-10);
        min-width: auto;

        p {
          display: none;
        }

        img {
          width: 48px;
          height: 48px;
        }
      }
    }
  }
`;
