import React from "react";
import styled from "styled-components";
import cl from "classnames";

//
import NumberFormat from "../number-format/NumberFormat";

//
export default function OperationCard({ title, price }) {
  return (
    <StyleOperationCard>
      <section className="left">
        <div
          className={cl("circle", {
            success: price > 0,
          })}
        ></div>
        <p>{title}</p>
      </section>
      <h4 className="price">
        <NumberFormat value={price} />
      </h4>
    </StyleOperationCard>
  );
}

const StyleOperationCard = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 45px 84px 40px 48px;

  & > .left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 51px;

    & > p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
    & > .circle {
      width: 10px;
      height: 10px;
      min-height: 10px;
      min-width: 10px;
      border-radius: 50%;
      background-color: red;

      &.success {
        background-color: var(--success);
      }
    }
  }
  .price {
    color: var(--dark-70);
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
  }
  @media (max-width: 768px) {
    padding: 20px 30px;
    & > .left {
      gap: 10px;
      & > p {
        margin-left: 10px;
      }
    }
  }
`;
