import api from "../../service/axios";

class UserApi {
  static getUserInfo() {
    let url = "/getUserInfo";
    return api.get(url);
  }

  static postAvatar(avatar_url) {
    let url = `/postAvatar`;
    return api.post(url, { avatar_url });
  }
}

export default UserApi;
