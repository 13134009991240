import React from "react";
import styled from "styled-components";
import cl from "classnames";

export default function Tab({ options = [], onChange, value, analytic }) {
  return (
    <StyledTab>
      <ul>
        {options.map((i) => (
          <li
            key={i.id}
            className={cl({
              active: i.value === value,
            })}
            onClick={() => onChange(i.value)}
          >
            {i.title} {i.value === "income" && analytic ? " - %" : ""}
          </li>
        ))}
      </ul>
    </StyledTab>
  );
}

const StyledTab = styled.div`
  & > ul {
    padding: 30px;
    padding-bottom: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 5px;
    width: max-content;
    background: var(--dark-5);
    border-radius: 25px;
    padding: 5px;

    & > li {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
      padding: 8px 18px;
      background: transparent;
      border-radius: 20px;
      cursor: pointer;
      transition: 0.2s linear;
      white-space: nowrap;

      &.active {
        background-color: var(--light);
        box-shadow: 0px 0px 4px var(--dark-15);
      }
    }
  }

  @media (max-width: 768px) {
    width: calc(100vw - 30px);
    padding-right: 30px;
    overflow-y: auto;
    border-radius: 25px 0 0 25px;

    &::-webkit-scrollbar {
      display: none;
    }
  }
`;
