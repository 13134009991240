import React, { useState } from "react";
import styled, { css } from "styled-components";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";

//
import DocDownload from "../doc-download/DocDownload";
import Loader from "../loader/Loader";
import Button from "../button/Button";
import { msToNormTime } from "../../util/date";

export default function DocumentModal({ act }) {
  // helper
  const defaultLayoutPluginInstance = defaultLayoutPlugin();

  // state
  const [isLoading, setIsLoading] = useState(true);

  return (
    <StyledDocumentModal isLoading={isLoading}>
      <nav>
        <h1>{act?.name || "-"}</h1>
        <p>{act?.note || "-"}</p>
      </nav>
      <main>
        <div className="pdf__wrap">
          {isLoading && <Loader bg="bg" w={60} h={60} size={3} />}
          {act?.file_link && (
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.15.349/build/pdf.worker.min.js">
              <Viewer
                fileUrl={
                  "https://ulug-cors-proxy.herokuapp.com/" + act?.file_link
                }
                plugins={[defaultLayoutPluginInstance]}
                onDocumentLoad={() => setIsLoading(false)}
              />
            </Worker>
          )}
        </div>
        <div className="down__pdf__btn">
          <a
            href={act?.file_link || "#"}
            target="_blank"
            rel="noreferrer"
            download
          >
            <Button p={[14, 20]}>
              Скачать PDF <i className="icon icon-sm icon-download"></i>
            </Button>
          </a>
        </div>
      </main>
      <footer>
        <DocDownload
          title={act?.name || "-"}
          text={"От " + msToNormTime(act?.date_created, 3)}
          file={act?.file_link}
        />
      </footer>
    </StyledDocumentModal>
  );
}

const StyledDocumentModal = styled.div`
  padding: 10px 30px 30px;

  nav {
    padding: 0 30px;
    margin-bottom: 30px;

    h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }

  main {
    margin-bottom: 30px;

    .pdf__wrap {
      position: relative;
      min-height: 450px;
      border-radius: 30px;
      overflow: hidden;

      .rpv-core__viewer {
        height: 450px !important;
        opacity: 0;

        ${({ isLoading }) =>
          !isLoading &&
          css`
            opacity: 1;
          `}
      }
    }

    .down__pdf__btn {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    nav {
      background: var(--light);
      box-shadow: 0px 0px 10px var(--dark-5);
      border-radius: 0px 0px 30px 30px;
      padding: 30px;
      margin-bottom: 20px;

      h1 {
        font-size: 16px;
        line-height: 20px;
      }
    }

    main {
      background: var(--light);
      box-shadow: 0px 0px 10px rgba(26, 43, 70, 0.05);
      border-radius: 30px;
      padding: 30px;

      .down__pdf__btn {
        margin-top: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
      }
    }

    footer {
      display: none;
    }
  }
`;
