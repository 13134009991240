import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

//
import Button from "../button/Button";
import IconWrapper from "../icon-wrapper/IconWrapper";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export default function DocDownload({ title, text, file }) {
  const { width } = useWindowDimensions();

  return (
    <StyledDocDownload>
      <div className="doc__download">
        <section>
          <IconWrapper
            icon="pdf icon-lg"
            bg="dark-5"
            color="danger"
            w={60}
            h={60}
            rounded={16}
          />
          <div>
            <h1>{title}</h1>
            <p>{text}</p>
          </div>
        </section>
        <section>
          <a href={file || "#"} target="_blank" rel="noreferrer" download>
            {width < 769 ? (
              <div className="download__wrap">
                <i className="icon icon-download"></i>
              </div>
            ) : (
              <Button p={[14, 20]}>
                Скачать <i className="icon icon-sm icon-download"></i>
              </Button>
            )}
          </a>
        </section>
      </div>
    </StyledDocDownload>
  );
}

const StyledDocDownload = styled.div`
  .doc__download {
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 20px;
    padding: 20px 30px 20px 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;

    section {
      &:nth-child(1) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;

        h1 {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark);
          opacity: 0.5;
        }
      }

      i.icon-download {
        background-color: var(--dark);
      }
    }
  }

  @media (max-width: 768px) {
    .doc__download {
      border-radius: 0;
      padding: 20px 30px;
      background-color: var(--bg);
      border: none;
      width: 100vw;
      transform: translateX(-30px);

      section {
        &:nth-child(1) {
          h1 {
            font-size: 16px;
            line-height: 20px;
          }

          p {
            font-size: 10px;
            line-height: 12px;
          }
        }

        .download__wrap {
          width: 48px;
          height: 48px;
          border: 1px solid var(--primary-dark-15);
          border-radius: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
`;
