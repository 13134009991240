import React from "react";
import styled, { css } from "styled-components";

//
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export default function Card({ children, mobile, bgHide, ...props }) {
  const { width } = useWindowDimensions();

  if (width < 993 && bgHide) return <>{children}</>;

  if (width < 769 && !mobile) return <>{children}</>;

  return <StyledCard {...props}>{children}</StyledCard>;
}

const StyledCard = styled.div`
  background: var(--light);
  box-shadow: 0px 2px 10px var(--dark-5);
  border-radius: 30px;
  height: 100%;
  position: relative;

  /* by codezhama code */
  ${({ rentbron }) =>
    rentbron &&
    css`
      border-radius: 30px;
      @media (max-width: 768px) {
        border-radius: 0 0 30px 30px;
      }
    `}

  ${({ rentbron2 }) =>
    rentbron2 &&
    css`
      margin-top: 20px;
      border-radius: 30px;
      overflow: hidden;
    `}
`;
