import React from "react";
import styled from "styled-components";

//
import DocDownload from "../../../../components/doc-download/DocDownload";
import PregressSection from "../../../../components/rent-book-progress/PregressSection";
import PayCard from "../../../../components/pay-card/PayCard";
import TarifCard from "./TarifCard";

export default function RentModal({ car }) {
  // render
  if (!(car && car.status === "rented")) return null;

  return (
    <StyledRentModal>
      <main>
        <section className="top__tarif__wrapper">
          <TarifCard
            title={car?.tariff}
            all={car?.rent_days_total}
            day={car?.rent_days_remain}
          />
        </section>
        <section className="date__progress__wrapper">
          <PregressSection start={car?.rent_start} end={car?.rent_end} bg='success' />
        </section>
        <section className="profit__pay__card">
          <PayCard
            title={car?.rent_price_total}
            text="Прогноз дохода"
            icon="icon-currency-ruble"
          />
          <PayCard
            title={car?.rent_price_day}
            text="Платеж в сутки"
            icon="icon-wallet"
            color="primary"
          />
        </section>
      </main>
      {car?.rent_agreement_link && (
        <footer>
          <DocDownload
            title="Договор аренды"
            text="Дата заключения 2 апреля 2022 г."
            file={car?.rent_agreement_link}
          />
        </footer>
      )}
    </StyledRentModal>
  );
}

const StyledRentModal = styled.div`
  padding: 30px;
  min-height: calc(100vh - 152px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--light);

  main {
    section {
      &.top__tarif__wrapper {
        margin-bottom: 60px;
      }

      &.date__progress__wrapper {
        margin-bottom: 60px;
        padding: 0 20px;
      }

      &.profit__pay__card {
        padding: 0 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }
    }
  }

  footer {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    padding: 30px 30px 0px;
    min-height: calc(100vh - 54px);

    main {
      section {
        &.date__progress__wrapper {
          margin-bottom: 60px;
          padding: 0;
        }

        &.profit__pay__card {
          padding: 0;
          display: -ms-grid;
          display: grid;
          grid-template-columns: 1fr;
          gap: 20px;
        }
      }
    }
  }
`;
