import React from "react";
import styled from "styled-components";

//
import NumberFormat from "../../../../components/number-format/NumberFormat";
import CarNumber from "../../../../components/car-number/CarNumber";

export default function ItemCar({
  autoName,
  inc,
  cons,
  comm,
  topay,
  carNumber,
  ...props
}) {
  return (
    <StyleItemCar {...props}>
      <div className="left">
        <h1 className="car__name" >{autoName}</h1>
        <CarNumber title={carNumber} />
      </div>
      <div className="right">
        <div>
          <h5>Доход</h5>
          <h4>
            <NumberFormat value={inc} />
          </h4>
        </div>
        <div>
          <h5>Расход</h5>
          <h4>
            <NumberFormat value={cons} />
          </h4>
        </div>
        <div>
          <h5>Комиссия</h5>
          <h4>
            <NumberFormat value={comm} />
          </h4>
        </div>
        <div>
          <h5>К выплате</h5>
          <h4>
            <NumberFormat value={topay} />
          </h4>
        </div>
      </div>
    </StyleItemCar>
  );
}

const StyleItemCar = styled.section`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 220px 1fr;
  gap: 95px;
  padding: 24px 0 24px 27px;

  cursor: pointer;

  .left {
    .car__name {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      white-space: nowrap;
      margin-bottom: 10px;
    }
  }

  .right {
    grid-template-columns: repeat(4, 201px);
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center;
    gap: 20px;
    & > div {
      padding: 16px 0;
      & > h5 {
        display: none;
      }
      & > h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: dark;
      }
      &:nth-child(2n + 1) {
        text-align: center;
        background-color: var(--dark-5);
        border-radius: 14px;
      }
    }
  }

  @media (max-width: 768px) {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 20px 0 20px 30px;
    .right {
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
      & > div {
        padding: 14px 16px;
        text-align: start;
        border-radius: 14px;
        border: 1px solid var(--primary-dark-15);
        background-color: var(--light) !important;
        & > h5 {
          display: block;
          white-space: nowrap;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          margin-bottom: 5px;
          color: var(--dark-70);
        }
        & > h4 {
          white-space: nowrap;
        }
        &:nth-child(1) {
          text-align: start;
          margin-bottom: 5px;
          background-color: var(--success-20) !important;
          border: 1px solid var(--primary-dark-15);
        }
        &:nth-child(2) {
          background: rgba(255, 74, 62, 0.1) !important;
          border: 1px solid var(--primary-dark-15);
        }
        &:nth-child(2n + 1) {
          text-align: initial;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      overflow-x: scroll;

      &::-webkit-scrollbar {
        width: 0;
      }
    }
  }
`;
