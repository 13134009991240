import React from "react";
import styled from "styled-components";

//
import StatePark from "./section/StatePark";
import ProfitDownload from "./section/ProfitDownload";
import TableCar from "./section/TableCar";

export default function Dashboard() {
  return (
    <StyledDashboard>
      <StatePark />
      <ProfitDownload />
      <TableCar />
    </StyledDashboard>
  );
}

const StyledDashboard = styled.div``;
