import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_CHART_DATA, GET_ANAL_CARS } from "./type";

export function useGetChartData(from, to, onSuccess) {
  return useQuery([GET_CHART_DATA, from, to], () => Api.getChartData(from, to), {
    onSuccess
  });
}

export function useGetAnalCars(from, to) {
  return useQuery([GET_ANAL_CARS, from, to], () =>
    Api.getCarTableData(from, to)
  );
}
