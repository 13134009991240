import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";

//
import Card from "../../../components/card/Card";
import CardTwo from "../../../components/card-2/CardTwo";
import ActImg from "../../../asset/img/notif_doc.svg";
import NotifApi from "../../../store/notif/api";
import map from "../../../asset/img/map-act.png";
import Consts from "../../../util/const";
import { useGetAct } from "../../../store/doc/hook";

export default function ActNotificationItem() {
  // helper
  const params = useParams();

  // query
  let { data: act } = useGetAct(
    params.id,
    localStorage.getItem(Consts.DOC_DATE)
  );
  console.log(act);
  useQuery("READ_NOTIF", () =>
    NotifApi.putNotif({ notification_id: +params.id, read_status: true })
  );

  return (
    <StyleActNotificationItem>
      <main>
        <div className="wrapp__card">
          <Card mobile={1} rentbron>
            <section className="left">
              <div className="left__item">
                <div className="info__read">
                  <i className="icon icon-alert-circle" />
                </div>

                <div className="left__item__text">
                  <h3>{act?.name || "-"}</h3>
                  <h4>{act?.note || "-"}</h4>
                </div>
              </div>
            </section>
          </Card>
          <CardTwo>
            <section className="right">
              <div className="map">
                <img src={map} alt="map" />
              </div>
              <div className="address">
                <div className="address__btn">Режим работы: с 10 до 17</div>
                <p>г Санкт-Петербург, пр. Маршала Блюхера, 12 к.7</p>
              </div>
            </section>
          </CardTwo>
        </div>
        <Card mobile={1}>
          <section className="act__photo">
            <img src={ActImg} alt="act-img" />
          </section>
        </Card>
      </main>
    </StyleActNotificationItem>
  );
}

const StyleActNotificationItem = styled.div`
  margin-top: 30px;
  & > main {
    & > .wrapp__card {
      display: -ms-grid;
      display: grid;
      grid-template-columns: 750px auto;
      gap: 20px;
      .left {
        padding: 30px 30px 50px 30px;

        &__item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 20px;
          padding: 30px;
          background-color: var(--primary-5);
          border-radius: 30px;

          .info__read {
            width: 48px;
            height: 48px;
            min-height: 48px;
            min-width: 48px;
            border-radius: 50%;
            background-color: var(--light);
            display: none;

            & > .icon-alert-circle {
              background-color: var(--primary);
            }
          }

          &__text {
            & > h3 {
              font-size: 22px;
              font-weight: 500;
              line-height: 27px;
              letter-spacing: 0em;
              color: var(--primary);
            }

            & > h4 {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
          }
        }
      }

      .right {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 30px 0 30px 30px;
        gap: 30px;

        & > .map {
          line-height: 0;

          img {
          }
        }

        .address {
          &__btn {
            width: max-content;
            padding: 6px 16px;
            border-radius: 16px;
            background-color: var(--success-15);
            margin-bottom: 10px;
            color: var(--success-dark);
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
          }
          & > p {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark-07);
            max-width: 206px;
          }
        }
      }
    }
    .act__photo {
      padding: 86px;
      margin-top: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }
  }

  @media (max-width: 1200px) {
    margin: 0;

    & > main {
      & > .wrapp__card {
        grid-template-columns: 1fr;

        .left {
          padding: 30px 30px;

          &__item {
            padding: 20px 22px 20px 20px;
            background-color: var(--primary-15);
            border-radius: 20px;

            .info__read {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
            }

            &__text {
              & > h3 {
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                margin-bottom: 5px;
              }
              & > h4 {
                display: block;
              }
            }
          }
        }

        .right {
          padding: 30px 62px 30px 30px;
        }
      }
    }
  }
`;
