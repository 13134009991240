import React from "react";
import styled from "styled-components";

//
import ProgressBar from "../preogress-bar/ProgressBar";
import { msToNormTime } from "../../util/date";

export default function PregressSection({
  start = 1640984400,
  end = 1640984400,
  bg,
  booked,
  startText = "Начало аренды",
  endText = "Конец аренды",
}) {
  function getPercent() {
    if (booked) return 100;
    start *= 1000;
    end *= 1000;
    let rentDaySum = end - start;
    const now = Date.now();

    let rentedDaySum = now - start;

    let percent = Math.round((rentedDaySum / rentDaySum) * 100);

    return percent < 0 ? 0 : percent;
  }

  return (
    <StyledPregressSection>
      <div>
        <p>
          {startText} <br />
          <span>{msToNormTime(start, 3)}</span>
        </p>
        <div className="line">
          <i className="icon icon-arrow-right"></i>
        </div>
        <p>
          {endText} <br />
          <span>{msToNormTime(end, 3)}</span>
        </p>
      </div>
      <ProgressBar
        rent={1}
        fill={1}
        value={getPercent()}
        bg={bg}
        book={booked}
      />
    </StyledPregressSection>
  );
}

const StyledPregressSection = styled.div`
  div {
    &:nth-child(1) {
      margin-bottom: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;

      .line {
        min-width: 40px;
        height: 2px;
        background-color: var(--primary-dark-15);
        position: relative;
        width: 25%;

        i {
          width: 22px;
          height: 22px;
          position: absolute;
          top: 50%;
          right: 2px;
          transform: translate(100%, -52%);
          background-color: var(--primary-dark-15);
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
        white-space: nowrap;

        span {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
        }

        &:nth-child(3) {
          text-align: right;
        }

        &:nth-child(1) {
          opacity: 0.7;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    div {
      &:nth-child(1) {
        flex-direction: column;

        .line {
          width: 50%;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark);
          white-space: nowrap;
          text-align: center !important;
        }
      }
    }
  }
`;
