/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

//
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

export default function YearsModal({ from, to, onClose, setDate }) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [step, setStep] = useState(0);
  const [value, setValue] = useState({
    from,
    to,
  });

  // method
  const yearList = useMemo(() => {
    const year = new Date().getFullYear();
    const list = new Array(width < 769 ? 6 : 8).fill("").map((i, idx) => {
      i = year - idx - step;
      return i;
    });
    return list.filter((i) => i >= 2022).reverse();
  }, [width]);

  function prevStep(e) {
    e.stopPropagation();
    if (prevIsDisable) return;
    setStep((p) => p + (width < 769 ? 6 : 8));
  }

  const prevIsDisable = useMemo(() => {
    return yearList[0] === 2022;
  }, [yearList]);

  function nextStep(e) {
    e.stopPropagation();
    if (nextIsDisable) return;
    setStep((p) => p - (width < 769 ? 6 : 8));
  }

  const nextIsDisable = useMemo(() => {
    const curYear = new Date().getFullYear();
    if (yearList[yearList.length - 1] === curYear) return true;
  }, [yearList]);

  function getClassNames(year) {
    const { from, to } = value;
    if (year === to || year === from) return "active";

    if (year < to && year > from) return "selected";

    return "";
  }

  function chooseYear(y) {
    const { from, to } = value;

    if (from === to) {
      if (from < y) {
        setValue((p) => ({ ...p, to: y }));
      } else {
        setValue((p) => ({ ...p, from: y }));
      }
      return;
    }
    if (from === y) {
      setValue((p) => ({ ...p, to: p.from }));
      return;
    }

    if (to === y) {
      setValue((p) => ({ ...p, from: p.to }));
      return;
    }

    setValue((p) => ({ ...p, from: y, to: y }));
  }

  function handleSubmit(e) {
    e.stopPropagation();
    setDate((p) => ({
      ...p,
      type: "year",
      yFrom: value.from,
      yTo: value.to,
    }));
    onClose();
  }

  function handleClose(e) {
    e.stopPropagation();
    onClose();
  }

  return (
    <StyledYearsModal len={yearList.length}>
      <section>
        <i
          className={cl("icon icon-chevron-right prev", {
            disabled: prevIsDisable,
          })}
          onClick={prevStep}
        ></i>
        <ul>
          {yearList.map((i) => (
            <li
              key={i}
              className={getClassNames(i)}
              onClick={(e) => {
                e.stopPropagation();
                chooseYear(i);
              }}
            >
              {i}
            </li>
          ))}
        </ul>
        <i
          className={cl("icon icon-chevron-right next", {
            disabled: nextIsDisable,
          })}
          onClick={nextStep}
        ></i>
      </section>
      <footer>
        <div className="ic__wrap" onClick={handleClose}>
          <i className="icon icon-close"></i>
        </div>
        <div className="ic__wrap" onClick={handleSubmit}>
          <i className="icon icon-check"></i>
        </div>
      </footer>
    </StyledYearsModal>
  );
}

const StyledYearsModal = styled.div`
  padding: 30px;

  & > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;

    i {
      &.prev {
        transform: rotate(180deg);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    ul {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      ${({ len }) => {
        if (len < 4)
          return css`
            grid-template-columns: repeat(${len}, 1fr);
          `;
      }}

      li {
        width: 100px;
        background: var(--primary-dark-3);
        border-radius: 14px;
        padding: 14px 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        width: 100px;
        text-align: center;
        cursor: pointer;
        transition: 0.2s linear;

        &.active {
          background-color: var(--primary);
          color: var(--light);
        }

        &.selected {
          background: var(--primary-10);
          color: var(--primary);
        }
      }
    }
  }

  & > footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;

    .ic__wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid var(--primary-dark-15);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    & > section {
      flex-direction: column;

      i.prev {
        transform: rotate(-90deg);
      }

      i.next {
        transform: rotate(90deg);
      }

      ul {
        grid-template-columns: repeat(3, 1fr);

        ${({ len }) => {
          if (len < 4)
            return css`
              grid-template-columns: repeat(${len}, 1fr);
            `;
        }}
      }
    }
  }
`;
