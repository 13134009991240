import React, { useState } from "react";
import styled, { css } from "styled-components";

//
import AlertModal from "../alert-modal/AlertModal";

export default function AlertIcon({ text, color }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledAlertIcon
      onMouseOver={() => setIsOpen(true)}
      onMouseOut={() => setIsOpen(false)}
      color={color}
    >
      <i className="icon icon-sm icon-alert-circle"></i>
      <AlertModal show={isOpen}>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </AlertModal>
    </StyledAlertIcon>
  );
}

const StyledAlertIcon = styled.div`
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  cursor: pointer;
  position: relative;
  transition: 0.2s linear;
  background-color: var(--light);

  i {
    background-color: var(--success);
  }

  &:hover {
    background-color: var(--success);

    i {
      background-color: var(--light);
    }
  }

  ${({ color }) =>
    color === "dark"
      ? css`
          i {
            background-color: var(--dark);
          }

          &:hover {
            background-color: var(--dark-10);
            
            i {
              background-color: var(--dark);
            }
          }
        `
      : css``}
`;
