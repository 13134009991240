import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_DOCS, GET_DOC_BY_ID, GET_ACT } from "./type";

export function useGetDocs(options) {
  return useQuery(GET_DOCS, Api.getDocs, { ...options });
}

export function useGetDocById(id, date) {
  return useQuery([GET_DOC_BY_ID, id, date], () => Api.getDocById(id, date));
}

export function useGetAct(id, date, options = {}) {
  return useQuery([GET_ACT, id, date], () => Api.getAct(id, date), {
    ...options,
  });
}
