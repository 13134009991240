import React, { useState } from "react";
import styled from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import Card from "../../../../components/card/Card";
import CarNumber from "../../../../components/car-number/CarNumber";
import PregressSection from "../../../../components/rent-book-progress/PregressSection";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import carImg from "../../../../asset/img/sad.png";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { msToNormTime } from "../../../../util/date";

export default function CarCard({ book, car }) {
  const { width } = useWindowDimensions();
  const [isOpen, setIsOpen] = useState(false);

  return (
    <li
      className={cl("car__parent", {
        active__border: isOpen,
      })}
    >
      <div className="car" onClick={() => setIsOpen((p) => !p)}>
        <div className="car__left">
          <div className="icon__img">
            <i
              className="icon icon-chevron-right"
              style={{ transform: isOpen ? "rotate(90deg)" : "rotate(0deg)" }}
            />
            <div className="car__left__img">
              <img
                src={car?.car_pic_url || carImg}
                alt={"carimg"}
                width={145}
              />
            </div>
          </div>
          <div className="car__left__name">
            <p>{car?.brand_model || "Car model"}</p>
            <CarNumber title={car?.gos_num} size='sm' />
          </div>
        </div>

        <div className="car__right">
          {width < 769 ? (
            <div className="response__price__day">
              <h3>Сумма дохода</h3>
              <h2>
                <NumberFormat
                  value={book ? car?.book_price_total : car?.rent_price_total}
                />
              </h2>
            </div>
          ) : (
            <p className="price">
              <NumberFormat
                value={book ? car?.book_price_total : car?.rent_price_total}
              />
            </p>
          )}
          {width < 769 ? (
            <div className="response__defferent__day">
              <h3>Осталось дней</h3>
              <h2>
                {book
                  ? car?.book_days_total || 0
                  : (car?.rent_days_remain || 0) +
                    "/" +
                    car?.rent_days_total}{" "}
                д.
              </h2>
            </div>
          ) : (
            <div className="different__day">
              {book
                ? car?.book_days_total || 0
                : (car?.rent_days_remain || 0) +
                  "/" +
                  car?.rent_days_total}{" "}
              д.
            </div>
          )}

          <i
            className={cl("icon icon-chevron-right")}
            style={{ transform: isOpen ? "rotate(-90deg)" : "rotate(90deg)" }}
          />
        </div>
      </div>

      <Collapse isOpened={isOpen}>
        {isOpen && (
          <StyleInnerAccardion>
            <Card mobile={1}>
              <div className="collabsible__left">
                {/* <div
                  className={cl("info__icon", {
                    book,
                  })}
                  onMouseOver={() => setAlert(true)}
                  onMouseOut={() => setAlert(false)}
                >
                  <i className="icon icon-alert-circle"></i>
                  <AlertModal show={alert}>
                    Сумма предполагаемая. <br /> В итоге сумма может <br /> быть
                    другой.
                  </AlertModal>
                </div> */}
                <div className="collabsible__left__currency">
                  <div className={!book ? "currency__rubl " : "bron__warning"}>
                    <i className="icon icon-currency-ruble" />
                  </div>
                  <div className="currency__text">
                    <h3 className="currency__text__top">Цена аренды</h3>
                    <h2 className="currency__text__bottom">
                      <NumberFormat
                        value={book ? car?.book_price_day : car?.rent_price_day}
                      />
                    </h2>
                  </div>
                </div>
                <div className="return__car">
                  <div className="return__car__icon">
                    {!book ? (
                      <i className="icon icon-reverse-left" />
                    ) : (
                      <i className="icon icon-calendar" />
                    )}
                  </div>
                  <div className="return__car__text">
                    <h3 className="return__car__text__top">
                      {!book ? "Возврат авто" : "Дата бронирования"}
                    </h3>
                    <h2 className="return__car__text__bottom">
                      {msToNormTime(book ? car?.book_end : car?.rent_end, 3)}
                    </h2>
                  </div>
                </div>
              </div>
            </Card>

            <Card mobile={1}>
              <div className="collabsible__right">
                <div className="collabsible__right__header">
                  <h3>Тарифный план</h3>
                  <h2>{car?.tariff || "Tarif title"}</h2>
                </div>
                <PregressSection
                  start={book ? car?.book_start : car?.rent_start}
                  end={book ? car?.book_end : car?.rent_end}
                  booked={book}
                />
              </div>
            </Card>
          </StyleInnerAccardion>
        )}
      </Collapse>
    </li>
  );
}

const StyleInnerAccardion = styled.div`
  padding: 30px 30px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  .collabsible__left {
    position: relative;
    padding: 30px;

    .info__icon {
      position: absolute;
      right: 2.5%;
      top: 3.5%;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-color: var(--success-10);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
      transition: 0.2s linear;

      i {
        background-color: var(--success-dark);
      }

      &:hover {
        background-color: var(--success);

        i {
          background-color: var(--light);
        }
      }

      &.book {
        background-color: var(--warning-10);

        i {
          background-color: var(--warning-dark);
        }

        &:hover {
          background-color: var(--warning);

          i {
            background-color: var(--light);
          }
        }
      }
    }

    &__currency {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      .currency__rubl {
        padding: 25px 11px 24px 11px;
        background-color: var(--success-20);
        border-radius: 14.5px;
        .icon-currency-ruble {
          background-color: var(--success-dark);
        }
      }
      .bron__warning {
        padding: 25px 11px 24px 11px;
        background-color: var(--warning-10);
        border-radius: 14.5px;
        .icon-currency-ruble {
          background-color: var(--warning);
        }
      }

      .currency__text {
        &__top {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
          margin-bottom: 5px;
        }
        &__bottom {
          font-size: 22px;
          font-weight: 600;
          line-height: 27px;
          letter-spacing: 0em;
        }
      }
    }

    .return__car {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      margin-top: 10px;
      gap: 30px;

      &__icon {
        padding: 25px 11px 24px 11px;
        background-color: var(--primary-15);
        border-radius: 14.5px;
        .icon-reverse-left {
          background-color: var(--primary);
        }
        .icon-calendar {
          background-color: var(--primary);
        }
      }
      &__text {
        &__top {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
        &__bottom {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
        }
      }
    }
  }

  .collabsible__right {
    padding: 30px;

    &__header {
      text-align: center;
      margin-bottom: 35px;

      & > h3 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
      & > h2 {
        font-size: 20px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }
  }

  @media (max-width: 1200px) {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    padding: 30px 0;

    .collabsible__left {
      .currency__text {
        &__top {
          font-size: 12px;
          line-height: 15px;
        }
        &__bottom {
          font-size: 18px;
          line-height: 22px;
          white-space: nowrap;
        }
      }
    }

    .collabsible__right {
      &__different__day {
        .line__horizontal {
        }

        .size__dif {
          & > h3 {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
          }
          & > h2 {
            font-size: 16px;
            font-weight: 600;
            line-height: 20px;
          }
        }
      }
    }
  }
`;
