/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";

//
import Top from "./section/Top";
import Chart from "../../../components/chart/Chart";
import Detailing from "./section/Detailing";
import TableCarAnal from "./section/TableCarAnal";
import Consts from "../../../util/const";
import { getMarkModelList } from "../../../util/formatters";
import { getFromTo, msToNormTime } from "../../../util/date";
import { useGetChartData, useGetAnalCars } from "../../../store/analytic/hook";

export default function Analytic() {
  // state
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [sumDays, setSumDays] = useState(0);
  const [closed, setClosed] = useState(false);
  const [filterList, setFilterList] = useState([]);
  const [search, setSearch] = useState("");
  const [lastClosedTime, setLastClosedTime] = useState(null);
  const now = new Date();
  const [date, setDate] = useState({
    type: "",
    yFrom: now.getFullYear(),
    yTo: now.getFullYear(),
    year: now.getFullYear(),
    mFrom: [now.getFullYear(), now.getMonth()],
    mTo: [now.getFullYear(), now.getMonth()],
  });
  const [fromToMs, setFromToMs] = useState({
    from: 0,
    to: 0,
  });

  // time formatter
  const fromToValue = useMemo(() => {
    let now = new Date();
    let from = 0;
    let to = 0;

    if (date.type === "") {
      const result = getFromTo(2);
      to = result.to;
      from = result.from;
    }

    if (date.type === "all") {
      const result = getFromTo(1);
      to = result.to;
      from = result.from;
    }

    if (date.type === "year") {
      from = new Date(date.yFrom, 0, 1, "00", "00", "00").getTime() / 1000;
      to = new Date(date.yTo, 11, 31, "23", "59", "59").getTime() / 1000;
    }

    if (date.type === "month") {
      from = new Date(date.mFrom[0], date.mFrom[1]).getTime() / 1000;
      to = new Date(date.mTo[0], date.mTo[1] + 1).getTime() / 1000 - 1;
    }

    now =
      new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate(),
        "23",
        "59",
        "59"
      ).getTime() / 1000;

    to = to > now ? now : to;

    setFromToMs({ from, to });

    getTimeForUi(from, to);

    localStorage.setItem(Consts.ANAL_FROM_TO, JSON.stringify({ from, to }));

    return {
      from,
      to,
    };
  }, [date]);

  // query
  let { isLoading: chart_loading, data: chart } = useGetChartData(
    fromToValue.from,
    fromToValue.to,
    onSuccess
  );
  chart = chart?.data?.data;
  let { isLoading: car_loading, data: car } = useGetAnalCars(
    fromToValue.from,
    closed ? lastClosedTime : fromToValue.to
  );
  car = car?.data?.data;

  // watcher
  useEffect(() => {
    setFilterList([...getMarkModelList(car?.anal_car_list)]);
  }, [car]);

  // method
  function onSuccess(v) {
    v = v.data.data;
    v.profits_breakes.forEach((i) => {
      if (i.closed) {
        let tempTime =
          new Date(i.year, i.month, 1, "00", "00", "00").getTime() / 1000 - 1;
        setLastClosedTime(tempTime);
      }
    });
  }

  const filteredList = useMemo(() => {
    let tempFilterList = [];
    filterList.forEach((i) => {
      i.models.forEach((j) => {
        if (j.isChecked) {
          tempFilterList.push(i.mark + " " + j.name);
        }
      });
    });
    return tempFilterList;
  }, [filterList]);

  function getTimeForUi(from, to) {
    let difDate = to - from;
    difDate = Math.round(difDate / 86400);
    setSumDays(difDate);
    from = msToNormTime(from, 3);
    setFromDate(from);
    to = msToNormTime(to, 3);
    setToDate(to);
  }

  return (
    <StyledAnalytic>
      <h1>Статистика за период</h1>
      <Top
        date={date}
        setDate={setDate}
        fromDate={fromDate}
        toDate={toDate}
        sumDays={sumDays}
        chart_loading={chart_loading}
        profitTotal={chart?.profit_total || 0}
        profitMonth={chart?.profit_current_month || 0}
      />
      <Chart
        list={chart?.profits_breakes || []}
        loading={chart_loading}
        closed={closed}
      />
      <Detailing
        closed={closed}
        setClosed={setClosed}
        loading={car_loading}
        fromToMs={fromToMs}
        type={date.type}
        cars={car?.anal_car_list || []}
        filterList={filterList}
        setFilterList={setFilterList}
        filter={filteredList}
        search={search}
        setSearch={setSearch}
        lastClosedTime={lastClosedTime}
      />
      <TableCarAnal
        loading={car_loading}
        cars={car?.anal_car_list || []}
        filter={filteredList}
        search={search}
      />
    </StyledAnalytic>
  );
}

const StyledAnalytic = styled.div`
  & > h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    margin-bottom: 30px;
  }

  @media (max-width: 768px) {
    & > h1 {
      display: none;
    }
  }
`;
