import React from "react";
import NumberFormatter from "react-number-format";

export default function NumberFormat({ value = 0, suffix = "₽" }) {
  return (
    <NumberFormatter
      value={value}
      thousandSeparator={true}
      displayType="text"
      suffix={" " + suffix}
    />
  );
}
