import React, { useRef, useState } from "react";
import styled from "styled-components";

export default function Avatar({ avatar, changeImgUrl }) {
  const [img, setImg] = useState(null);
  const inputRef = useRef();

  function uploadImg(e) {
    let file = e.target.files[0];
    setImg(file);
    getBase64(file);
  }

  const getBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      changeImgUrl(reader.result);
    };
  };

  return (
    <StyledAvatar>
      <div className="upload__sloy" onClick={() => inputRef.current.click()}>
        <i className="icon icon-lg icon-upload"></i>
        <input
          type="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={uploadImg}
        />
      </div>
      <img
        src={
          img
            ? URL.createObjectURL(img)
            : avatar ||
              "https://freepikpsd.com/file/2019/10/default-avatar-png-Transparent-Images.png"
        }
        alt="avatar"
      />
    </StyledAvatar>
  );
}

const StyledAvatar = styled.div`
  display: inline-block;
  width: 100px;
  height: 100px;
  border-radius: 30px;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .upload__sloy {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--light-10);
    backdrop-filter: blur(4px);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;
    transition: 0.2s linear;
    opacity: 0;
    visibility: hidden;
    border-radius: 30px;

    i {
      background-color: var(--dark);
    }
  }

  &:hover {
    .upload__sloy {
      opacity: 1;
      visibility: visible;
    }
  }

  @media (max-width: 768px) {
    width: 80px;
    height: 80px;
  }
`;
