import React, { useState } from "react";
import styled, { css } from "styled-components";
import ReactOutsideClickHandler from "react-outside-click-handler";
import cl from "classnames";

//
import Button from "../button/Button";

export default function Select({
  value = "",
  options = [],
  onChange,
  dateSort,
}) {
  const [isOpen, setIsOpen] = useState(false);

  function handleSelect(v) {
    onChange(v);
    setIsOpen(false);
  }

  return (
    <ReactOutsideClickHandler onOutsideClick={() => setIsOpen(false)}>
      <StyledSearch isOpen={isOpen} dateSort={dateSort}>
        <nav onClick={() => setIsOpen(true)}>
          <i className="icon icon-sm icon-filter-lines"></i>
          <p>{options.find((i) => i.value === value).title}</p>
          <i className="icon icon-chevron-selector"></i>
        </nav>
        {isOpen && (
          <main>
            <ul>
              {options.map((i) => (
                <li
                  key={i.id}
                  onClick={() => handleSelect(i.value)}
                  className={cl({
                    selected: i.value === value,
                  })}
                >
                  {i.title}
                </li>
              ))}
            </ul>
            <div className="cancel__btn">
              <Button onClick={() => setIsOpen(false)}>Отмена</Button>
            </div>
          </main>
        )}
      </StyledSearch>
    </ReactOutsideClickHandler>
  );
}

const StyledSearch = styled.div`
  background: var(--light);
  border: 1px solid var(--primary-dark-15);
  border-radius: 14px;
  cursor: pointer;
  transition: 0.2s linear;
  position: relative;
  min-width: ${({ dateSort }) => (dateSort ? "310px" : "250px")};

  & > nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 10px;
    padding: 14px;
    transition: 0.2s linear;
    border-radius: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);

    i.icon-filter-lines {
      background-color: var(--dark);
      margin-right: 10px;
    }

    i.icon-chevron-selector {
      background-color: var(--dark);
      opacity: 0.7;
      margin-left: auto;
    }
  }

  & > main {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: calc(100% + 2px);
    background-color: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-top-color: transparent;
    border-radius: 0 0 14px 14px;
    padding-right: 3px;
    overflow: hidden;
    z-index: 2;

    ul {
      max-height: 260px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: var(--dark-10);
        border-radius: 5px;
      }

      li {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
        padding: 16px 30px;
        transition: 0.2s linear;

        &:hover {
          background-color: var(--dark-5);
          color: var(--dark);
        }

        &.selected {
          background-color: var(--primary-15);
          color: var(--primary);
        }
      }
    }

    .cancel__btn {
      display: none;
    }
  }

  &:hover {
    border-color: transparent;

    & > nav {
      background: var(--primary-dark-5);
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-radius: 14px 14px 0 0;
      box-shadow: 0px 5px 20px var(--dark-10);

      & > nav {
        border-radius: 14px 14px 0 0;
      }

      & > main {
        box-shadow: 0px 20px 20px var(--dark-10);
      }

      &:hover {
        border-color: var(--primary-dark-15);

        & > nav {
          background: var(--light);
        }
      }
    `}

  @media (max-width : 768px) {
    min-width: auto;

    & > nav {
      i.icon-filter-lines {
        display: none;
      }
    }

    & > main {
      position: fixed;
      bottom: auto;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 2;
      transform: translate(0%, 0%);
      border-radius: 0 0 30px 30px;
      padding: 20px;
      ul {
        max-height: 400px;
        li {
          border-radius: 14px;
        }
      }

      .cancel__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        margin: 30px 0 0;
      }
    }
  }
`;
