import React from "react";
import styled from "styled-components";

//
import Loader from "../../../../components/loader/Loader";

export default function Document({ sts, pts, load }) {
  return (
    <StyledDocument>
      <DocumentItem type={1} sts={sts} load={load} />
      <DocumentItem type={2} pts={pts} load={load} />
    </StyledDocument>
  );
}

function DocumentItem({ type, sts, pts, load }) {
  const title1 = type === 1 ? "СТС" : "ПТС";
  const title2 = type === 1 ? "Номер свидетельства" : "Серия и номер";
  const title3 = type === 1 ? "Гос. номер" : "VIN";

  function carNumFormatter(n) {
    if (!n) return "";
    // Н616СЕ178
    // H 616 CE 178
    n = n.split("");

    return (
      n.slice(0, 1).join("") +
      " " +
      n.slice(1, 4).join("") +
      " " +
      n.slice(4, 6).join("") +
      " " +
      n.slice(6).join("")
    );
  }

  return (
    <StyledDocItem>
      {load && <Loader />}
      <div className="type__block">{title1}</div>
      <ul className="info__list">
        <li>
          <h1>{title2}</h1>
          <p>{(type === 1 ? sts?.doc_num : pts?.doc_num) || "-"}</p>
        </li>
        <li>
          <h1>{title3}</h1>
          <p>
            {(type === 1 ? carNumFormatter(sts?.gos_num) : pts?.vin) || "-"}
          </p>
        </li>
        <li>
          <h1>Дата выдачи</h1>
          <p>{(type === 1 ? sts?.year : pts?.year) || "-"} год</p>
        </li>
        <li>
          <h1>Кем выдан</h1>
          <p>{(type === 1 ? sts?.who : pts?.who) || "-"}</p>
        </li>
      </ul>
    </StyledDocItem>
  );
}

const StyledDocument = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const StyledDocItem = styled.div`
  background-color: var(--light);
  padding: 80px 60px 40px;
  box-shadow: 0px 2px 10px var(--primary-dark-5);
  border-radius: 30px;
  position: relative;
  overflow: hidden;

  .type__block {
    position: absolute;
    top: 0;
    left: 0;
    background: var(--primary-10);
    border-radius: 21px 30px 30px 0px;
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--primary);
    padding: 16px 30px 17px 60px;
  }

  .info__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;

    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }

  @media (max-width: 992px) {
    padding: 80px 40px 40px;
  }
`;
