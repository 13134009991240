import api from "../../service/axios";

class RentBookApi {
  static getRBSummary() {
    let url = `/getRBSummary`;

    return api.get(url);
  }

  static getRBCarList() {
    let url = `/getRBCarList`;

    return api.get(url);
  }
}

export default RentBookApi;
