import React from "react";
import styled from "styled-components";

//
import IconWrapper from "../icon-wrapper/IconWrapper";

export default function MobTop({ title, onBack }) {
  return (
    <StyledMobTop>
      <IconWrapper w={48} h={48} icon='arrow-narrow-left' bg='primary' color="light" border={1} borderColor='light-10' onClick={onBack} />
      <p>{title || "Car model"}</p>
    </StyledMobTop>
  );
}

const StyledMobTop = styled.div`
  background: var(--primary);
  box-shadow: 0px 0px 20px var(--primary-dark-15);
  padding: 11px 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  transform: translateY(-1%);

  p {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    color: var(--light);
  }
`;
