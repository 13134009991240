import React, { useMemo, useState } from "react";
import styled from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";
import { useParams, useNavigate } from "react-router-dom";
import { useMutation, useQueryClient } from "react-query";

//
import CardTwo from "../../../components/card-2/CardTwo";
import OperationCard from "../../../components/operation-card/OperationCard";
import ApproveModal from "./components/ApproveModal";
import CardReject from "./components/CardReject";
import DissmisModal from "./components/DissmisModal";
import ItemCar from "./components/ItemCar";
import ItemCarMoreInfo from "./components/ItemCarMoreInfo";
import Button from "../../../components/button/Button";
import Consts from "../../../util/const";
import IconWrapper from "../../../components/icon-wrapper/IconWrapper";
import NumberFormat from "../../../components/number-format/NumberFormat";
import Loader from "../../../components/loader/Loader";
import DocApi from "../../../store/doc/api";
import MobTop from "../../../components/mobile-top-back/MobTop";
import { GET_DOC_BY_ID } from "../../../store/doc/type";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { useGetDocById, useGetDocs } from "../../../store/doc/hook";
import { msToNormTime } from "../../../util/date";

///
export default function OneDoc() {
  // helper
  const { width } = useWindowDimensions();
  const params = useParams();
  const navigate = useNavigate();

  // state
  const [operationIds, setOperationIds] = useState([]);
  const [detailIds, setDetailIds] = useState([]);
  const [show, setShow] = useState(false);
  const [type, setType] = useState(false);
  const [sort, setSort] = useState(null);

  // query
  const queryClient = useQueryClient();
  const date_created = localStorage.getItem(Consts.DOC_DATE);
  let { isLoading, data: doc } = useGetDocById(params.id, date_created);
  doc = doc?.data?.data;
  const mutation = useMutation(
    (obj) => DocApi.postReport({ ...obj, doc_id: params.id, date_created }),
    { onSuccess: onSuccessPostReport }
  );
  let { data: list } = useGetDocs();
  let doc_info = (list?.data?.data || []).find((i) => params.id === i.doc_id);

  // method
  function handlePostReport(status, comment) {
    mutation.mutate({ status, comment });
  }

  function onSuccessPostReport() {
    setShow(false);
    setType(false);
    window.scroll(0, 0);
    queryClient.resetQueries([GET_DOC_BY_ID, params.id, date_created]);
  }

  const totalEconomy = useMemo(() => {
    let income = 0;
    let expense = 0;
    let comission = 0;
    let pay = 0;

    doc?.report_car_list.forEach((i) => {
      income += i.income;
      expense += i.expense;
      comission += i.comission;
      pay += i.pay;
    });

    return {
      income: income.toFixed(),
      expense: expense.toFixed(),
      comission: comission.toFixed(),
      pay: pay.toFixed(),
    };
  }, [doc]);

  const listForMap = useMemo(() => {
    if (!doc) return [];
    if (!sort) return [...doc.report_car_list];

    return [...doc.report_car_list].sort((a, b) => {
      if (sort.value === 0) {
        return b[sort.type] - a[sort.type];
      } else {
        return a[sort.type] - b[sort.type];
      }
    });
  }, [sort, doc]);

  function toggleDropDown(id) {
    if (operationIds.includes(id)) {
      setOperationIds((p) => p.filter((i) => i !== id));
    } else {
      setOperationIds((p) => [...p, id]);
    }
  }

  function toggleDetail(id) {
    if (detailIds.includes(id)) {
      setDetailIds((p) => p.filter((i) => i !== id));
    } else {
      setDetailIds((p) => [...p, id]);
    }
  }

  function openModalFuncDissmiss() {
    setShow(true);
    setType(false);
  }

  function openModalFuncApprove() {
    setShow(true);
    setType(true);
  }

  function getTitle() {
    if (doc?.doc_info?.type === "report") return doc?.doc_info?.name || "-";
    return "";
  }

  function changeSort(type) {
    let value = 0;
    if (sort && sort.value === 1) {
      setSort(null);
      return;
    }

    if (sort?.type === type && sort?.value === 0) {
      value = 1;
    }

    setSort({ type, value });
  }

  return (
    <StyledOneDoc>
      {width < 769 && (
        <MobTop
          title={
            "Отчёт за " + msToNormTime(doc_info?.date_created, 6).toLowerCase()
          }
          onBack={() => navigate(-1)}
        />
      )}

      {doc?.doc_info.status === "Pending" && (
        <div className={show ? "modal__window active__modal" : "modal__window"}>
          {type ? (
            <DissmisModal
              setShow={setShow}
              show={show}
              createdAt={doc_info.date_created}
              handlePostReport={handlePostReport}
              isLoading={mutation.isLoading}
            />
          ) : (
            <ApproveModal
              setShow={setShow}
              show={show}
              handlePostReport={handlePostReport}
              isLoading={mutation.isLoading}
            />
          )}
        </div>
      )}

      <section>
        <div>
          <IconWrapper
            icon="arrow-left"
            w={48}
            h={48}
            onClick={() => navigate(-1)}
          />
          <div>
            <p
              className={cl({
                red_circle: doc?.doc_info.status === "Pending",
              })}
            >
              Отчёт за {msToNormTime(doc_info?.date_created, 6).toLowerCase()}
            </p>
          </div>
        </div>
        <p className="date__report">
          От {msToNormTime(doc_info?.date_created, 2)}
        </p>
      </section>

      <CardTwo reject>
        {isLoading && <Loader />}
        <CardReject
          title={getTitle()}
          type={doc?.doc_info?.type}
          status={doc?.doc_info.status}
          comment={doc?.doc_info.comment}
          note={doc?.doc_info.note}
        />
      </CardTwo>

      <CardTwo inforeject>
        {isLoading && <Loader />}

        <section className="cars">
          <main>
            <div className="report__header">
              <div>
                <h4>Автомобили</h4>
              </div>
              <div>
                <div>
                  <h4>Доход</h4>
                  <i
                    className={cl("icon ", {
                      "icon-chevron-selector": sort?.type !== "income",
                      "icon-chevron-up":
                        sort?.type === "income" && sort?.value === 0,
                      "icon-chevron-down":
                        sort?.type === "income" && sort?.value === 1,
                    })}
                    onClick={() => changeSort("income")}
                  />
                </div>
                <div>
                  <h4>Расход</h4>
                  <i
                    className={cl("icon", {
                      "icon-chevron-selector": sort?.type !== "expense",
                      "icon-chevron-up":
                        sort?.type === "expense" && sort?.value === 0,
                      "icon-chevron-down":
                        sort?.type === "expense" && sort?.value === 1,
                    })}
                    onClick={() => changeSort("expense")}
                  />
                </div>
                <div>
                  <h4>Комиссия</h4>
                  <i
                    className={cl("icon", {
                      "icon-chevron-selector": sort?.type !== "comission",
                      "icon-chevron-up":
                        sort?.type === "comission" && sort?.value === 0,
                      "icon-chevron-down":
                        sort?.type === "comission" && sort?.value === 1,
                    })}
                    onClick={() => changeSort("comission")}
                  />
                </div>
                <div>
                  <h4>К выплате</h4>
                  <i
                    className={cl("icon", {
                      "icon-chevron-selector": sort?.type !== "pay",
                      "icon-chevron-up":
                        sort?.type === "pay" && sort?.value === 0,
                      "icon-chevron-down":
                        sort?.type === "pay" && sort?.value === 1,
                    })}
                    onClick={() => changeSort("pay")}
                  />
                </div>
              </div>
            </div>

            <section>
              {listForMap.map((i) => {
                return (
                  <div
                    className={
                      operationIds.includes(i.car_id)
                        ? "item active__border"
                        : "item"
                    }
                    key={i.car_id}
                  >
                    <ItemCar
                      onClick={() => toggleDropDown(i.car_id)}
                      autoName={i.brand_model}
                      inc={i.income}
                      cons={i.expense}
                      comm={i.comission}
                      topay={i.pay}
                      carNumber={i.gos_num}
                    />
                    <Collapse
                      isOpened={operationIds.includes(i.car_id) ? true : false}
                    >
                      <section>
                        {i.operation_list.map((j, index) => {
                          return (
                            <div className="wrapp" key={index}>
                              <ItemCarMoreInfo
                                key={index}
                                onClick={() =>
                                  toggleDetail(i.car_id + "-" + index)
                                }
                                icon={"icon-" + j.operation_type}
                                title={j.operation_name}
                                date={j.operation_date}
                                total={j.operation_sum}
                                success={j.operation_sum >= 0}
                                details={j.operation_details}
                                isOpened={detailIds.includes(
                                  i.car_id + "-" + index
                                )}
                              />
                              <Collapse
                                isOpened={detailIds.includes(
                                  i.car_id + "-" + index
                                )}
                              >
                                {j.operation_details.map((k, kdx) => (
                                  <OperationCard
                                    key={kdx}
                                    price={k.detail_sum}
                                    title={k.detail_name}
                                  />
                                ))}
                              </Collapse>
                            </div>
                          );
                        })}
                      </section>
                    </Collapse>
                  </div>
                );
              })}
            </section>

            <div className="results">
              <div className="results__left">
                {width < 768 ? "Итог месяца" : "Итоги"}
              </div>
              <div className="results__right">
                <ResultItem title="Доход" price={totalEconomy.income} />
                <ResultItem title="Расход" price={totalEconomy.expense} />
                <ResultItem title="Комиссия" price={totalEconomy.comission} />
                <ResultItem title="К выплате" price={totalEconomy.pay} />
              </div>
            </div>

            {doc?.doc_info.status === "Pending" && (
              <div className="btn__answer">
                <div className="dismiss" onClick={openModalFuncDissmiss}>
                  <Button color="danger" p={[16, 30]}>
                    Отклонить
                  </Button>
                </div>
                <div className="approve" onClick={openModalFuncApprove}>
                  <Button color="primary" p={[16, 30]}>
                    Согласовать
                  </Button>
                </div>
              </div>
            )}
          </main>
        </section>
      </CardTwo>
    </StyledOneDoc>
  );
}

function ResultItem({ title, price }) {
  return (
    <StyleResultItem>
      <h3>{title}</h3>
      <h4>
        <NumberFormat value={price} />
      </h4>
    </StyleResultItem>
  );
}

const StyleResultItem = styled.div`
  opacity: 0.86;
  text-align: center;
  padding: 16px 55px;
  border: 1px solid var(--primary-dark-15);
  border-radius: 14px;
  cursor: pointer;

  & > h3 {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--dark);
  }

  & > h4 {
    white-space: nowrap;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: 0em;
  }

  @media (max-width: 768px) {
    width: initial;
    text-align: start;
    padding: 14px 16px;
  }
`;

const StyledOneDoc = styled.div`
  .modal__window {
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    opacity: 0;
    visibility: hidden;
    transition: 0.1s ease-in;
    background-color: var(--dark-30);
    z-index: 22;
    &.active__modal {
      opacity: 1;
      visibility: visible;
    }
  }

  & > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-bottom: 30px;
    padding: 0 15px 0 15px;

    & > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      & > div {
        & > p {
          font-size: 26px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
          color: var(--dark);

          &.red_circle {
            position: relative;

            &::after {
              content: "";
              position: absolute;
              top: 50%;
              right: -20px;
              transform: translateY(-50%);
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: var(--danger);
            }
          }
        }
      }
    }
    .date__report {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }

  .cars {
    padding: 38px 30px 30px 30px;
    margin: 20px 0 0 0;

    & > main {
      & > .report__header {
        display: -ms-grid;
        display: grid;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        grid-template-columns: 10% 73%;
        padding: 0 0 0 30px;
        margin-bottom: 30px;

        & > div {
          text-transform: uppercase;

          &:nth-child(1) {
            & > h4 {
              font-size: 14px;
              font-weight: 500;
              line-height: 17px;
              letter-spacing: 0em;
              color: var(--dark);
            }
          }
          &:nth-child(2) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;

            & > div {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              gap: 9px;
              padding: 0 54px;

              & > h4 {
                font-size: 14px;
                font-weight: 500;
                line-height: 17px;
                letter-spacing: 0em;
                color: var(--dark);
                white-space: nowrap;
              }
              & > i {
                width: 16px;
                height: 16px;
                background-color: var(--dark);
              }
            }
          }
        }
      }

      & > section {
        .item {
          overflow: hidden;
          border-radius: 20px;
          border: 3px solid transparent;
          transition: all 0.126s linear;
          margin-bottom: 10px;
          &.active__border {
            border-color: var(--primary);
          }

          & > .more__info {
            width: 100%;
            display: none;
            transition: 0.1s linear;
            &.show {
              display: block;
            }
            .wrapp {
              border-bottom: 1px solid var(--primary-15);
            }
          }
          &:nth-child(2n) {
            background-color: var(--dark-3);
            border-radius: 20px;
          }
        }
      }

      .results {
        display: -ms-grid;
        display: grid;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        grid-template-columns: 83px 1fr;
        gap: 232px;
        padding: 16px 20px 16px 30px;
        border: 1px solid var(--primary-dark-15);
        border-radius: 16px;
        margin-bottom: 60px;

        &__left {
          font-size: 26px;
          font-weight: 600;
          line-height: 32px;
          letter-spacing: 0em;
        }
        &__right {
          width: 100%;
          display: -ms-grid;
          display: grid;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          grid-template-columns: repeat(4, 201px);
          gap: 20px;
        }
      }

      .btn__answer {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        gap: 20px;
        margin-bottom: 30px;

        .dismiss {
          cursor: pointer;
        }

        .approve {
          cursor: pointer;
        }
      }
    }
  }
  @media (max-width: 768px) {
    & > section {
      display: none;
    }

    .cars {
      & > main {
        .report__header {
          display: none;
        }

        .results {
          grid-template-columns: 1fr;
          gap: 20px;
          padding: 30px 0 10px 30px;
          margin: 0;
          border: none;
          border-radius: 0;
          &__right {
            display: -ms-grid;
            display: grid;
            grid-template-columns: 1fr 1fr;
          }
        }

        .btn__answer {
          margin-top: 30px;
        }
      }
    }
  }
  @media (max-width: 576px) {
    .modal__window {
      padding: 0 15px;
    }

    .cars {
      overflow: hidden;
      padding: 30px 0 30px 0;
      & > main {
        .results {
          border-top: 1px solid var(--primary-dark-15);
          border-bottom: 1px solid var(--primary-dark-15);

          &__left {
            margin: 0 auto;
          }

          &__right {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 10px;
            transform: translateX();
            overflow-x: scroll;

            &::-webkit-scrollbar {
              width: 0;
            }
          }
        }
      }
    }
  }
`;
