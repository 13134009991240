/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import { useSearchParams } from "react-router-dom";

//
import Card from "../../../components/card/Card";
import Filter from "./sections/Filter";
import TableItem from "./sections/TableItem";
import IncomeExpense from "./sections/IncomeExpense";
import Download from "./sections/Download";
import Loader from "../../../components/loader/Loader";
import MobileCurCar from "./sections/MobileCurCar";
import EmptyText from "../../../components/epmty-text/EmptyText";
import { getMarkModelList } from "../../../util/formatters";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { useGetParkCars } from "../../../store/park/hook";

export default function CarParking() {
  // helper
  const { width } = useWindowDimensions();
  const [params, setParams] = useSearchParams({});

  // state
  const [filterList, setFilterList] = useState([]);
  const [selectedId, setSelectedId] = useState([]);
  const [status, setStatus] = useState("all");
  const [search, setSearch] = useState("");

  // query
  let { isLoading: car_loading, data: car } = useGetParkCars();
  car = car?.data?.data;

  // watcher
  useEffect(() => {
    setFilterList(getMarkModelList(car?.car_list));
  }, [car]);

  useEffect(() => {
    if (width < 769) {
      params.delete("download");
      params.delete("incexp");
      setParams(params);
      return;
    }
    if (width > 768) {
      params.delete("selected_car_id");
      params.delete("tab");
      setParams(params);
    }
  }, [width]);

  // method
  const list = useMemo(() => {
    let tempFilterList = [];
    filterList.forEach((i) => {
      i.models.forEach((j) => {
        if (j.isChecked) {
          tempFilterList.push(i.mark + " " + j.name);
        }
      });
    });
    if (!tempFilterList.length) {
      return [];
    }

    //
    let tempList = [...(car?.car_list || [])];
    tempList = tempList.filter((i) => {
      if (status !== "all" && search) {
        return (
          tempFilterList.includes(i.brand_model) &&
          i.brand_model.toLowerCase().includes(search.toLowerCase()) &&
          status === i.status
        );
      }
      if (status !== "all" && !search) {
        return tempFilterList.includes(i.brand_model) && status === i.status;
      }
      return (
        tempFilterList.includes(i.brand_model) &&
        i.brand_model.toLowerCase().includes(search.toLowerCase())
      );
    });
    return tempList;
  }, [car, filterList, search, status]);

  function toggleSelectedId(id) {
    if (selectedId.includes(id)) {
      setSelectedId((p) => p.filter((i) => i !== id));
    } else {
      setSelectedId((p) => [...p, id]);
    }
  }

  function addParams(name, value) {
    params.set(name, value);
    setParams(params);
  }

  function removeParam(param) {
    params.delete(param);
    setParams(params);
  }

  function getCarById(id) {
    return list.find((i) => i.car_id === +id);
  }

  // render
  const incexp = params.get("incexp");
  if (incexp && width > 768)
    return (
      <IncomeExpense
        car={getCarById(incexp)}
        removeParam={removeParam}
        params={params}
      />
    );

  const download = params.get("download");
  if (download && width > 768)
    return (
      <Download
        removeParam={removeParam}
        car={getCarById(download)}
        params={params}
      />
    );

  const selected_car_id = params.get("selected_car_id");
  if (selected_car_id && width < 769)
    return (
      <MobileCurCar
        car={getCarById(selected_car_id)}
        removeParam={removeParam}
        params={params}
        addParams={addParams}
        load={car_loading}
      />
    );

  return (
    <StyleCarParking loading={+car_loading}>
      <h1>Ваши автомобили</h1>
      <Card>
        <main className="park__content">
          <Filter
            count={car?.count || 0}
            filterList={filterList}
            setFilterList={setFilterList}
            search={search}
            setSearch={setSearch}
            status={status}
            setStatus={setStatus}
          />
          <ul className="car__table">
            {car_loading && <Loader />}
            {!list.length ? (
              <EmptyText />
            ) : (
              <>
                <li className="title">Ваши автомобили</li>
                {list.map((i) => (
                  <TableItem
                    key={i.car_id}
                    selectedId={selectedId}
                    toggleSelectedId={toggleSelectedId}
                    car={i}
                    addParams={addParams}
                  />
                ))}
              </>
            )}
          </ul>
        </main>
      </Card>
    </StyleCarParking>
  );
}

const StyleCarParking = styled.div`
  & > h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    color: var(--dark);
    padding-left: 36px;
    margin-bottom: 10px;
  }

  main.park__content {
    padding: 30px;

    .car__table {
      margin-top: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
              flex-direction: column;
      gap: 10px;
      position: relative;

      .title {
        display: none;
      }

      ${({ loading }) =>
        loading &&
        css`
          height: 250px;
          overflow: hidden;
        `}
    }
  }

  @media (max-width: 768px) {
    & > h1 {
      display: none;
    }

    main.park__content {
      padding: 0;

      .car__table {
        background-color: var(--light);
        box-shadow: 0px 0px 10px var(--primary-darj-5);
        border-radius: 30px;
        padding-bottom: 30px;

        .title {
          display: block;
          text-align: center;
          padding: 30px 30px 20px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }
  }
`;
