import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_RB_CARS, GET_RB_SUMMARY } from "./type";

export function useGetRBSummary() {
  return useQuery(GET_RB_SUMMARY, Api.getRBSummary);
}

export function useGetRBCars() {
  return useQuery(GET_RB_CARS, Api.getRBCarList);
}
