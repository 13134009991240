import React from "react";
import styled, { css } from "styled-components";

//
import { msToNormTime } from "../../../../util/date";

export default function InfoCard({ icon, color, title, text = 1640984400 }) {
  return (
    <StyledInfoCard color={color}>
      <section>
        <div className="icon__wrap">
          <i className={"icon " + icon}></i>
        </div>
      </section>
      <section>
        <h1>{title}</h1>
        <p>{msToNormTime(text, 1)}</p>
      </section>
    </StyledInfoCard>
  );
}

const StyledInfoCard = styled.div`
  background: var(--light);
  border: 1px solid var(--primary-dark-15);
  border-radius: 20px;
  padding: 20px;
  display: flex;
  align-items: center;
  gap: 20px;

  section {
    .icon__wrap {
      width: 60px;
      height: 60px;
      border-radius: 16px;
      background-color: var(--primary-10);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      i {
        background-color: var(--primary);

        &.icon-car {
          width: 30px;
          height: 18px;
        }
      }

      ${({ color }) =>
        color === "success"
          ? css`
              background-color: var(--success-20);

              i {
                background-color: var(--success);
              }
            `
          : null}
    }

    h1 {
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-bottom: 5px;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }
`;
