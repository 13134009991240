import React from "react";
import styled, { css } from "styled-components";

//
import warningBg from "../../asset/img/book-progress-bg.svg";

export default function ProgressBar({
  value = 0,
  vertical = false,
  bg = false,
  fill = 0,
  rent = false,
  booking = false,
  size,
  book,
}) {
  return (
    <StyledProgressBar
      value={value}
      vertical={vertical}
      bg={bg}
      fill={fill}
      rent={rent}
      booking={booking}
      size={size}
      book={book}
    >
      <div className="value"></div>
    </StyledProgressBar>
  );
}

const StyledProgressBar = styled.div`
  display: inline-block;
  width: 70px;
  height: 10px;
  background: var(--primary-dark-5);
  border-radius: 5px;
  position: relative;
  overflow: hidden;

  .value {
    width: ${({ value }) => value + "%"};
    background-color: var(--success);
    border-radius: 5px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    font-size: 10px;

    ${({ bg }) =>
      bg === "warning"
        ? css`
            /* background: url(${warningBg}) no-repeat;
            background-size: cover; */
            background-color: var(--warning);
          `
        : bg
        ? css`
            background: var(--${bg});
          `
        : null}
  }

  ${({ vertical }) =>
    vertical &&
    css`
      width: 10px;
      height: 30px;

      ${({ size }) =>
        size &&
        css`
          width: 15px;
          height: 52px;
          border-radius: 10px;

          @media (max-width: 768px) {
            width: 10px;
            height: 30px;
            border-radius: 5px;
          }
        `}

      .value {
        width: 100%;
        height: ${({ value }) => value + "%"};
        bottom: 0;
        top: auto;
      }
    `}

  ${({ fill }) =>
    fill &&
    css`
      width: 100%;
    `}

  ${({ rent }) =>
    rent &&
    css`
      background-color: var(--dark-10);
      height: 20px;
      border-radius: 8px;

      .value {
        border-radius: 10px;
      }
    `}

  ${({ book }) =>
    book &&
    css`
      background-color: var(--dark-10);
      height: 20px;
      border-radius: 8px;

      .value {
        border-radius: 10px;
        background: url(${warningBg}) no-repeat;
        background-size: cover;
      }
    `}
`;
