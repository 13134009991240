import React from "react";
import styled, { css } from "styled-components";

export default function Badge({
  title,
  bg = "success-15",
  p = [6, 16],
  color = "success",
  size,
  icon,
  radius = 16,
  minW,
  ...props
}) {
  p = p.map((i) => i + "px").join(" ");

  return (
    <StyledBadge
      bg={bg}
      p={p}
      color={color}
      size={size}
      radius={radius}
      minW={minW}
      {...props}
    >
      {icon && <i className={"icon " + icon}></i>}
      {title}
    </StyledBadge>
  );
}

const StyledBadge = styled.div`
  background: var(--${({ bg }) => bg});
  border-radius: ${({ radius }) => radius + "px"};
  padding: ${({ p }) => p};
  color: var(--${({ color }) => color});
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0em;
  text-transform: uppercase;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  gap: 5px;
  width: max-content;

  ${({ minW }) =>
    minW &&
    css`
      min-width: 120px;
    `}

  i {
    width: 16px;
    height: 16px;
    background-color: var(--${({ color }) => color});
  }

  ${({ size }) =>
    size === "sm" &&
    css`
      font-size: 12px;
      font-weight: 600;
      line-height: 15px;
      letter-spacing: 0em;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 5px;

      i {
        width: 14px;
        height: 14px;
      }
    `}
`;
