import React from "react";
import styled, { css } from "styled-components";

export default function Button({
  p = [12, 24],
  type = "button",
  color,
  radius = 16,
  textUpperCase,
  disabled,
  border,
  shadow,
  fW = 400,
  children,
  ...props
}) {
  p = p.map((i) => i + "px").join(" ");

  return (
    <StyledButton
      p={p}
      type={type}
      color={color}
      radius={radius}
      textUpperCase={textUpperCase}
      disabled={disabled}
      shadow={shadow}
      border={border}
      fW={fW}
      {...props}
    >
      {children}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  font-size: 16px;
  font-weight: ${({ fW }) => fW};
  line-height: 20px;
  letter-spacing: 0em;
  padding: ${({ p }) => p};
  background: var(--primary-dark-5);
  color: var(--dark);
  border-radius: ${({ radius }) => radius + "px"};
  cursor: pointer;
  border: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 10px;
  position: relative;
  transition: 0.2s linear;

  i {
    background-color: var(--dark);
  }

  ${({ textUpperCase }) =>
    textUpperCase &&
    css`
      text-transform: uppercase;
    `}

  ${({ shadow }) =>
    shadow &&
    css`
      box-shadow: 0px 0px 20px var(--secondary-dark-10);
    `}

  ${({ border }) =>
    border &&
    css`
      border: 1px solid var(--primary-dark-15);
    `}

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${({ color }) =>
    color === "primary"
      ? css`
          background-color: var(--primary);
          color: var(--light);
        `
      : color === "danger"
      ? css`
          background-color: var(--danger);
          color: var(--light);
        `
      : color === "light"
      ? css`
          background-color: var(--light);
        `
      : null}
`;
