import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { v4 } from "uuid";
import { useMutation, useQueryClient } from "react-query";

//
import NotifList from "./components/NotifList";
import Badge from "../../../components/badge/Badge";
import Tab from "../../../components/tab/Tab";
import NotifApi from "../../../store/notif/api";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";
import { useGetNotifications } from "../../../store/notif/hook";
import { useAppContext } from "../../../store/Context";
import { GET_NOTiFICATIONS } from "../../../store/notif/type";

const tabOptions = [
  {
    id: v4(),
    title: "Не прочитанныйе",
    value: 0,
  },
  {
    id: v4(),
    title: "Прочитанныйе",
    value: 1,
  },
];

export default function Notification() {
  // helper
  const { width } = useWindowDimensions();
  const {
    fn: { setNotifCount },
  } = useAppContext();

  // state
  const [tab, setTab] = useState(0);
  const [notif__cur, setNotif] = useState(null);
  const [staticReads, setStaticReads] = useState([]);

  useEffect(() => {
    return () => {
      queryClient.setQueryData(GET_NOTiFICATIONS, (notifs) => {
        if (!notifs) return;
        let tempArr = notifs.data.data;
        let lcList = JSON.stringify(localStorage.getItem("notif_list") || "[]");
        tempArr = tempArr.map((i) =>
          lcList.includes(i.notification_id) ? { ...i, read: true } : i
        );
        localStorage.removeItem('notif_list')

        return {
          ...notifs,
          data: {
            data: tempArr,
          },
        };
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // query
  const queryClient = useQueryClient();
  let { isLoading, data: notifs } = useGetNotifications();
  notifs = notifs?.data?.data;

  const mutation = useMutation(
    (id) => NotifApi.putNotif({ notification_id: id, read: true }),
    {
      onSuccess: onSuccessPutNotif,
    }
  );

  // method
  function onSuccessPutNotif(_, id) {
    setStaticReads((p) => [...p, id]);
    setNotifCount((p) => p - 1);
    localStorage.setItem("notif_list", [...staticReads, id]);
  }

  const list = useMemo(() => {
    let read = [];
    let unRead = [];

    notifs?.forEach((i) => {
      if (i.read) {
        read.push(i);
      } else {
        unRead.push(i);
      }
    });

    return {
      notif_count: unRead.length,
      read,
      unRead,
    };
  }, [notifs]);

  return (
    <StyledNotif>
      {width < 769 && (
        <header>
          <div className="head__title">
            <h1>Уведомления</h1>
            <Badge
              title={list.notif_count}
              radius={14}
              p={[2, 10]}
              bg="danger"
              color="light"
              size="sm"
            />
          </div>
          <Tab options={tabOptions} value={tab} onChange={(v) => setTab(v)} />
        </header>
      )}

      {width > 768 ? (
        <NotifList
          title="Уведомления"
          list={list.unRead}
          isLoading={isLoading}
          onPutNotifAsRead={mutation.mutateAsync}
          notif__cur={notif__cur}
          setNotif={setNotif}
          staticReads={staticReads}
        />
      ) : tab === 0 ? (
        <NotifList
          title="Уведомления"
          list={list.unRead}
          isLoading={isLoading}
          onPutNotifAsRead={mutation.mutateAsync}
          notif__cur={notif__cur}
          setNotif={setNotif}
          staticReads={staticReads}
        />
      ) : null}
      {width > 768 ? (
        <NotifList
          title="Прочитано"
          list={list.read}
          isLoading={isLoading}
          notif__cur={notif__cur}
          setNotif={setNotif}
        />
      ) : tab === 1 ? (
        <NotifList
          title="Прочитано"
          list={list.read}
          isLoading={isLoading}
          notif__cur={notif__cur}
          setNotif={setNotif}
        />
      ) : null}
    </StyledNotif>
  );
}

const StyledNotif = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 60px;

  @media (max-width: 768px) {
    gap: 0;

    header {
      background: var(--light);
      box-shadow: 0px 0px 10px var(--dark-5);
      border-radius: 0px 0px 30px 30px;
      margin-bottom: 20px;
      padding: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 30px;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;

      .head__title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;

        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }
  }
`;
