import React, { useState } from "react";
import styled, { css } from "styled-components";
import { v4 } from "uuid";

//
import FilterByCarModel from "../../../../components/filter-car/FilterByCarModel";
import Search from "../../../../components/search/Search";
import Select from "../../../../components/select/Select";
import Input from "../../../../components/input/Input";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

//
const selectOptions = [
  {
    id: v4(),
    title: "Все статусы",
    value: "all",
  },
  {
    id: v4(),
    title: "Свободны",
    value: "free",
  },
  {
    id: v4(),
    title: "Арендованы",
    value: "rented",
  },
  {
    id: v4(),
    title: "Забронированы",
    value: "booked",
  },
  {
    id: v4(),
    title: "На СТО",
    value: "off",
  },
  {
    id: v4(),
    title: "В перегоне",
    value: "peregon",
  },
];

export default function Filter({
  count,
  filterList,
  setFilterList,
  search,
  setSearch,
  status,
  setStatus,
}) {
  const { width } = useWindowDimensions();
  const [searchIsOpen, setSearchIsOpen] = useState(false);

  function changeSearchIsOpen(sio) {
    if (!sio) {
      setSearch("");
    }
    setSearchIsOpen(sio);
  }

  return (
    <StyledFilter searchIsOpen={searchIsOpen}>
      <section className="filter__left">
        <div className="title">
          <h1>{count}</h1>
          <p>
            Всего <br />
            автомобилей
          </p>
        </div>
        <div className="left__search__wrapper">
          <Search
            isOpen={searchIsOpen}
            changeSearchIsOpen={changeSearchIsOpen}
          />
        </div>
      </section>
      <section className="filter__right">
        {!searchIsOpen ? (
          <>
            <FilterByCarModel
              marks={filterList}
              setMarks={setFilterList}
              park={1}
            />
            <Select
              value={status}
              onChange={(v) => setStatus(v)}
              options={selectOptions}
            />
          </>
        ) : (
          <>
            <Input
              type="text"
              placeholder="Search"
              fill={width > 768 ? 0 : 1}
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </>
        )}
        <div className="right__search__wrapper">
          <Search
            isOpen={searchIsOpen}
            changeSearchIsOpen={changeSearchIsOpen}
          />
        </div>
      </section>
    </StyledFilter>
  );
}

const StyledFilter = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-left: 20px;
  gap: 20px;

  .filter__left {
    .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;

      h1 {
        font-size: 38px;
        font-weight: 600;
        line-height: 46px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    .left__search__wrapper {
      display: none;
    }
  }

  .filter__right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
  }

  @media (max-width: 768px) {
    background-color: var(--light);
    padding: 30px;
    flex-direction: column;
    align-items: flex-start;
    box-shadow: 0px 0px 10px var(--primary-dark-5);
    border-radius: 0px 0px 30px 30px;

    .filter__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      width: 100%;

      .left__search__wrapper {
        display: block;
      }
    }

    .filter__right {
      width: 100%;
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr;

      .right__search__wrapper {
        display: none;
      }

      ${({ searchIsOpen }) =>
        searchIsOpen &&
        css`
          grid-template-columns: 1fr;
        `}
    }
  }

  @media (max-width: 475px) {
    .filter__right {
      grid-template-columns: 1fr;
    }
  }
`;
