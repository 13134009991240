import { v4 } from "uuid";

export const months = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export const dash_cars_colors = [
  "c7",
  "c8",
  "c9",
  "c10",
  "c11",
  "c12",
  "c13",
  "c14",
  "c15",
  "c16",
  "c17",
  "c18",
  "c19",
  "c20",
  "c21",
  "c22",
  "c23",
  "c24",
  "c25",
  "c26",
  "c27",
  "c28",
  "c1",
  "c2",
  "c3",
  "c4",
  "c5",
  "c6",
  "info",
  "aqua",
  "tropic",
  "derm",
  "pompelmo",
  "purple",
  "warning",
  "primary",
];

export const statusTitle = {
  free: "Свободна",
  rented: "В аренде",
  booked: "Бронь",
  off: "В сервисе",
  peregon: "В перегоне",
};

export const statusColor = {
  free: "primary",
  rented: "success-dark",
  booked: "warning-dark",
  off: "danger",
  peregon: "purple",
};

export const statusBg = {
  free: "primary-5",
  rented: "success-15",
  booked: "warning-20",
  off: "danger-10",
  peregon: "purple-15",
};

export const parkTabs = {
  doc: "Документы",
  osago: "ОСАГО",
  journal: "Журнал ТО",
  download: "Загрузка",
  incexp: "Доходы и расходы",
};

export const stateCarTitle = {
  free: "Сейчас <br/> свободно",
  rented: "Находится <br/> у арендаторов",
  booked: "В состоянии <br/> бронирования",
  off: "Находится <br/> в сервисе",
  peregon: "Находится <br/> в перегоне",
};

export const doughuntTabOptions = [
  {
    id: v4(),
    title: "Доход",
    value: "income",
  },
  {
    id: v4(),
    title: "Расход",
    value: "expense",
  },
  {
    id: v4(),
    title: "Выплаты",
    value: "pay",
  },
  {
    id: v4(),
    title: "Загрука",
    value: "load",
  },
];

export const doughuntTabTitle = {
  income: "Общий доход <br/> - комиссия",
  pay: "Всего к выплате",
  expense: "Всего расходов",
  load: "Средн. загрузка",
};
