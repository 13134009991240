import React from "react";
import styled from "styled-components";

//

export default function Search({ isOpen, changeSearchIsOpen }) {
  return (
    <StyledSearch onClick={() => changeSearchIsOpen(!isOpen)}>
      <i className={"icon icon-" + (isOpen ? "close" : "search")}></i>
    </StyledSearch>
  );
}

const StyledSearch = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  border: 1px solid var(--primary-dark-15);
  cursor: pointer;

  i {
    background-color: var(--dark);
  }

  @media (max-width: 768px) {
    background-color: var(--dark-5);
  }
`;
