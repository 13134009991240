import React, { useState } from "react";
import styled, { css } from "styled-components";
import ReactOutSideClickHandler from "react-outside-click-handler";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import Checkbox from "../checkbox/Checkbox";
import Button from "../button/Button";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export default function FilterByCarModel({ marks, setMarks, park }) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [curModel, setCurModel] = useState(null);
  const [open, setOpen] = useState(false);

  //  method
  function testCheckCount(list) {
    list = list.filter((i) => i.isChecked);
    return list.length;
  }

  function toggleCheckAllModels(id) {
    let mark = marks.find((i) => i.id === id);
    let res = testCheckCount(mark.models);
    let tempModels = mark.models.map((i) => ({
      ...i,
      isChecked: !(res === mark.models.length),
    }));
    mark.models = tempModels;
    setMarks((p) => p.map((i) => (i.id === id ? mark : i)));
  }

  function checkAllMarks(title) {
    let checkCount = marks.reduce((total, i) => {
      total += testCheckCount(i.models);
      return total;
    }, 0);
    let initialCount = marks.reduce((t, i) => {
      t += i.models.length;
      return t;
    }, 0);
    if (title) return checkCount;
    return initialCount === checkCount;
  }

  function allMarksCheck() {
    let ex = true;
    if (checkAllMarks()) {
      ex = false;
    }
    setMarks((p) =>
      p.map((i) => ({
        ...i,
        models: i.models.map((j) => ({ ...j, isChecked: ex })),
      }))
    );
  }

  function toggleCurModel(idx, id) {
    let temp = [...marks];
    temp[idx].models = temp[idx].models.map((i) =>
      i.id === id ? { ...i, isChecked: !i.isChecked } : i
    );
    setMarks([...temp]);
  }

  function toggleCheckModels(idx) {
    let tempModels = marks[idx];
    let checkCount = tempModels.models.filter((i) => i.isChecked).length;

    let ex = true;
    if (checkCount === tempModels.models.length) {
      ex = false;
    }

    tempModels.models = tempModels.models.map((i) => ({ ...i, isChecked: ex }));
    let tempMarks = [...marks];
    tempMarks[idx] = tempModels;
    setMarks(tempMarks);
  }

  function changeOpen(o) {
    setOpen(o);
  }

  return (
    <ReactOutSideClickHandler onOutsideClick={() => changeOpen(false)}>
      <StyledFilterByCarModel open={open} park={park}>
        <main onClick={() => changeOpen(true)}>
          <h6>
            {checkAllMarks(1) > 0
              ? "Выбрать авто"
              : "В фильтрах не выбран ни один автомобиль"}
          </h6>
          <i className="icon icon-chevron-selector"></i>
        </main>
        {open && (
          <div className="options__wrapper">
            {width < 769 ? (
              <MobileFilter
                marks={marks}
                onClose={() => changeOpen(false)}
                testCheckCount={testCheckCount}
                toggleCheckAllModels={toggleCheckAllModels}
                allMarksCheck={allMarksCheck}
                toggleCurModel={toggleCurModel}
              />
            ) : (
              <>
                <MainOptions
                  marks={marks}
                  setCurModel={setCurModel}
                  setMarks={setMarks}
                  curModel={curModel}
                  testCheckCount={testCheckCount}
                  toggleCheckAllModels={toggleCheckAllModels}
                  checkAllMarks={checkAllMarks}
                  allMarksCheck={allMarksCheck}
                  width={width}
                />
                {curModel !== null && (
                  <InnerOptions
                    curModel={marks[curModel]}
                    idx={curModel}
                    toggleCurModel={toggleCurModel}
                    toggleCheckAllModels={toggleCheckModels}
                    width={width}
                    setCurModel={setCurModel}
                  />
                )}
              </>
            )}
            <div className="white__romb"></div>
          </div>
        )}
      </StyledFilterByCarModel>
    </ReactOutSideClickHandler>
  );
}

function MainOptions({
  marks,
  setCurModel,
  curModel,
  testCheckCount,
  toggleCheckAllModels,
  checkAllMarks,
  allMarksCheck,
}) {
  return (
    <section className="main__options">
      <ul>
        <li
          className={cl("all__btn", {
            active: checkAllMarks(),
          })}
          onClick={allMarksCheck}
        >
          <i className={`icon icon-${checkAllMarks() ? "check" : "minus"}`}></i>
          <p>Все автомобили</p>
        </li>
        {marks.map((i, idx) => {
          let res = testCheckCount(i.models);
          return (
            <li
              className={cl("car__mark", {
                active: res > 0,
                checked: idx === curModel,
              })}
              key={i.id}
              onClick={() => setCurModel(idx)}
            >
              <div className="car__mark__left">
                <Checkbox
                  checked={res === i.models.length}
                  onClick={(e) => {
                    e.stopPropagation();
                    toggleCheckAllModels(i.id);
                  }}
                />
                <p>{i.mark}</p>
              </div>
              {res > 0 && <div className="count__badge">{res}</div>}
            </li>
          );
        })}
      </ul>
    </section>
  );
}

function InnerOptions({
  curModel,
  idx,
  toggleCurModel,
  toggleCheckAllModels,
  width,
  setCurModel,
}) {
  function checkAllModels() {
    let tempM = curModel?.models || [];
    tempM = tempM.filter((i) => i.isChecked);

    return tempM.length;
  }

  return (
    <section className="inner__options">
      {width < 769 && (
        <header>
          <i
            className="icon icon-arrow-right"
            onClick={() => setCurModel(null)}
          ></i>
          <h1>{curModel.mark}</h1>
        </header>
      )}
      <ul>
        <li
          className={cl("all__btn", {
            active: checkAllModels() === curModel?.models.length,
          })}
          onClick={() => toggleCheckAllModels(idx)}
        >
          <i className={`icon icon-check`}></i>
          <p>Все модели</p>
        </li>
        {curModel?.models?.map((i) => {
          return (
            <li
              className={cl("car__mark", {
                checked: i.isChecked,
              })}
              key={i.id}
              onClick={() => toggleCurModel(idx, i.id)}
            >
              <div className="car__mark__left">
                <Checkbox checked={i.isChecked} />
                <p>{i.name}</p>
              </div>
            </li>
          );
        })}
      </ul>
    </section>
  );
}

function MobileFilter({
  marks,
  onClose,
  testCheckCount,
  toggleCheckAllModels,
  allMarksCheck,
  toggleCurModel,
}) {
  // state
  const [curModel, setCurModel] = useState(null);

  return (
    <StyledMobileFilter>
      <div className="filter__nav">
        <p></p>
        <h1>Выбор авто</h1>
        <i className="icon icon-sm icon-close" onClick={onClose}></i>
      </div>
      <ul>
        {marks.map((i, idx) => (
          <li
            key={i.id}
            className={cl({
              isOpened: curModel === i.id,
            })}
          >
            <div
              className={cl("list__nav")}
              onClick={() => setCurModel((p) => (p === i.id ? null : i.id))}
            >
              <p>{i.mark}</p>
              <i className="icon icon-sm icon-chevron-down"></i>
            </div>
            <Collapse isOpened={curModel === i.id}>
              <ul className="list__innerList">
                <li
                  className={cl("all__btn", {
                    active: testCheckCount(i.models) === i.models.length,
                  })}
                  onClick={() => toggleCheckAllModels(i.id)}
                >
                  <i className="icon icon-check"></i>
                  <p>Все автомобили</p>
                </li>
                {i.models.map((j) => (
                  <li
                    key={j.id}
                    className={cl("model", {
                      active: j.isChecked,
                    })}
                    onClick={() => toggleCurModel(idx, j.id)}
                  >
                    <div className="icon__check__wrap">
                      <i className="icon icon-sm icon-check"></i>
                    </div>
                    <p>{j.name}</p>
                  </li>
                ))}
              </ul>
            </Collapse>
          </li>
        ))}
      </ul>
      <div className="reset__btn__wrapper">
        <Button
          onClick={allMarksCheck}
          p={[14, 16, 14, 26]}
          color="light"
          shadow={1}
          border={1}
          fW={500}
        >
          Сбросить <i className="icon icon-sm icon-refresh"></i>
        </Button>
      </div>
    </StyledMobileFilter>
  );
}

const StyledMobileFilter = styled.div`
  .filter__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px 15px;

    h1 {
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    i {
      background-color: var(--dark);
    }
  }

  & > ul {
    & > li {
      border: 3px solid transparent;
      border-radius: 14px;
      padding: 10px;
      padding-top: 0;

      .list__nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 16px 14px;

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        i {
          background-color: var(--dark);
          opacity: 0.5;
        }
      }

      &.isOpened {
        border-color: var(--primary);

        .list__nav {
          i {
            opacity: 1;
            transform: rotate(-90deg);
          }
        }
      }

      .list__innerList {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;

        li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 15px;
          padding: 14px;
          border-radius: 16px;
          transition: 0.2s linear;
          cursor: pointer;

          &.model {
            padding: 11px 20px 11px 11px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          i {
            background-color: var(--dark);
          }

          .icon__check__wrap {
            width: 30px;
            height: 30px;
            border-radius: 10px;
            background-color: var(--dark-5);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            transition: 0.2s linear;

            i {
              background-color: var(--primary-dark-15);
            }
          }

          &:hover {
            background-color: var(--dark-3);
          }

          &.active {
            background-color: var(--primary-10);

            p {
              color: var(--primary);
            }

            i {
              background-color: var(--primary);
            }

            .icon__check__wrap {
              background-color: var(--primary);

              i {
                background-color: var(--light);
              }
            }

            &:hover {
              background-color: var(--primary-20);
            }
          }
        }
      }
    }
  }

  .reset__btn__wrapper {
    position: fixed;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
  }
`;

const StyledFilterByCarModel = styled.div`
  position: relative;

  & > main {
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding: 14px 14px 14px 20px;
    cursor: pointer;
    transition: 0.2s linear;
    z-index: 2;
    min-width: 200px;

    & > h6 {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    & > i {
      background-color: var(--dark);
      opacity: 0.7;
      min-width: 24px;
    }

    &:hover {
      background: var(--dark-5);
      border-color: transparent;
    }

    ${({ open }) =>
      open &&
      css`
        border-color: var(--primary-dark-30);
      `}
  }

  .options__wrapper {
    position: absolute;
    bottom: -10px;
    left: 0;
    transform: translateY(100%);
    background-color: var(--light);
    border: 1px solid var(--primary-dark-15);
    box-shadow: 0px 0px 20px var(--dark-5);
    border-radius: 20px;
    padding: 20px;
    cursor: default;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    z-index: 3;

    &::-webkit-scrollbar {
      display: none;
    }

    .white__romb {
      position: absolute;
      top: -5px;
      left: 150px;
      width: 10px;
      height: 10px;
      background: var(--light);
      border-width: 1px 1px 0px 0px;
      border-style: solid;
      border-color: var(--primary-dark-15);
      transform: rotate(-45deg);
    }

    .main__options,
    .inner__options {
      padding-right: 3px;
      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
        max-height: 300px;
        overflow-y: auto;
        padding-right: 12px;
        overflow-x: hidden;

        &::-webkit-scrollbar {
          width: 5px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          background: var(--dark-10);
          border-radius: 5px;
        }
      }

      .all__btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 15px;
        border-radius: 16px;
        padding: 14px 20px 14px 14px;
        transition: 0.2s linear;
        cursor: pointer;

        p {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        i {
          background-color: var(--dark);
        }

        &:hover {
          background-color: var(--dark-3);
        }

        &.active {
          background-color: var(--primary-10);

          p {
            color: var(--primary);
          }

          i {
            background-color: var(--primary);
          }

          &:hover {
            background-color: var(--primary-20);
          }
        }
      }

      .car__mark {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 10px;
        min-width: 285px;
        padding: 11px 20px 11px 11px;
        cursor: pointer;
        border-radius: 16px;
        transition: 0.2s linear;

        &__left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 15px;

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            transition: 0.2s linear;
            color: var(--dark);
            text-transform: capitalize;
          }
        }

        .count__badge {
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          transition: 0.2s linear;
          color: var(--dark);
          padding: 4px 6px;
        }

        &:hover {
          background-color: var(--dark-3);
        }

        &.active {
          background: var(--primary-10);

          .car__mark__left {
            p {
              color: var(--primary);
            }
          }

          .count__badge {
            background-color: var(--light);
            color: var(--primary);
          }
        }

        &.checked {
          background-color: var(--primary-20);
        }
      }
    }

    .main__options {
      border-right: 1px solid var(--primary-dark-15);
    }

    ${({ park }) =>
      park &&
      css`
        left: -93px;

        .white__romb {
          left: 185px;
        }
      `}
  }

  @media (max-width: 768px) {
    & > main {
      min-width: auto;
      padding: 12px 14px 12px 24px;
      background-color: var(--dark-5);
    }

    .options__wrapper {
      position: fixed;
      bottom: auto;
      top: 0;
      transform: translateY(0%);
      left: 0;
      border-radius: 0;
      width: 100%;
      height: 100vh;
      overflow-y: auto;
      z-index: 4;
      flex-direction: column;

      .white__romb {
        display: none;
      }

      .main__options,
      .inner__options {
        border: none;
        padding-right: 0px;

        header {
          margin-bottom: 30px;
          position: relative;

          h1 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--dark);
            text-align: center;
          }

          i {
            background-color: var(--dark);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%) rotate(180deg);
          }
        }

        ul {
          max-height: unset;
          padding-right: 0px;

          &::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
    }
  }
`;
