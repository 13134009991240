import { BrowserRouter } from "react-router-dom";
import { YMaps } from "react-yandex-maps";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

//
import Router from "./router/Router";
import Context from "./store/Context";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import { GlobalStyles } from "./asset/style/global";

let cacheTime = 24 * 60 * 60 * 1000;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime,
      refetchOnWindowFocus: false,
    },
  },
});

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <GlobalStyles />
      <YMaps>
        <QueryClientProvider client={queryClient}>
          <Context>
            <Router />
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </Context>
        </QueryClientProvider>
      </YMaps>
    </BrowserRouter>
  );
}

export default App;
