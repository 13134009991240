import React from "react";
import styled from "styled-components";
import cl from "classnames";

import PayCard from "../../pay-card/PayCard";
import NumberFormat from "../../number-format/NumberFormat";
import { msToNormTime } from "../../../util/date";

export default function CarPaymentModal({ changeOperation, car }) {
  return (
    <StyledCarPaymentModal>
      <section className="top__sect">
        <PayCard
          icon="icon-plus-circle"
          text="Доход"
          title={car?.income || 0}
          mobileSize="sm"
        />
        <PayCard
          icon="icon-minus-circle"
          text="Расход"
          title={car?.expense || 0}
          color="danger"
          mobileSize="sm"
        />
      </section>
      <section className="bottom__sect">
        <h1>Операции</h1>
        <ul>
          {car?.operation_list?.map((i, idx) => (
            <li key={idx} onClick={() => changeOperation(i)}>
              <section>
                <div
                  className={cl("i__wrap", {
                    danger: i.operation_sum < 0,
                    success: i.operation_sum >= 0,
                  })}
                >
                  <i className={"icon icon-" + i.operation_type}></i>
                </div>
                <div>
                  <h1>{i.operation_name}</h1>
                  {i.operation_type !== "bar-line-chart" && (
                    <p>От {msToNormTime(i.operation_date, 2)}</p>
                  )}
                </div>
              </section>
              <section>
                <p>
                  <NumberFormat value={i.operation_sum} />
                </p>
                <i className="icon icon-chevron-right"></i>
              </section>
            </li>
          ))}
        </ul>
      </section>
    </StyledCarPaymentModal>
  );
}

const StyledCarPaymentModal = styled.div`
  padding: 30px 0;

  .top__sect {
    padding: 0 60px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 60px;
  }

  .bottom__sect {
    padding: 0 30px;

    & > h1 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
      margin-bottom: 30px;
      padding-left: 30px;
    }

    & > ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 10px;

      li {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 20px;
        cursor: pointer;
        border-radius: 20px;
        transition: 0.2s linear;

        &:hover {
          background-color: var(--dark-3);
        }

        section {
          &:nth-child(1) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 20px;

            .i__wrap {
              width: 52px;
              height: 52px;
              border-radius: 50%;
              background-color: var(--success-20);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;

              i {
                background-color: var(--success-dark);
              }

              &.danger {
                background-color: var(--danger-10);

                i {
                  background-color: var(--danger-dark);
                }
              }
            }

            h1 {
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark);
              margin-bottom: 5px;
            }

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
          }

          &:nth-child(2) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 30px;

            p {
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark);
            }

            i {
              background-color: var(--dark);
              opacity: 0.5;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .top__sect {
      padding: 0 20px;
      gap: 10px;
      flex-wrap: wrap;
    }

    .bottom__sect {
      padding: 0;

      & > h1 {
        font-size: 16px;
        line-height: 20px;
        padding-left: 0;
        text-align: center;
      }

      & > ul {
        li {
          padding: 20px 30px;
          border-radius: 0;

          section {
            &:nth-child(1) {
              .i__wrap {
                width: 40px;
                height: 40px;

                i {
                  width: 20px;
                  height: 20px;
                }
              }

              h1 {
                font-size: 16px;
                line-height: 20px;
              }

              p {
                font-size: 12px;
                line-height: 14px;
              }
            }

            &:nth-child(2) {
              i {
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;
