import React from "react";
import styled from "styled-components";
import cl from "classnames";

//
import NumberFormat from "../../../../components/number-format/NumberFormat";
import { msToNormTime } from "../../../../util/date";

//
export default function ItemCarMoreInfo({
  icon,
  title,
  date,
  total,
  success,
  details,
  isOpened,
  ...props
}) {
  // const [open, setOpen] = useState(false);
  return (
    <StyleItemCarMoreInfo {...props}>
      <section>
        <div
          className={cl("icon__box", {
            success,
          })}
        >
          <i className={"icon " + icon} />
        </div>
        <p className="title">{title}</p>
      </section>
      <section>
        <div className="text">
          <h6>От {msToNormTime(date, 2)}</h6>
          <h3>
            <NumberFormat value={total} />
          </h3>
        </div>
        <div className="icon__drop">
          <i
            className="icon icon-chevron-right"
            style={{ transform: isOpened ? "rotate(-90deg)" : "rotate(90deg)" }}
          />
        </div>
      </section>
    </StyleItemCarMoreInfo>
  );
}

const StyleItemCarMoreInfo = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 17px 30px;
  cursor: pointer;

  & > section {
    &:nth-child(1) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      & > .icon__box {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 14px 14px;
        border-radius: 50%;
        background-color: var(--danger-10);

        & > i {
          background-color: var(--danger-dark);
        }

        &.success {
          background-color: var(--success-20);

          & > i {
            background-color: var(--success-dark);
          }
        }
      }
      & > .title {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
      }
    }

    &:nth-child(2) {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      .text {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 90px;

        & > h6 {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
        & > h3 {
          font-size: 18px;
          font-weight: 600;
          line-height: 22px;
          letter-spacing: 0em;
        }
      }
      & > .icon__drop {
        & > i {
          background-color: var(--dark-50);
        }
      }
    }
  }
  @media (max-width: 768px) {
    & > section {
      align-items: center;
      &:nth-child(1) {
        flex-direction: column;
        align-items: flex-start;
        gap: 10px;
      }
      &:nth-child(2) {
        display: flex;
        display: -webkit-box;
        display: -ms-flexbox;

        .text {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
          gap: 10px;
        }
      }
    }
  }
`;
