import React from "react";
import styled, { css } from "styled-components";

export default function Input({ icon, fill, error, ...props }) {
  return (
    <StyledInput fill={fill} error={error}>
      <input type="text" {...props}  />
      {error && <p className="error-text">{error}</p>}
      {icon && <i className={"icon icon-sm icon-" + icon}></i>}
    </StyledInput>
  );
}

const StyledInput = styled.div`
  position: relative;

  & > input {
    min-width: 300px;
    background: var(--light);
    border: 1px solid var(--primary-dark-15);
    border-radius: 14px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    color: var(--dark);
    padding: 16px 45px 16px 20px;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--dark-40);
      font-weight: 400;
    }
  }

  i {
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -50%);
    background-color: var(--primary-dark-15);
  }

  .error-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 15px;
    letter-spacing: 0em;
    color: var(--danger);
    margin-top: 5px;
    padding-left: 10px;
  }

  ${({ fill }) =>
    fill &&
    css`
      & > input {
        width: 100%;
      }
    `}

  ${({ error }) =>
    error &&
    css`
      & > input {
        border-color: var(--danger);
      }
    `}
`;
