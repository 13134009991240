import React from "react";
import styled from "styled-components";

//
import CarNumber from "../../../../components/car-number/CarNumber";
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";

export default function BackTitleNum({ onBack, gos, model }) {
  return (
    <StyledBackTitleNum>
      <IconWrapper
        icon="arrow-narrow-left"
        w={48}
        h={48}
        bg="light"
        color="dark"
        onClick={onBack}
      />
      <h1>{model || "Car model"}</h1>
      <CarNumber title={gos} size='lg' />
    </StyledBackTitleNum>
  );
}

const StyledBackTitleNum = styled.header`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;

  h1 {
    font-size: 26px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: 0em;
    color: var(--dark);
  }

  @media (max-width: 768px) {
    display: none;
  }
`;
