import React from "react";
import styled from "styled-components";
//

export default function HeaderModal({ marginBot, setShow, ...props }) {
  function closeModalFun() {
    setShow(false);
  }
  return (
    <StyleHeaderModal {...props} marginBot={marginBot}>
      <h2>Подтвердите свое действие</h2>
      <div onClick={closeModalFun}>
        <i className="icon icon-close icon-lg" />
      </div>
    </StyleHeaderModal>
  );
}

const StyleHeaderModal = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: ${({ marginBot }) => marginBot};

  & > h2 {
    font-size: 22px;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0em;
    color: var(--dark);
  }
  & > div {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    & > i {
      background-color: var(--dark);
    }
  }
  @media (max-width: 768px) {
    & > h2 {
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      letter-spacing: 0em;
    }
    & > div {
      & > i {
        width: 24px;
      }
    }
  }
`;
