import React from "react";
import styled, { css } from "styled-components";

export default function StateCarCard({ icon, color, title }) {
  return (
    <StyledStateCarCard color={color}>
      <section>
        <div className="icon__wrapper">
          <i className={"icon " + icon}></i>
        </div>
      </section>
      <section>
        <h1>{title}</h1>
      </section>
    </StyledStateCarCard>
  );
}

const StyledStateCarCard = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  background-color: var(--primary-15);
  padding: 30px;
  border-radius: 30px;

  section {
    .icon__wrapper {
      width: 60px;
      height: 60px;
      border-radius: 50%;
      background-color: var(--light);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;

      i {
        background-color: var(--primary);
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--primary);
    }
  }

  ${({ color }) =>
    color === "danger"
      ? css`
          background-color: var(--danger-10);

          section {
            h1 {
              color: var(--danger-dark);
            }
            .icon__wrapper {
              i {
                background-color: var(--danger-dark);
              }
            }
          }
        `
      : null}

  @media (max-width: 768px) {
    gap: 20px;
    padding: 15px;
    border-radius: 20px;

    section {
      .icon__wrapper {
        width: 40px;
        height: 40px;
      }

      h1 {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }
`;
