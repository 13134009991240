import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

//
import CarNumber from "../car-number/CarNumber";
import { useWindowDimensions } from "../../hooks/useWindowDimensions";

export default function Modal({
  title = "Title",
  isOpen = false,
  isLoading = false,
  isEmpty = false,
  onClose,
  children,
  topSm,
  topCurCar,
  changeCurCar,
  carNum,
}) {
  const { width } = useWindowDimensions();

  useEffect(() => {
    // if (isOpen) {
    //   document.body.classList.add("kill-scroll");
    // } else {
    //   document.body.classList.remove("kill-scroll");
    // }
  }, [isOpen]);

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <StyledModal isOpen={isOpen} topSm={topSm} topCurCar={topCurCar}>
        <section className="modal__top">
          {width < 769 && topSm && !topCurCar ? (
            <div className="mobile__close__icon__wrap" onClick={onClose}>
              <i className="icon icon-arrow-right"></i>
            </div>
          ) : null}

          {topCurCar && (
            <i
              className="icon icon-lg icon-arrow-right"
              onClick={() => changeCurCar(null)}
            ></i>
          )}

          <h1>
            {title} {carNum && <CarNumber title={carNum} size='lg' />}
          </h1>
          {(width < 769 && topSm) || topCurCar ? null : (
            <i className="icon icon-lg icon-close" onClick={onClose}></i>
          )}
        </section>
        {isLoading || isEmpty ? (
          <Loading>
            {isLoading && <div className="loading__indicator"></div>}
            {!isLoading && isEmpty && (
              <p className="empty__text">Список пуст</p>
            )}
          </Loading>
        ) : (
          <section>{children}</section>
        )}
      </StyledModal>
    </OutsideClickHandler>
  );
}

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 66%;
  background: var(--light);
  box-shadow: 0px 0px 20px var(--primary-dark-40);
  border-radius: 40px 0px 0px 40px;
  z-index: 5;
  opacity: 0.5;
  transform: translateX(100%);
  visibility: hidden;
  transition: 0.2s linear;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 750px;
  max-width: 850px;

  &::-webkit-scrollbar {
    display: none;
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      transform: translateX(0%);
      visibility: visible;
    `}

  .modal__top {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    background-color: var(--light);
    transform: translateY(0%);
    padding: 50px 40px 30px 60px;

    h1 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--dark);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
    }

    i {
      background-color: var(--dark);
    }

    ${({ topCurCar }) =>
      topCurCar &&
      css`
        justify-content: flex-start;
        gap: 30px;
        padding: 50px 40px 30px;

        i.icon-arrow-right {
          transform: rotate(180deg);
        }
      `}
  }

  @media (max-width: 768px) {
    width: 100%;
    border-radius: 0;
    min-width: auto;

    .modal__top {
      padding: 30px;

      h1 {
        font-size: 20px;
        line-height: 24px;
        flex-wrap: wrap;
        row-gap: 5px;
      }
    }

    ${({ topSm }) =>
      topSm &&
      css`
        background-color: var(--bg);

        .modal__top {
          background-color: var(--primary);
          box-shadow: 0px 0px 20px var(--dark-15);
          padding: 10px;
          justify-content: flex-start;
          gap: 30px;

          h1 {
            color: var(--light);
          }

          .mobile__close__icon__wrap {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid var(--light-10);
            cursor: pointer;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            i {
              background-color: var(--light);
              transform: rotate(180deg);
            }
          }
        }
      `}

    ${({ topCurCar }) =>
      topCurCar &&
      css`
        .modal__top {
          background-color: var(--light);
          box-shadow: none;
          padding: 30px 30px 0 30px;

          h1 {
            color: var(--dark);
            text-align: center;
          }

          i.icon-arrow-right {
            width: 24px;
            height: 24px;
          }
        }
      `}
  }
`;

const loadingAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const Loading = styled.div`
  background-color: var(--light);
  height: calc(100vh - 152px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  .loading__indicator {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 3px solid var(--primary);
    border-top-color: transparent;
    animation-name: ${loadingAnimation};
    animation-duration: 0.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }

  @media (max-width: 768px) {
    height: calc(100vh - 68px);
  }
`;
