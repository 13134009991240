import React from "react";
import styled from "styled-components";

//
import MobTop from "../../../../components/mobile-top-back/MobTop";
import MobileCarCard from "../components/MobileCarCard";
import MobileTabList from "../components/MobileTabList";
import ModelGos from "../components/ModelGos";
import Document from "../components/Document";
import Osago from "../components/Osago";
import JournalTo from "../components/JournalTo";
import Download from "./Download";
import IncomeExpense from "./IncomeExpense";
import { parkTabs } from "../../../../util/lists";

export default function MobileCurCar({
  car,
  removeParam,
  addParams,
  params,
  load,
}) {
  const tab = params.get("tab");

  function back() {
    removeParam("tab");
    if (!tab) {
      removeParam("selected_car_id");
    }
  }

  function getComponentWithTab() {
    let obj = {
      doc: (
        <Document
          sts={car?.car_docs?.sts}
          pts={car?.car_docs?.pts}
          load={load}
        />
      ),
      osago: <Osago osago={car?.car_docs?.osago} load={load} car={car} />,
      journal: <JournalTo to={car?.maintenance_log} load={load} />,
      download: <Download params={params} car={car} />,
      incexp: <IncomeExpense car={car} params={params} />,
    };

    return obj[tab];
  }

  return (
    <StyledMobileCurCar>
      <MobTop title={tab ? parkTabs[tab] : car?.brand_model} onBack={back} />
      {tab ? (
        <>
          <ModelGos model={car?.brand_model} gos={car?.gos_num} />
          {getComponentWithTab()}
        </>
      ) : (
        <>
          <MobileCarCard car={car} load={load} />
          <MobileTabList addParams={addParams} car_id={car?.car_id} />
        </>
      )}
    </StyledMobileCurCar>
  );
}

const StyledMobileCurCar = styled.div``;
