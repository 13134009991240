import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//
import Button from "../../../../components/button/Button";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";
import Consts from "../../../../util/const";
import { msToNormTime } from "../../../../util/date";

export default function DocItem({ doc, onOpenPdfModal }) {
  // helper
  const navigate = useNavigate();

  // method
  function goToDoc() {
    localStorage.setItem(Consts.DOC_DATE, doc.date_created);
    navigate("/cabinet/doc/" + doc.doc_id);
  }

  function getIcon() {
    if (doc.status === "Declined") return "close";
    if (doc.type === "report") return "calendar";
    if (doc.type === "act") return "file";
    if (doc.type === "service") return "tools";
    if (doc.type === "contract") return "shield";
  }

  function getIconBg() {
    if (doc.status === "Declined") return "dark-5";
    return "success";
  }

  function getIconColor() {
    if (doc.status === "Declined") return "danger";
    return "light";
  }

  return (
    <StyledDocItem onClick={goToDoc}>
      <section>
        <IconWrapper
          icon={`${getIcon()} icon-lg`}
          w={60}
          h={60}
          bg={getIconBg()}
          color={getIconColor()}
          rounded={14}
          redCircle={doc.type === "report" && doc.status === "Pending"}
          approved={doc.status === "Approved"}
        />
        <div>
          <h1>{doc.name || "-"}</h1>
          <p>{doc.note || "-"}</p>
        </div>
        {doc.sum && (
          <p className="money__wrapper">
            <NumberFormat value={doc.sum} />
          </p>
        )}
      </section>
      <section>
        {doc?.status === "Approved" ? (
          <Button
            p={[14, 20]}
            onClick={(e) => {
              e.stopPropagation();
              onOpenPdfModal();
            }}
          >
            Отчёт агента
          </Button>
        ) : null}
        <p>От {msToNormTime(doc.date_created, 3)}</p>
      </section>
    </StyledDocItem>
  );
}

const StyledDocItem = styled.li`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 20px;
  border-radius: 20px;
  cursor: pointer;

  & > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 30px;

    &:nth-child(1) {
      & > div {
        &:nth-child(2) {
          h1 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark-70);
          }
        }
      }

      .money__wrapper {
        padding: 10px 14px;
        background: var(--dark-5);
        border-radius: 14px;
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        white-space: nowrap;
      }
    }
    &:nth-child(2) {
      & > p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }
  }
  @media (max-width: 768px) {
    display: -ms-grid;
    display: grid;
    grid-template-columns: 1fr;
    gap: 22px;
    padding: 23px 32px 20px 30px;
    & > section {
      &:nth-child(1) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;

        .money__wrapper {
          display: none;
        }
      }
      &:nth-child(2) {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        & > p {
          border: 1px solid var(--primary-dark-15);
          padding: 6px 10px;
          border-radius: 8px;
          font-size: 12px;
          font-weight: 500;
          line-height: 15px;
          letter-spacing: 0em;
        }
      }
    }
  }
  @media (max-width: 376px) {
    padding: 20px 15px;
    & > section {
      &:nth-child(1) {
        & > div {
          &:nth-child(2) {
            & > h1 {
              font-size: 14px;
              max-width: 305px;
            }
            & > p {
              font-size: 14px;
              max-width: 305px;
            }
          }
        }
      }
    }
  }
`;
