import React from "react";
import styled from "styled-components";

//
import FilterDate from "../components/FilterDate";
import IncomeCard from "../components/IncomeCard";

export default function Top({
  date,
  setDate,
  fromDate,
  toDate,
  sumDays,
  chart_loading,
  profitMonth,
  profitTotal,
  getNewChartData,
}) {
  return (
    <StyledTop>
      <FilterDate
        date={date}
        setDate={setDate}
        fromDate={fromDate}
        toDate={toDate}
        sumDays={sumDays}
        getNewChartData={getNewChartData}
        chart_loading={chart_loading}
      />
      <IncomeCard
        chart_loading={chart_loading}
        profitMonth={profitMonth}
        profitTotal={profitTotal}
      />
    </StyledTop>
  );
}

const StyledTop = styled.section`
  margin-bottom: 20px;
  display: -ms-grid;
  display: grid;
  grid-template-columns: auto 420px;
  gap: 20px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`;
