import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

//
import AuthLeft from "../../../components/auth-left/AuthLeft";
import Enter from "./components/Enter";
import ResetMain from "./components/ResetMain";
import ResetSmsCode from "./components/ResetSmsCode";
import ResetPassword from "./components/ResetPassword";

export default function Login() {
  const [searchParams, setSearchParams] = useSearchParams();

  function addSearchParam(name, value) {
    searchParams.set(name, value);
    setSearchParams(searchParams);
  }

  function removeSearchParam(name) {
    searchParams.delete(name);
    setSearchParams(searchParams);
  }

  function onClearSearchParam() {
    searchParams.set("success", true);
    removeSearchParam("reset");
    removeSearchParam("reset_phone");
    removeSearchParam("reset_code");
  }

  function getRightComponent() {
    const reset = searchParams.get("reset");
    const reset_phone = searchParams.get("reset_phone");
    const reset_code = searchParams.get("reset_code");
    if (reset && reset_phone && reset_code)
      return (
        <ResetPassword
          onBack={() => removeSearchParam("reset_code")}
          onClearSearchParam={onClearSearchParam}
          reset_code={reset_code}
          phone={reset_phone}
        />
      );
    if (reset && reset_phone)
      return (
        <ResetSmsCode
          phone={reset_phone}
          onBack={() => removeSearchParam("reset_phone")}
          onNext={addSearchParam}
        />
      );
    if (reset)
      return (
        <ResetMain
          onBack={() => removeSearchParam("reset")}
          onNext={addSearchParam}
        />
      );
    return (
      <Enter
        onResetPassword={addSearchParam}
        searchParams={searchParams}
        removeSearchParam={removeSearchParam}
        reset_success={searchParams.get("success")}
      />
    );
  }

  return (
    <StyledLogin>
      <main>
        <AuthLeft />
        <section className="auth__right">{getRightComponent()}</section>
        {/* <i className="icon icon-close"></i> */}
      </main>
    </StyledLogin>
  );
}

const StyledLogin = styled.div`
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;

  & > main {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border-radius: 30px;
    overflow: hidden;
    background: var(--light);
    box-shadow: 0px 2px 10px var(--dark-5);
    position: relative;
    min-height: 440px;

    .auth__right {
      width: 440px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
    }

    i {
      &.icon-close {
        position: absolute;
        top: 25px;
        right: 25px;
        background-color: var(--dark);
      }
    }
  }

  @media (max-width: 768px) {
    align-items: flex-start;
    & > main {
      flex-direction: column;
      width: 100%;
      border-radius: 0 0 30px 30px;
      min-height: auto;

      .auth__right {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding: 60px 40px;
      }
    }
  }
`;
