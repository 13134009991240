import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";
import { dash_cars_colors } from "../../../util/lists";

// sample/test data
// const data = Array.from({length: 5}).reduce((acc, _, i, {length}) => {
// 	const percent = i === length - 1 ? acc.remaining : Math.random() * acc.remaining;
// 	acc.remaining -= percent;
// 	acc.result.push({
// 		label: `Item ${i}`,
// 		percent
// 	});
// 	return acc;
// }, { remaining: 1, result: [] }).result;

// const length = 12;
// const data = Array.from({length}, () => ({percent: 1 / length}));

// convert between radians and degrees
const d2r = (d) => d * (Math.PI / 180);
const r2d = (r) => r / (Math.PI / 180);

/**
 * Find the angle that will produce an arc of a given length at a given radius.
 * Using this to allow for gaps between the segments. Returns angle in radians
 * arcLength = radius * angleInRadians
 *
 * @param {number} arcLength - the sought arc length in local coordinate space
 * @param {number} atRadius - the radius of the arc
 * @returns {number} - the angle in radians of an arc of the given length at the given radius
 */
const angleForArcLength = (arcLength, atRadius) => arcLength / atRadius;

/**
 * The viewBox size. Coordinates are computed within this coordinate space
 */
const size = 85;

/**
 * The center of the viewBox, center of the chart
 */
const center = size / 2;

/**
 * The diameter of the chart's inner hole in local coordinate space units
 */
const hole = 60;

/**
 * The thickness of the chart segments for the given size and hole
 */
const thickness = (size - hole) / 2;

/**
 * The outer radius of the chart
 */
const radiusOuter = size / 2.1;

/**
 * The inner radius of the chart
 */
const radiusInner = radiusOuter - thickness;

/**
 * The size of the gap between chart segments, in local coordinate space units
 */
const gapSize = 1.5;

/**
 * Compute the angle offset required to establish the gaps between segments at the inner edge
 */
const gapAngleOffsetInner = r2d(angleForArcLength(gapSize, radiusInner));

/**
 * Compute the angle offset required to establish the gaps between segments at the outer edge
 */
const gapAngleOffsetOuter = r2d(angleForArcLength(gapSize, radiusOuter));

/**
 * The minimum angle that won't be swallowed by the gap offsets at the inner edge.
 * Used to compute the minimum value that won't get swallowed (minimumValue defined below)
 */
const minimumAngleDeg = r2d(angleForArcLength(gapSize * 2, radiusInner));

/**
 * The minimum value that won't get swallowed by the gap offsets at the inner edge
 */
const minimumValue = minimumAngleDeg / 360;

/**
 * Computes an x/y coordinate for the given angle and radius
 * @param {number} deg - The angle in degrees
 * @param {number} r  - The radius
 * @returns {Array} - An x/y coordinate for the point at the given angle and radius
 */
const coords = (deg, r) => {
  const rad = d2r(deg);

  return [center - Math.cos(rad) * r, center - Math.sin(rad) * r];
};

export default class PieChart extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    series: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.number.isRequired,
        label: PropTypes.string,
        color: PropTypes.string,
      })
    ),
    classes: PropTypes.shape({
      title: PropTypes.string,
      container: PropTypes.string,
      chart: PropTypes.string,
      legend: PropTypes.string,
      legendLabel: PropTypes.string,
      // legendValue: PropTypes.string,
      legendPercent: PropTypes.string,
    }),
    colors: PropTypes.arrayOf(PropTypes.string),
  };

  alfa = null;
  makeSegment = ({ paths, subtotal }, { percent, color, car_id }, i) => {
    const startAngle = subtotal * 360 + 90; // +90 so we start at 12 o'clock
    const endAngle = startAngle + percent * 360;

    // no gaps for values beneath the minimum threshold
    const useGap = percent >= minimumValue;
    const innerGap = useGap ? gapAngleOffsetInner : 0;
    const outerGap = useGap ? gapAngleOffsetOuter : 0;

    const startAngleInner = startAngle + innerGap;
    const startAngleOuter = startAngle + outerGap;
    const endAngleInner = endAngle - innerGap;
    const endAngleOuter = endAngle - outerGap;

    const [x1, y1] = coords(startAngleInner, radiusInner); // start point on inner circle
    const [x2, y2] = coords(startAngleOuter, radiusOuter); // start point on outer circle
    const [x3, y3] = coords(endAngleOuter, radiusOuter); // end point on outer circle
    const [x4, y4] = coords(endAngleInner, radiusInner); // end point on inner circle

    const largeArc = percent > 0.5 ? 1 : 0;
    const sweepOuter = 1;
    const sweepInner = 0;

    const commands = [
      // move to start angle coordinate, inner radius
      `M${x1} ${y1}`,

      // line to start angle coordinate, outer radius
      `L${x2} ${y2}`,

      // arc to end angle coordinate, outer radius
      `A${radiusOuter} ${radiusOuter} 0 ${largeArc} ${sweepOuter} ${x3} ${y3}`,

      // line to end angle coordinate, inner radius
      `L${x4} ${y4}`,

      // arc back to start angle coordinate, inner radius
      `A${radiusInner} ${radiusInner} 0 ${largeArc} ${sweepInner} ${x1} ${y1}`,
    ];

    paths.push(
      <path
        key={i}
        className={
          "donut-chart-segment " +
          (this.props.hoverId === car_id
            ? "active"
            : this.props.hoverId === null
            ? ""
            : "disabled")
        }
        fill={`var(--${color})`}
        stroke="none"
        d={commands.join(" ")}
        onMouseOver={() => {
          this.props.changeHoverId(car_id);
          if (this.alfa) {
            clearTimeout(this.alfa);
          }
        }}
        onMouseOut={() => {
          this.alfa = setTimeout(() => {
            this.props.changeHoverId(null);
          }, 500);
        }}
      />
    );

    return {
      paths,
      subtotal: subtotal + percent,
    };
  };

  computePercentages() {
    const { cars, tab, analytic } = this.props;

    // eliminate values of zero or less; protects against division by zero when computing percentages
    const filtered = (cars || []).filter(
      (i) => i[analytic ? tab : "profit"] > 0
    );
    const total = filtered.reduce(
      (t, i) => t + i[analytic ? tab : "profit"],
      0
    );

    return filtered.map((item, idx) => ({
      ...item,
      percent: item[analytic ? tab : "profit"] / total,
      color: dash_cars_colors[idx],
    }));
  }

  render() {
    const items = this.computePercentages();

    return !(items || []).length ? null : (
      <StyledDonut className="donut-chart-container">
        <div className="donut-chart">
          <svg viewBox={`0 0 ${size} ${size}`}>
            {items.reduce(this.makeSegment, { paths: [], subtotal: 0 }).paths}
          </svg>
        </div>
      </StyledDonut>
    );
  }
}

const StyledDonut = styled.div`
  .donut-chart-segment {
    border-radius: 6px !important;
    transition: .35s ease-in-out;

    &.disabled {
      opacity: 0.3;
    }
  }

  /* .donut-chart-segment:nth-child(5n + 1) {
    fill: var(--color1);
  }
  .donut-chart-segment:nth-child(5n + 2) {
    fill: var(--color2);
  }
  .donut-chart-segment:nth-child(5n + 3) {
    fill: var(--color3);
  }
  .donut-chart-segment:nth-child(5n + 4) {
    fill: var(--color4);
  }
  .donut-chart-segment:nth-child(5n + 5) {
    fill: var(--color5);
  } */

  .donut-chart-legend {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .donut-chart-legend li {
    margin-bottom: 0.5em;
  }

  .donut-chart-legend li span {
    margin-right: 0.5em;
  }

  .donut-chart-legend li::before {
    content: "";
    display: inline-block;
    width: 1em;
    height: 1em;
    margin-right: 0.5em;
    vertical-align: middle;
  }

  .donut-chart-legend li:nth-child(5n + 1)::before {
    background: var(--color1);
  }
  .donut-chart-legend li:nth-child(5n + 2)::before {
    background: var(--color2);
  }
  .donut-chart-legend li:nth-child(5n + 3)::before {
    background: var(--color3);
  }
  .donut-chart-legend li:nth-child(5n + 4)::before {
    background: var(--color4);
  }
  .donut-chart-legend li:nth-child(5n + 5)::before {
    background: var(--color5);
  }
`;
