import React from "react";
import styled from "styled-components";

//
import StateCarCard from "./StateCarCard";
import DocDownload from "../../../../components/doc-download/DocDownload";
import PregressSection from "../../../../components/rent-book-progress/PregressSection";
import { msToNormTime } from "../../../../util/date";

export default function ServiceModal({ car }) {
  console.log(car);
  // render
  if (!(car && car.status === "off")) return null;

  return (
    <StyledServiceModal>
      <main>
        <section className="state__car__wrap">
          <StateCarCard
            title="Автомобиль в сервисе"
            icon="icon-tool"
            color="danger"
          />
        </section>
        <section className="sevice__modal__list">
          <ul>
            <li>
              <PregressSection
                bg="danger"
                start={car?.service_start}
                end={car?.service_end}
                startText="Начало сервиса"
                endText="Предполагаемое окончание работ"
              />
            </li>
            <li>
              <h1>Описание</h1>
              <p>{car?.service_desc || "-"}</p>
            </li>
          </ul>
        </section>
      </main>
      {car?.service_agreement_link && (
        <footer>
          <DocDownload
            title="Акт о проведении ремонтных работ"
            text="Дата заключения 2 апреля 2022 г."
          />
        </footer>
      )}
    </StyledServiceModal>
  );
}

const StyledServiceModal = styled.div`
  min-height: calc(100vh - 152px);
  background-color: var(--light);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 30px;

  .state__car__wrap {
    margin-bottom: 60px;
  }

  .sevice__modal__list {
    padding: 0 20px;
    ul {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 60px;

      li {
        h1 {
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 10px;
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
    }
  }

  footer {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    padding: 30px 30px 0;
    min-height: calc(100vh - 54px);

    .sevice__modal__list {
      padding: 0;
      ul {
        li {
          h1 {
            font-size: 16px;
            line-height: 20px;
          }

          p {
            font-size: 16px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;
