import axios from "axios";

import CONST from "../../util/const";
import cookies from "../../util/cookie";

const $api = axios.create({
  baseURL: CONST.BASE_URL,
});

$api.interceptors.request.use(
  (config) => {
    let tempConfig = { ...config };
    if (
      !tempConfig.url.includes("getToken") &&
      !tempConfig.url.includes("set_new_pass") &&
      !tempConfig.url.includes("check_code") &&
      !tempConfig.url.includes("new_pass_request")
    ) {
      // let tempUrl = tempConfig.url.split("/").filter((i) => i);
      let token = cookies.getCookie(CONST.APP_TOKEN);
      if (!token) {
        window.location.replace("/");
        return null;
      }
      tempConfig.headers.token = token || "";
      // tempUrl = [tempUrl[0], token, "hexapi", ...tempUrl.slice(1)];
      // tempConfig.url = tempUrl.join("/");
    }

    // if (process.env.NODE_ENV === "production") {
    //   tempConfig.baseURL =
    //     "https://ulug-cors-proxy.herokuapp.com/" + tempConfig.baseURL;
    // }
    return tempConfig;
  },
  (err) => {
    console.log(err);
    return Promise.reject(err);
  }
);
$api.interceptors.response.use(
  (res) => {
    return Promise.resolve(res);
  },
  (err) => {
    if (err?.response?.status === 401 && window.location.pathname !== "/") {
      cookies.setCookie(CONST.APP_TOKEN,"", 0);
      cookies.setCookie(CONST.USER_PHONE,"", 0);
      window.location.replace("/");
    }
    return Promise.reject(err);
  }
);

export default $api;
