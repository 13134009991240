import React from "react";
import styled from "styled-components";

export default function Zoom({ inc, dec }) {
  return (
    <StyledZoom className="zoom__group">
      <div className="zoom__icon__wrapper" onClick={inc}>
        <i className="icon icon-plus"></i>
      </div>
      <div className="zoom__icon__wrapper" onClick={dec}>
        <i className="icon icon-minus"></i>
      </div>
    </StyledZoom>
  );
}

const StyledZoom = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  gap: 10px;
  position: absolute;
  top: 30px;
  right: 30px;

  .zoom__icon__wrapper {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: 1px solid var(--primary-dark-15);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    cursor: pointer;

    i {
      background-color: var(--dark);
    }
  }

  @media (max-width: 1200px) {
    top: auto;
    right: auto;
    bottom: 30px;
    left: 50%;
    flex-direction: row;
    gap: 20px;
    transform: translateX(-50%);
  }
`;
