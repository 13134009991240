import React from "react";
import styled, { css } from "styled-components";

//
import NumberFormat from "../number-format/NumberFormat";

export default function PayCard({
  color,
  icon,
  text,
  title = 0,
  mobileSize,
  park,
  border,
  navigate,
}) {
  return (
    <StyledPayCard
      color={color}
      mobileSize={mobileSize}
      park={park}
      border={border}
    >
      <div className="left__box">
        <i className={"icon " + icon}></i>
      </div>
      <div className="main__box">
        <p>{text}</p>
        <h1>
          <NumberFormat value={title} />
        </h1>
      </div>
      {navigate && (
        <div className="navigate__wrapper">
          <div className="i__right__wrap">
            <i className="icon icon-chevron-right"></i>
          </div>
        </div>
      )}
    </StyledPayCard>
  );
}

const StyledPayCard = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background: var(--success-10);
  border-radius: 20px;
  min-height: 90px;

  .left__box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    background-color: var(--success);
    padding: 11px;
    border-radius: 15px;
    min-width: 46px;

    i {
      background-color: var(--light);
    }
  }

  .main__box {
    padding: 20px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
      margin-bottom: 5px;
    }

    h1 {
      font-size: 22px;
      font-weight: 600;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }

  .navigate__wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;

    .i__right__wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border: 1px solid var(--primary-dark-15);
      margin-left: auto;
      cursor: pointer;

      i {
        background-color: var(--dark);
      }
    }
  }

  ${({ color }) =>
    color === "primary"
      ? css`
          background-color: var(--light);

          .left__box {
            background-color: var(--primary-10);

            i {
              background-color: var(--primary);
            }
          }
        `
      : color === "warning"
      ? css`
          background-color: var(--warning-10);

          .left__box {
            background-color: var(--warning);
          }
        `
      : color === "danger"
      ? css`
          background-color: var(--danger-10);
          .left__box {
            background-color: var(--danger);
          }
        `
      : ""}

  ${({ mobileSize }) =>
    mobileSize === "sm"
      ? css`
          border-radius: 15px;
          min-height: 70px;

          .left__box {
            min-width: auto;
            width: 40px;
            padding: 5px;

            i {
              min-width: 24px;
            }
          }

          .main__box {
            padding: 13px 30px 13px 20px;
            white-space: nowrap;

            p {
              font-size: 14px;
              line-height: 17px;
            }

            h1 {
              font-size: 18px;
              line-height: 22px;
            }
          }
        `
      : null}

  ${({ park }) =>
    park &&
    css`
      .left__box {
        width: 60px;
        border-radius: 20px;

        i {
          width: 30px;
          height: 30px;
        }
      }

      .main__box {
        padding: 25px 30px;
      }
    `}

  ${({ border }) =>
    border &&
    css`
      border: 2px solid var(--primary);
    `}
`;
