import React from "react";
import styled from "styled-components";

//
import CurrencyTab from "./CurrencyTab";
import IconWrapper from "../../icon-wrapper/IconWrapper";
import { dash_cars_colors } from "../../../util/lists";

export default function MobileChart({
  setShowType,
  showType,
  analytic,
  tab,
  cars,
  sum,
  onSort,
  changeHoverId,
  hoverId,
}) {
  let alfa = null;
 
  return (
    <StyledMobileChart>
      <ul className="mobile__doughunt">
        {cars.map((i, idx) => (
          <li
            key={i.car_id}
            style={{
              width:
                (i[tab] / (tab === "load" ? sum * cars.length : sum)) * 100 +
                "%",
              background: `var(--${dash_cars_colors[idx]})`,
              opacity: hoverId && hoverId !== i.car_id ? 0.5 : 1,
            }}
            onMouseOver={() => {
              changeHoverId(i.car_id);
              if (alfa) {
                clearTimeout(alfa);
              }
            }}
            onMouseOut={() => {
              alfa = setTimeout(() => changeHoverId(null), 500);
            }}
          ></li>
        ))}
      </ul>
      <div className="bottom__sect">
        <IconWrapper icon="filter-lines" border={1} onClick={onSort} />
        <CurrencyTab
          setShowType={setShowType}
          showType={showType}
          analytic={analytic}
          tab={tab}
        />
      </div>
    </StyledMobileChart>
  );
}

const StyledMobileChart = styled.div`
  width: 100%;

  .mobile__doughunt {
    display: flex;
    display: -webkit-box;
    display: -ms-flexbox;
    height: 20px;
    border-radius: 20px;
    overflow: hidden;
    margin-bottom: 20px;

    li {
      transition: opacity 0.2s linear;
      &:first-of-type {
        border-radius: 20px 0 0 20px;
      }
      &:last-of-type {
        border-radius: 0 20px 20px 0;
      }
    }
  }

  & > .bottom__sect {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
`;
