/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useContext, useState, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useQueryClient } from "react-query";

//
import Consts from "../util/const";
import { GET_USER_INFO } from "./user/type";
import {
  GET_CARS_STATUS_COUNT,
  GET_DASH_CARS,
  GET_CAR_OPERATIONS,
} from "./car/type";
import { GET_DASH_PROFITS } from "./dash-profit/type";
import { GET_RB_CARS, GET_RB_SUMMARY } from "./rent-book/type";
import { GET_ANAL_CARS, GET_CHART_DATA } from "./analytic/type";
import { GET_PARK_CARS, GET_PARK_CAR_LOAD } from "./park/type";
import { GET_DOCS, GET_DOC_BY_ID } from "./doc/type";
import { useGetDocs } from "./doc/hook";
import { GET_NOTiFICATIONS } from "../store/notif/type";
import { getFromTo } from "../util/date";
import { useGetNotifications } from "../store/notif/hook";
import cookies from "../util/cookie";

//
const AppContext = createContext(null);
export const useAppContext = () => useContext(AppContext);

//
export default function Context({ children }) {
  // helper
  const { pathname } = useLocation();
  // eslint-disable-next-line 
  const [searchParams, setSearchParams] = useSearchParams();

  // state
  const [notif_count, setNotifCount] = useState(0);
  const [isAuth, setIsAuth] = useState(
    !!cookies.getCookie(Consts.APP_TOKEN)
  );
  const [navH, setNavH] = useState(0);

  // query
  let token = cookies.getCookie(Consts.APP_TOKEN)
  const queryClient = useQueryClient();
  let { data: notifs } = useGetNotifications({ enabled: !!token });
  let { data: docs } = useGetDocs({ enabled: !!token });
  let docs_count = docs?.data?.data.filter(
    (i) => i.status === "Pending"
  ).length;

  // watcher
  useEffect(() => {
    if (pathname.includes("auth")) {
      queryClient.clear();
    }
  }, [pathname]);

  useEffect(() => {
    if (!notifs) return;
    setNotifCount(notifs?.data?.data.filter((i) => !i.read).length);
  }, [notifs]);

  // method
  function resetCache() {
    let token = cookies.getCookie(Consts.APP_TOKEN);
    if (!pathname.includes("auth") && token) {
      queryClient.resetQueries(GET_USER_INFO, { exact: true });
    }
    if (pathname.includes("dashboard")) {
      queryClient.resetQueries(GET_CARS_STATUS_COUNT, { exact: true });
      queryClient.resetQueries(GET_DASH_CARS, { exact: true });
      queryClient.resetQueries(GET_DASH_PROFITS, { exact: true });
    }
    if (pathname.includes("rent-book")) {
      queryClient.resetQueries(GET_RB_CARS, { exact: true });
      queryClient.resetQueries(GET_RB_SUMMARY, { exact: true });
      queryClient.resetQueries(GET_CARS_STATUS_COUNT, { exact: true });
    }
    if (pathname.includes("analytics")) {
      let { from, to } = JSON.parse(
        localStorage.getItem(Consts.ANAL_FROM_TO) || "{}"
      );
      queryClient.resetQueries([GET_ANAL_CARS, from, to], { exact: true });
      queryClient.resetQueries([GET_CHART_DATA, from, to], { exact: true });
    }
    if (pathname.includes("parking")) {
      queryClient.resetQueries(GET_PARK_CARS, { exact: true });
      let download = searchParams.get("download");
      if (download) {
        queryClient.resetQueries([GET_PARK_CAR_LOAD, download], {
          exact: true,
        });
      }
      let incexp = searchParams.get("incexp");
      if (incexp) {
        const { from, to } = getFromTo(1);
        queryClient.resetQueries([GET_CAR_OPERATIONS, incexp, from, to], {
          exact: true,
        });
      }
    }
    if (pathname.includes("doc")) {
      queryClient.resetQueries(GET_DOCS, { exact: true });
      let doc_id = pathname.split("/");
      if (doc_id.length === 4) {
        let date_created = localStorage.getItem(Consts.DOC_DATE);
        queryClient.resetQueries([GET_DOC_BY_ID, doc_id[3], date_created], {
          exact: true,
        });
      }
    }
    if (pathname.includes("notif")) {
      queryClient.resetQueries(GET_NOTiFICATIONS, { exact: true });
    }
    queryClient.clear();
  }

  function changeIsAuth(v) {
    setIsAuth(v);
  }

  function changeNavH(h) {
    setNavH(h);
  }

  return (
    <AppContext.Provider
      value={{
        state: { isAuth, navH, notif_count, docs_count },
        fn: { resetCache, changeIsAuth, changeNavH, setNotifCount },
      }}
    >
      {children}
    </AppContext.Provider>
  );
}
