/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import cl from "classnames";
import { v4 } from "uuid";

//
import BackTitleNum from "../components/BackTitleNum";
import Card from "../../../../components/card/Card";
import Chart from "../../../../components/chart/Chart";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useGetParkCarLoad } from "../../../../store/park/hook";

export default function Download({ removeParam, car, params }) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [curYear, setCurYear] = useState(null);
  const now = new Date();
  const [year, setYear] = useState(now.getFullYear());
  const [years, setYears] = useState({
    min: now.getFullYear(),
    max: now.getFullYear(),
  });

  // query
  const dow_car_id = params.get("download");
  const car_id = params.get("selected_car_id");
  let { data: load, isLoading } = useGetParkCarLoad(dow_car_id || car_id);

  // watcher
  useEffect(() => {
    if (!load) return;
    load.data.data.load_year.forEach((i) => {
      if (i.year > years.max) {
        setYears((p) => ({ ...p, max: i.year }));
      }
      if (i.year < years.min) {
        setYears((p) => ({ ...p, min: i.year }));
      }
      if (i.year === year) {
        setCurYear({ ...i });
      }
    });
  }, [load, year]);

  // method
  const list = useMemo(() => {
    if (!curYear?.load_month) return [];

    return curYear.load_month.map((i) => ({
      id: v4(),
      month: i.month,
      sum: i.load > 0 ? i.load * 1000 : 0,
      year,
      closed: true,
    }));
  }, [curYear]);

  return (
    <StyledDownload>
      <BackTitleNum
        onBack={() => removeParam("download")}
        gos={car?.gos_num}
        model={car?.brand_model}
      />
      {width > 768 ? (
        <main className="num__info">
          <Card>
            <ul className="num__info__left p-r">
              {isLoading && <Loader />}
              <li>
                <h1>{curYear?.days_total || 0}</h1>
                <p>Дней в аренде</p>
              </li>
              <li>
                <h1>{curYear?.load_avg || 0}%</h1>
                <p>Средняя загрузка</p>
              </li>
              <li>
                <h1>
                  <NumberFormat value={curYear?.run_total} suffix=" км" />
                </h1>
                <p>Пробег</p>
              </li>
            </ul>
          </Card>
          <Card>
            <div className="num__info__right p-r">
              {isLoading && <Loader />}
              <i
                className={cl("icon icon-chevron-right", {
                  disabled: year - years.min === 0,
                })}
                style={{ transform: "rotate(180deg)" }}
              ></i>
              <p>2022</p>
              <i
                className={cl("icon icon-chevron-right", {
                  disabled: years.max - year === 0,
                })}
              ></i>
            </div>
          </Card>
        </main>
      ) : (
        <Card mobile={1}>
          <main className="mobile__num__info p-r">
            {isLoading && <Loader />}
            <div className="year__counter">
              <i
                className={cl("icon icon-chevron-right", {
                  disabled: year - years.min === 0,
                })}
                style={{ transform: "rotate(180deg)" }}
              ></i>
              <p>2022</p>
              <i
                className={cl("icon icon-chevron-right", {
                  disabled: years.max - year === 0,
                })}
              ></i>
            </div>
            <ul className="nums__list">
              <li>
                <p>Всего в аренде</p>
                <h1>{curYear?.days_total || 0} дн.</h1>
              </li>
              <li>
                <p>Средняя загрузка</p>
                <h1>{curYear?.load_avg || 0}%</h1>
              </li>
              <li>
                <p>Пробег</p>
                <h1>
                  <NumberFormat value={curYear?.run_total} suffix=" км" />
                </h1>
              </li>
            </ul>
          </main>
        </Card>
      )}
      <Chart type="%" loading={isLoading} list={list} />
    </StyledDownload>
  );
}

const StyledDownload = styled.div`
  .num__info {
    display: -ms-grid;
    display: grid;
    grid-template-columns: auto 300px;
    gap: 20px;
    margin-bottom: 20px;

    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -ms-flex-pack: distribute;
      justify-content: space-around;
      padding: 30px 0;

      li {
        text-align: center;

        h1 {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          letter-spacing: 0em;
          color: var(--dark);
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 50px;
      height: 100%;
      padding: 30px;
      min-height: 125px;

      p {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark);

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  .mobile__num__info {
    margin-bottom: 20px;
    padding: 40px 30px 30px 30px;

    .year__counter {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      margin-bottom: 42px;
      padding: 0 12px;

      p {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark);

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }

    .nums__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      overflow-y: auto;

      &::-webkit-scrollbar {
        height: 0;
      }

      li {
        padding: 14px 20px;
        border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
        white-space: nowrap;

        p {
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          color: var(--dark-70);
          margin-bottom: 5px;
        }

        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .num__info {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
    }
  }
`;
