import api from "../../service/axios";

class AuthApi {
  static getToken(login, pass) {
    let url = "/getToken";
    return api.post(url, {
      login,
      pass,
    });
  }

  static logout() {
    let url = "/logout";
    return api.get(url);
  }

  static newPassReq(phone) {
    let url = "/new_pass_request";
    return api.post(url, {
      login: phone,
    });
  }

  static checkCode(phone, code) {
    let url = "/check_code";
    return api.post(url, {
      login: phone,
      pass: code,
    });
  }

  static setNewPassword(login, code, new_pass) {
    let url = "/set_new_pass";
    return api.post(url, {
      login,
      pass: code,
      new_pass,
    });
  }
}

export default AuthApi;
