import React, { useEffect } from "react";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";

//
import { useAppContext } from "../../store/Context";

export default function Navbar() {
  // helper
  const { pathname } = useLocation();

  // context
  const {
    state: { notif_count, docs_count },
    fn: { changeNavH },
  } = useAppContext();

  // lifecycle
  useEffect(() => {
    window.addEventListener("resize", watchNavH);

    return () => {
      window.removeEventListener("resize", watchNavH);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // method
  function watchNavH() {
    let nav = document.getElementById("nav");
    changeNavH(nav.getBoundingClientRect().height);
  }

  function checkIsActive(path) {
    return pathname.includes(path) ? "active" : "";
  }

  return (
    <StyledNavbar id="nav">
      <div className="container">
        <main className="nav">
          <ul className="nav__list">
            <li className="nav__item">
              <Link
                to="/cabinet/dashboard"
                className={`nav__link ${checkIsActive("dashboard")}`}
              >
                <div className="figure circle">
                  <i className="icon icon-pizza"></i>
                </div>
                <p>Дашборд</p>
              </Link>
            </li>

            <li className="nav__item">
              <Link
                to="/cabinet/rent-book"
                className={`nav__link ${checkIsActive("rent-book")}`}
              >
                <div className="figure booking ">
                  <i className="icon icon-booking"></i>
                  <i className="icon icon-arrow-right"></i>
                </div>
                <p>Аренды и брони</p>
                {/* <div className="red-count">3</div> */}
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/cabinet/analytics"
                className={`nav__link ${checkIsActive("analytics")}`}
              >
                <div className="figure circle ">
                  <i className="icon icon-analytic"></i>
                </div>
                <p>Аналитика</p>
              </Link>
            </li>

            <li className="nav__item">
              <Link
                to="/cabinet/parking"
                className={`nav__link ${checkIsActive("parking")}`}
              >
                <div className="figure parking ">
                  <i className="icon icon-parking"></i>
                </div>
                <p>Автопарк</p>
              </Link>
            </li>
            <li className="nav__item">
              <Link
                to="/cabinet/doc"
                className={`nav__link ${checkIsActive("doc")}`}
              >
                <div className="figure doc ">
                  <i className="icon icon-doc"></i>
                </div>
                <p>Документы</p>
                {docs_count > 0 && <div className="red-circle"></div>}
              </Link>
            </li>
            <li className="nav__item mobile__none ">
              <Link
                to="/cabinet/notif"
                className={`nav__link ${checkIsActive("notif")}`}
              >
                <div className="figure bell ">
                  <i className="icon icon-bell"></i>
                </div>
                <p>Уведомления</p>
                {notif_count > 0 && (
                  <div className="red-count">{notif_count}</div>
                )}
              </Link>
            </li>
          </ul>
        </main>
      </div>
    </StyledNavbar>
  );
}

const StyledNavbar = styled.nav`
  background: var(--light);
  box-shadow: 0px 0px 20px rgba(26, 43, 70, 0.15);
  padding: 12px 0;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 4;

  .nav {
    &__list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
    }

    &__link {
      display: inline-block;
      padding: 12px 22px 12px 12px;
      border-radius: 16px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 10px;
      transition: 0.2s linear;

      p {
        font-size: 18px;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: 0em;
        color: var(--dark);
        transition: 0.2s linear;
      }

      .red-circle {
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background-color: var(--danger);
        border: 1px solid transparent;
      }

      .red-count {
        display: inline-block;
        font-size: 12px;
        font-weight: 600;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--light);
        background: var(--danger);
        border-radius: 10.5px;
        padding: 2px 8px;
        border: 1px solid transparent;
      }

      .figure {
        position: relative;
        transition: 0.2s linear;
        background: var(--primary-30);

        &.circle {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }

        &.booking {
          background-color: transparent;
          width: 20px;
          height: 25px;
        }

        &.parking {
          border-radius: 4px;
          width: 24px;
          height: 24px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
        }

        &.doc {
          width: 20px;
          height: 25px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          border-radius: 4px;
        }

        &.bell {
          background-color: transparent;
        }

        i {
          background-color: var(--primary);

          &.icon-pizza {
            position: absolute;
            top: 0;
            right: -2px;
            width: 17px;
            height: 15px;
          }

          &.icon-analytic {
            width: 16px;
            height: 7px;
          }

          &.icon-booking {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--primary-80);
          }

          &.icon-arrow-right {
            width: 16px;
            height: 10px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: -2.5px;
          }

          &.icon-parking {
            width: 10.5px;
            height: 14px;
          }

          &.icon-doc {
            width: 12px;
            height: 12px;
          }

          &.icon-bell {
            background: var(--primary);
            opacity: 0.3;
          }
        }
      }

      &.active {
        background: var(--primary);

        .red-circle {
          border-color: var(--light);
        }

        .red-count {
          border-color: var(--light);
        }

        p {
          color: var(--light);
        }

        .figure {
          background-color: var(--light-30);

          &.booking {
            background-color: transparent;
          }

          &.bell {
            background-color: transparent;
          }

          i {
            background-color: var(--light);

            &.icon-booking {
              background-color: var(--light-80);
            }

            &.icon-bell {
              background: var(--light);
              opacity: 0.6;
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    padding: 12px;

    .nav {
      &__link {
        flex-direction: column;
        padding: 10px;

        p {
          font-size: 14px;
          line-height: 16px;
        }
      }
    }
  }

  @media (max-width: 768px) {
    position: fixed;
    top: auto;
    bottom: 0;
    left: 0;
    width: 100%;
    height: max-content;
    padding: 12px 10px 8px;

    .nav {
      &__item {
        &.mobile__none {
          display: none;
        }
      }

      &__link {
        flex-direction: column;
        padding: 12px;
        gap: 7px;
        position: relative;
        padding: 10px 8px;

        .figure {
        }

        p {
          width: 52px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          font-size: 10px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0em;
        }

        .red-circle {
          position: absolute;
          top: 6px;
          right: 8px;
        }

        .red-count {
          position: absolute;
          top: 0;
          right: 0;
          font-size: 10px;
          font-weight: 600;
          line-height: 12px;
          letter-spacing: 0em;
          padding: 2px 6px;
          border-radius: 8px;
        }
      }
    }
  }
`;
