import React from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

//

export default function CardReject({ title, type, status, comment, note }) {
  return (
    <StyleCardReject type={type} status={status}>
      <div className="icon__wrapp">
        <i
          className={cl("icon", {
            "icon-alert-circle": status === "Pending",
            "icon-close": status === "Declined",
            "icon-nike": status === "Approved",
          })}
        ></i>
      </div>
      <div className="text">
        <h4>{title}</h4>
        <div>
          <p>{(status === "Declined" ? note : comment) || "-"}</p>
        </div>
      </div>
    </StyleCardReject>
  );
}

const StyleCardReject = styled.div`
  border: 1px solid var(${({ border }) => border});
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 30px;
  padding: 30px 0 30px 30px;
  border-radius: 30px;

  .icon__wrapp {
    width: 80px;
    height: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 50%;
    background-color: var(--light);

    & > i {
      width: 40px;
      height: 40px;
      background-color: var(--dark);
    }
  }

  .text {
    & > h4 {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
    }
    & > div {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 5px;
      margin-top: 5px;

      & > i {
        background-color: var(--dark);
      }

      & > p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }
  }

  ${({ status }) =>
    status === "Pending"
      ? css`
          .icon__wrapp {
            background-color: var(--warning-10);

            & > i {
              background-color: var(--warning-dark);
            }
          }
        `
      : status === "Declined"
      ? css`
          border: 1px solid var(--danger);

          .icon__wrapp {
            background-color: var(--danger-10);

            & > i {
              background-color: var(--danger);
            }
          }
        `
      : css`
          .icon__wrapp {
            background-color: var(--success-10);

            & > i {
              background-color: var(--success-dark);
            }
          }
        `}

  @media (max-width: 768px) {
    gap: 20px;
    border: none;
    border-radius: 20px;
    padding: 20px 22px 20px 20px;

    .icon__wrapp {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      width: 48px;
      min-width: 48px;
      height: 48px;
      background-color: var(--light);

      & > i {
        width: 24px;
        height: 24px;
      }
    }
    .text {
      & > h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
      }

      & > div {
        & > i {
          display: none;
        }

        & > p {
          color: var(--dark-70);
          max-width: 258px;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
        }
      }
    }

    ${({ status }) =>
      status === "Declined"
        ? css`
            background-color: var(--danger-10);

            .icon__wrapp {
              background-color: var(--light);
            }

            .text {
              & > h4 {
                color: var(--danger-dark);
              }
            }
          `
        : status === "Approved"
        ? css`
            background-color: var(--success-20);

            .text {
              & > h4 {
                color: var(--success-dark);
              }
            }
          `
        : css`
            background-color: var(--warning-10);

            .text {
              & > h4 {
                color: var(--warning-dark);
              }
            }
          `}
  }
`;
