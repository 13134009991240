import api from "../../service/axios";

class DocApi {
  static getDocs() {
    let url = "/getDocuments";
    return api.get(url);
  }
  static getDocById(id, date) {
    let url = `/getReport/${id}/${date}`;
    return api.get(url);
  }

  static postReport(body) {
    let url = `/putReport`;
    return api.put(url, body);
  }

  static getAct(id, date) {
    let url = `/getPaper/${id}/${date}`;
    return api.get(url);
  }
}

export default DocApi;
