import React from "react";
import styled, { css } from "styled-components";

export default function IconWrapper({
  icon,
  w = 40,
  h = 40,
  bg = "light",
  color = "dark",
  border,
  rounded,
  redCircle,
  approved,
  borderColor,
  ...props
}) {
  return (
    <StyledIconWrapper
      w={w}
      h={h}
      bg={bg}
      color={color}
      border={border}
      rounded={rounded}
      borderColor={borderColor}
      {...props}
    >
      <i className={"icon icon-" + icon}></i>
      {redCircle && <div className="red__circle"></div>}
      {approved && (
        <div className="approved__icon">
          <i className="icon icon-xs icon-check"></i>
        </div>
      )}
    </StyledIconWrapper>
  );
}

const StyledIconWrapper = styled.div`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: relative;
  min-width: 50px;

  .red__circle {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--light);
    top: 0;
    right: 0;
    transform: translate(25%, -25%);

    &::before {
      content: "";
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: var(--danger);
    }
  }

  .approved__icon {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(25%, -25%);
    width: 27px;
    height: 27px;
    border: 3px solid var(--success);
    border-radius: 50%;
    background-color: var(--light);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;

    i {
      background-color: var(--success);
    }
  }

  ${({ w, h, bg, color }) => css`
    width: ${w}px;
    height: ${h}px;
    min-width: ${w}px ;
    min-height: ${h}px;
    background-color: var(--${bg});

    i {
      background-color: var(--${color});
    }
  `}

  ${({ rounded }) =>
    rounded &&
    css`
      border-radius: ${rounded}px;
    `}

  ${({ border, borderColor }) =>
    border &&
    css`
      border: 1px solid var(--${borderColor || "primary-dark-15"});
    `}
`;
