import { useQuery } from "react-query";

//
import Api from "./api";
import {
  GET_CARS_STATUS_COUNT,
  GET_CARS_BY_STATUS,
  GET_CAR_BY_STATUS,
  GET_DASH_CARS,
  GET_CAR_OPERATIONS,
} from "./type";

export function useGetCarsStatusCount() {
  return useQuery(GET_CARS_STATUS_COUNT, Api.getCarsStatusesCount);
}

export function useGetCarsByStatus(type) {
  return useQuery([GET_CARS_BY_STATUS, type], () => Api.getCarsByStatus(type), {
    enabled: !!type,
  });
}

export function useGetCarByStatus(type, id) {
  let enabled = !!(type && id);
  return useQuery(
    [GET_CAR_BY_STATUS, type, id],
    () => Api.getCarByStatus(type, id),
    {
      enabled,
    }
  );
}

export function useGetDashboardCars() {
  return useQuery(GET_DASH_CARS, Api.getDashCars);
}

export function useGetCarOperations(id, from, to, enabled) {
  return useQuery(
    [GET_CAR_OPERATIONS, id, from, to],
    () => Api.getCarOperation(id, from, to),
    { enabled }
  );
}
