import React from "react";
import styled, { css } from "styled-components";

export default function Checkbox({ checked, ...p }) {
  return (
    <StyledCheckbox checked={checked} {...p}>
      <i className="icon icon-sm icon-nike"></i>
    </StyledCheckbox>
  );
}

const StyledCheckbox = styled.div`
  display: inline-block;
  width: 30px;
  height: 30px;
  background-color: var(--dark-5);
  border-radius: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s linear;

  i {
    background-color: var(--primary-dark-15);
  }

  &:hover {
    background-color: var(--primary-10);

    i {
      background-color: var(--primary);
    }
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: var(--primary);

      i {
        background-color: var(--light);
      }

      &:hover {
        background-color: var(--primary);

        i {
          background-color: var(--light);
        }
      }
    `}
`;
