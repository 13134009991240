/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import CarNumber from "../../../../components/car-number/CarNumber";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import chDown from "../../../../asset/img/chevron-down.svg";
import { msToNormTime } from "../../../../util/date";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { useGetCarOperations } from "../../../../store/car/hook";

export default function TableItem({
  car: { brand_model, car_id, comission, expense, gos_num, income, load, pay },
  fromToMs,
  closed,
  list,
  lastClosedTime,
}) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [isOpen, setIsOpen] = useState(false);
  const [innerId, setInnerId] = useState(null);

  // query
  let { data: selected_car, isLoading } = useGetCarOperations(
    car_id,
    fromToMs.from,
    closed ? lastClosedTime : fromToMs.to,
    isOpen
  );
  selected_car = selected_car?.data?.data;

  // watcher
  useEffect(() => {
    setIsOpen(false);
    setInnerId(null);
  }, [closed, list]);

  // method
  function changeInnerId(id) {
    setInnerId((p) => (p === id ? null : id));
  }

  function getInnerList() {
    let list = [];
    if (isLoading) return <Loader />;
    list = [...selected_car.operation_list];

    return list
      .sort((a, b) => b.operation_date - a.operation_date)
      .map((i, idx) => (
        <InnerItem
          item={i}
          key={idx}
          idx={idx}
          innerId={innerId}
          changeInnerId={changeInnerId}
        />
      ));
  }

  return (
    <StyledTableItem isOpen={isOpen}>
      <div className="sm__zagruzka">
        <i className="icon icon-circle-line"></i>
        <p>{load}%</p>
      </div>
      <main onClick={() => setIsOpen((p) => !p)}>
        <div className="auto__info">
          <h1>{brand_model}</h1>
          <CarNumber title={gos_num} size='sm' />
        </div>

        {width > 768 ? (
          <>
            <div className="doxod">
              <h1>Доход</h1>
              <p>
                <NumberFormat value={income.toFixed()} />
              </p>
            </div>
            <div className="rasxod">
              <h1>Расход</h1>
              <p>
                <NumberFormat value={expense.toFixed()} />
              </p>
            </div>
            <div className="komissiya">
              <h1>Комиссия</h1>
              <p>
                <NumberFormat value={comission.toFixed()} />
              </p>
            </div>
            <div className="kviplate">
              <h1>К выплате</h1>
              <p>
                <NumberFormat value={pay.toFixed()} />
              </p>
            </div>
            <div className="zagruzka">
              <h1>Загрузка</h1>
              <p>{load ?? 0}%</p>
            </div>
          </>
        ) : (
          <div className="cards__wrapper">
            <div className="doxod">
              <h1>Доход</h1>
              <p>
                <NumberFormat value={income.toFixed()} />
              </p>
            </div>
            <div className="rasxod">
              <h1>Расход</h1>
              <p>
                <NumberFormat value={expense.toFixed()} />
              </p>
            </div>
            <div className="komissiya">
              <h1>Комиссия</h1>
              <p>
                <NumberFormat value={comission.toFixed()} />
              </p>
            </div>
            <div className="kviplate">
              <h1>К выплате</h1>
              <p>
                <NumberFormat value={pay.toFixed()} />
              </p>
            </div>
            <div className="zagruzka">
              <h1>Загрузка</h1>
              <p>{load ?? 0}%</p>
            </div>
          </div>
        )}
      </main>
      <Collapse isOpened={isOpen}>
        {isOpen && <footer>{getInnerList()}</footer>}
      </Collapse>
    </StyledTableItem>
  );
}

const iconList = [
  "bar-line-chart",
  "file-check",
  "plus-circle",
  "minus-circle",
  "tool",
  "percent-cirle",
  "receipt-check",
];

function InnerItem({
  item: {
    operation_name,
    operation_sum,
    operation_type,
    operation_date,
    operation_details,
  },
  innerId,
  changeInnerId,
  idx,
}) {
  return (
    <section>
      <div className="main__sect" onClick={() => changeInnerId(idx)}>
        <div className="main__sect__left">
          <div
            className={cl("i__wrap", {
              danger: (operation_sum || 0) < 0,
            })}
          >
            <i
              className={
                "icon icon-" +
                (iconList.includes(operation_type) ? operation_type : "tool")
              }
            ></i>
          </div>
          <h1>{operation_name || "Title"}</h1>
        </div>
        <div className="main__sect__right">
          <h4>{operation_date && "От " + msToNormTime(operation_date, 2)}</h4>
          <p>
            <NumberFormat value={operation_sum} />
          </p>
          <img
            src={chDown}
            alt="chDown"
            style={{
              transform: innerId === idx ? "rotate(180deg)" : "rotate(0deg)",
              transition: ".2s linear",
            }}
          />
        </div>
      </div>
      <Collapse isOpened={innerId === idx}>
        {innerId === idx && (
          <div className="collapsed__sect">
            {operation_details?.map(
              ({ detail_name, detail_sum, detail_period }, ddx) => (
                <div className="collapsed__sect__item" key={ddx}>
                  <div className="collapsed__sect__item__left">
                    <div
                      className={cl("circle", {
                        danger: (detail_sum ?? 0) < 0,
                      })}
                    ></div>
                    <div>
                      <p>{detail_name || "Detail title"}</p>
                      {detail_period ? (
                        <h6>Кол-во дней: {detail_period || 0}</h6>
                      ) : null}
                    </div>
                  </div>
                  <div className="collapsed__sect__item__right">
                    <p>
                      <NumberFormat value={detail_sum} />
                    </p>
                  </div>
                </div>
              )
            )}
          </div>
        )}
      </Collapse>
    </section>
  );
}

const StyledTableItem = styled.li`
  border-radius: 20px;
  border: 3px solid transparent;
  transition: 0.2s linear;
  cursor: pointer;
  padding: 0 20px;

  .sm__zagruzka {
    display: none;
  }

  & > main {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    grid-template-columns: 2fr repeat(5, 1fr);
    gap: 20px;
    padding: 24px 10px;

    .auto__info {
      h1 {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }
    }

    .doxod,
    .rasxod,
    .komissiya,
    .kviplate,
    .zagruzka {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      border-radius: 14px;
      padding: 16px;
      white-space: nowrap;

      h1 {
        display: none;
      }

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }
    }

    .doxod,
    .komissiya {
      background-color: var(--dark-5);
    }

    .zagruzka {
      background-color: var(--success-20);
      color: var(--success-dark);

      &.warning {
        background-color: var(--warning-10);
        color: var(--warning-dark);
      }
    }
  }

  footer {
    position: relative;
    min-height: 80px;

    section {
      border-bottom: 1px solid var(--primary-dark-15);

      &.border__none {
        border-color: transparent;
      }

      .main__sect {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 17px 10px;

        &__left {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;

          .i__wrap {
            width: 52px;
            height: 52px;
            border-radius: 50%;
            background-color: var(--success-20);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;

            & > i {
              background-color: var(--success-dark);
            }

            &.danger {
              background-color: var(--danger-10);

              & > i {
                background-color: var(--danger-dark);
              }
            }
          }

          h1 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }

        &__right {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;

          h4 {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark-70);
          }

          p {
            font-size: 18px;
            font-weight: 600;
            line-height: 22px;
            letter-spacing: 0em;
            color: var(--dark);
          }

          & > i {
            background-color: var(--dark);
            transition: none;
            opacity: 0.5;
          }
        }
      }

      .collapsed__sect {
        cursor: default;
        &__item {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding: 20px 64px 20px 28px;

          &__left {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 50px;

            .circle {
              width: 10px;
              height: 10px;
              min-height: 10px;
              min-width: 10px;
              border-radius: 50%;
              background-color: var(--success);

              &.danger {
                background-color: var(--danger);
              }
            }

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: var(--dark-70);
              margin-bottom: 3px;
            }

            h6 {
              font-size: 13px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
          }

          &__right {
            p {
              font-size: 16px;
              font-weight: 500;
              line-height: 20px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
          }
        }
      }
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: var(--primary);
    `}

  @media (max-width : 1200px) {
    & > main {
      padding: 20px 0;
      grid-template-columns: 20% repeat(5, 15%);
      gap: 10px;
    }
  }

  @media (max-width: 992px) {
    padding: 0;
    border-radius: 0;
    position: relative;

    .sm__zagruzka {
      display: inline-block;
      position: absolute;
      top: 30px;
      right: 30px;
      background-color: var(--success-20);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 5px;
      padding: 6px 10px;
      border-radius: 8px;

      i {
        background-color: var(--success-dark);
        width: 14px;
        height: 14px;
      }

      p {
        color: var(--success-dark);
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
      }
    }

    & > main {
      padding: 20px 30px;
      display: -ms-grid;
      display: grid;
      grid-template-columns: auto;
      grid-template-areas:
        "a a a a "
        "b c d e";
      gap: 20px 10px;

      .auto__info {
        grid-area: a;
      }

      .doxod,
      .rasxod,
      .komissiya,
      .kviplate,
      .zagruzka {
        display: block;
        border: 1px solid var(--primary-dark-15);
        padding: 14px 16px;

        h1 {
          display: block;
          font-size: 12px;
          font-weight: 400;
          line-height: 15px;
          letter-spacing: 0em;
          margin-bottom: 5px;
        }
      }

      .doxod {
        grid-area: b;
        background-color: var(--success-20);
      }

      .rasxod {
        grid-area: c;
        background-color: var(--dark-10);
      }

      .komissiya {
        grid-area: d;
        background-color: var(--ligth);
      }

      .kviplate {
        grid-area: e;
        background-color: var(--ligth);
      }

      .zagruzka {
        display: none;
      }
    }

    footer {
      section {
        .main__sect {
          padding: 17px 30px;

          &__left {
            gap: 20px;
            .i__wrap {
              width: 45px;
              height: 45px;
            }
          }

          &__right {
            gap: 20px;
            p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }

        .collapsed__sect {
          &__item {
            padding: 20px 30px;
            gap: 20px;

            &__left {
              gap: 20px;
            }

            &__right {
              p {
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    & > main {
      grid-template-columns: 1fr;
      gap: 0;

      .cards__wrapper {
        margin-top: 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 10px;
        overflow-y: auto;
        width: calc(100vw - 50px);
        padding-right: 30px;

        &::-webkit-scrollbar {
          display: none;
        }
      }
    }

    footer {
      section {
        .main__sect {
          padding: 17px 30px;

          &__left {
            gap: 10px;
            flex-direction: column;
            align-items: flex-start;
          }

          &__right {
            display: -ms-grid;
            display: grid;
            grid-template-areas:
              "b c"
              "a c";
            gap: 10px 20px;

            h4 {
              grid-area: a;
            }
            p {
              grid-area: b;
            }
            i {
              grid-area: c;
            }
          }
        }

        .collapsed__sect {
          &__item {
            &__left {
              gap: 10px;
              p {
                font-size: 14px;
              }
            }

            &__right {
              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
`;
