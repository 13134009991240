import api from "../../service/axios";

class ParkApi {
  static getCar() {
    let url = `/getCars`;

    return api.get(url);
  }

  static getLoad(id) {
    let url = `/getCarLoad/${id}`;

    return api.get(url);
  }
}

export default ParkApi;
