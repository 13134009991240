import React from "react";
import styled from "styled-components";
import cl from "classnames";

//
import DocDownload from "../../doc-download/DocDownload";
import NumberFormat from "../../number-format/NumberFormat";
import { msToNormTime } from "../../../util/date";

export default function PayInfoModal({ operation }) {
  return (
    <StyledPayInfoModal>
      <main>
        <section>
          <div className="jumbotron__money">
            <h1
              className={cl("", {
                danger: operation.operation_sum < 0,
              })}
            >
              <NumberFormat value={operation.operation_sum} />
            </h1>
            {operation.operation_type !== "bar-line-chart" && (
              <p>{msToNormTime(operation.operation_date, 5)} </p>
            )}
          </div>
        </section>
        <section>
          <h1>Детали</h1>
          <ul>
            {operation.operation_details.map((i, idx) => (
              <li key={idx}>
                <section>
                  <div
                    className={cl("i__wrapper", {
                      danger: i.detail_sum < 0,
                    })}
                  >
                    <i
                      className={cl("icon", {
                        "icon-file-check": i.detail_type === "file-check",
                        "icon-tool": i.detail_type === "tool",
                        "icon-receipt-check": i.detail_type === "receipt-check",
                        "icon-plus-circle": i.detail_type === "plus-circle",
                        "icon-minus-circle": i.detail_type === "minus-circle",
                        "icon-percent-circle":
                          i.detail_type === "percent-circle",
                        "icon-bar-line-chart":
                          i.detail_type === "bar-line-chart",
                      })}
                    ></i>
                  </div>
                  <div>
                    <h1>{i.detail_name}</h1>
                    {i?.detail_period && (
                      <h4>Кол-во дней: {i.detail_period}</h4>
                    )}
                  </div>
                </section>
                <section>
                  <p>
                    <NumberFormat value={i.detail_sum} />
                  </p>
                </section>
              </li>
            ))}
          </ul>
        </section>
      </main>
      {operation.operation_details[0]?.details_file_link && (
        <footer>
          <DocDownload
            title="Акт реализации"
            text="Дата заключения 2 апреля 2022 г."
            file={operation.operation_details[0]?.details_file_link}
          />
        </footer>
      )}
    </StyledPayInfoModal>
  );
}

const StyledPayInfoModal = styled.div`
  min-height: calc(100vh - 152px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0 30px 30px;

  main {
    & > section {
      .jumbotron__money {
        text-align: center;
        background: var(--dark-3);
        border: 1px solid var(--primary-dark-15);
        border-radius: 30px;
        padding: 80px 20px 20px;
        margin-bottom: 60px;

        h1 {
          font-size: 30px;
          font-weight: 600;
          line-height: 37px;
          letter-spacing: 0em;
          color: var(--success-dark);
          margin-bottom: 42px;

          &.danger {
            color: var(--danger-dark);
          }
        }

        p {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }

      & > h1 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 30px;
        padding-left: 30px;
      }

      ul {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;

        li {
          border-radius: 20px;
          transition: 0.2s linear;
          padding: 20px 30px 20px 20px;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          cursor: pointer;

          section {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 20px;

            .i__wrapper {
              width: 52px;
              height: 52px;
              border-radius: 50%;
              background-color: var(--success-20);
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              -webkit-box-pack: center;
              -ms-flex-pack: center;
              justify-content: center;
              i {
                background-color: var(--success-dark);
              }

              &.danger {
                background-color: var(--danger-10);

                i {
                  background-color: var(--danger-dark);
                }
              }
            }

            h1 {
              font-size: 18px;
              font-weight: 500;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark);
              margin-bottom: 5px;
            }

            h4 {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }

            p {
              font-size: 18px;
              font-weight: 600;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark);
              white-space: nowrap;
            }
          }

          &:nth-child(2n) {
            background-color: var(--dark-3);
          }
        }
      }
    }
  }

  footer {
    margin-top: 80px;
  }

  @media (max-width: 768px) {
    min-height: calc(100vh - 54px);
    padding: 30px 0 0;

    main {
      & > section {
        .jumbotron__money {
          padding: 58px 0 20px;
          margin: 0 30px 60px;

          h1 {
            font-size: 28px;
            line-height: 34px;
            margin-bottom: 23px;
          }

          p {
            font-size: 12px;
            line-height: 15px;
          }
        }

        & > h1 {
          font-size: 18px;
          line-height: 24px;
          font-weight: 600;
          padding-left: 0;
          text-align: center;
        }

        ul {
          padding-bottom: 30px;

          li {
            border-radius: 0;
            padding: 20px 30px;

            section {
              gap: 15px;

              .i__wrapper {
                width: 40px;
                height: 40px;

                i {
                  width: 20px;
                  height: 20px;
                }
              }

              h1 {
                font-size: 16px;
                line-height: 20px;
                width: 180px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    footer {
      .doc__download {
        transform: translateX(0px);
      }
    }
  }
`;
