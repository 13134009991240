import React from "react";
import styled, { css } from "styled-components";

export default function AlertModal({ children, show }) {
  return (
    <StyledAlertModal show={show}>
      {children}
      <div className="top_romb"></div>
    </StyledAlertModal>
  );
}

const StyledAlertModal = styled.div`
  padding: 20px;
  background: var(--light);
  border: 1px solid var(--primary-dark-15);
  box-shadow: 0px 0px 20px var(--secondary-dark-10);
  border-radius: 20px;
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  color: var(--dark);
  position: absolute;
  bottom: -10px;
  right: -10px;
  transform: translateY(100%);
  width: max-content;
  opacity: 0;
  visibility: hidden;
  transition: 0.2s linear;
  z-index: 2;
  text-transform: none;
  max-width: 400px;
  cursor: text;

  .top_romb {
    position: absolute;
    border-width: 1px 1px 0px 0px;
    border-style: solid;
    border-color: var(--primary-dark-15);
    background: var(--light);
    top: 0;
    right: 24px;
    width: 10px;
    height: 10px;
    transform: translateY(-50%) rotate(-45deg);
  }

  ${({ show }) =>
    show &&
    css`
      opacity: 1;
      visibility: visible;
    `}

  @media (max-width : 768px) {
    max-width: 330px;
  }
`;
