import React, { useMemo, useState } from "react";
import styled from "styled-components";
import cl from "classnames";

//
import { months } from "../../../../util/lists";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

export default function MonthsModal({ from, to, year, setDate, onClose }) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [value, setValue] = useState({
    from,
    to,
    year,
  });

  // method
  function getTimeInMs(y, m) {
    return new Date(y, m).getTime();
  }

  function getClassName(m, y) {
    let { from, to } = value;
    from = getTimeInMs(from[0], from[1]);
    to = getTimeInMs(to[0], to[1]);
    let tempVal = getTimeInMs(y, m);
    if (tempVal > Date.now()) return "future";

    if (tempVal === from || tempVal === to) return "active";
    if (tempVal > from && tempVal < to) return "selected";
    return "";
  }

  function chooseMonth(m, y) {
    let { from, to } = value;
    from = getTimeInMs(from[0], from[1]);
    to = getTimeInMs(to[0], to[1]);
    let tempVal = getTimeInMs(y, m);
    const now = Date.now();
    if (tempVal > now) return;

    if (from === to) {
      if (from < tempVal) {
        setValue((p) => ({ ...p, to: [y, m] }));
      } else {
        setValue((p) => ({ ...p, from: [y, m] }));
      }
      return;
    }

    if (from === tempVal) {
      setValue((p) => ({ ...p, to: p.from }));
      return;
    }

    if (to === tempVal) {
      setValue((p) => ({ ...p, from: p.to }));
      return;
    }

    setValue((p) => ({ ...p, from: [y, m], to: [y, m] }));
  }

  function prevYear(e) {
    e.stopPropagation();
    if (value.year <= 2022) return;
    setValue((p) => ({ ...p, year: p.year - 1 }));
  }

  function nextYear(e) {
    e.stopPropagation();
    const curYear = new Date().getFullYear();
    if (value.year >= curYear) return;
    setValue((p) => ({ ...p, year: p.year + 1 }));
  }

  function handleSubmit(e) {
    e.stopPropagation();
    setDate((p) => ({
      ...p,
      type: "month",
      year: value.year,
      mFrom: value.from,
      mTo: value.to,
    }));
    onClose("");
  }

  function handleClose(e) {
    e.stopPropagation();
    onClose("");
  }

  function getNameMonth(name) {
    if (width > 768) return name;
    name = name.split("").slice(0, 3);
    return name;
  }

  const prevIsDisable = useMemo(() => {
    if (value.year <= 2022) return true;
    return false;
  }, [value.year]);

  const nextIsDisable = useMemo(() => {
    const curYear = new Date().getFullYear();
    return value.year === curYear;
  }, [value.year]);

  return (
    <StyledMonthsModal>
      <nav>
        <i
          className={cl("icon icon-chevron-right prev", {
            disabled: prevIsDisable,
          })}
          onClick={prevYear}
        ></i>
        <h1>{value.year}</h1>
        <i
          className={cl("icon icon-chevron-right next", {
            disabled: nextIsDisable,
          })}
          onClick={nextYear}
        ></i>
      </nav>
      <main>
        <ul>
          {months.map((i, idx) => (
            <li
              key={i}
              className={getClassName(idx, value.year)}
              onClick={() => chooseMonth(idx, value.year)}
            >
              {getNameMonth(i)}
            </li>
          ))}
        </ul>
      </main>
      <footer>
        <section className="icon__wrap" onClick={handleClose}>
          <i className="icon icon-close"></i>
        </section>
        <section className="icon__wrap" onClick={handleSubmit}>
          <i className="icon icon-check"></i>
        </section>
      </footer>
    </StyledMonthsModal>
  );
}

const StyledMonthsModal = styled.div`
  padding: 30px;

  & > nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
    margin-bottom: 30px;

    i {
      &.prev {
        transform: rotate(180deg);
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }

    h1 {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }

  & > main {
    ul {
      display: -ms-grid;
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 10px;

      li {
        width: 130px;
        text-align: center;
        padding: 14px 20px;
        background: var(--primary-dark-3);
        border-radius: 14px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        cursor: pointer;
        transition: 0.2s linear;

        &.active {
          background-color: var(--primary);
          color: var(--light);
        }

        &.selected {
          background-color: var(--primary-10);
          color: var(--primary);
        }

        &.future {
          opacity: 0.5;
          pointer-events: none;
        }
      }
    }
  }

  & > footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    gap: 20px;
    margin-top: 30px;

    & > section.icon__wrap {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      border: 1px solid var(--primary-dark-15);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      cursor: pointer;
    }
  }

  @media (max-width: 768px) {
    & > main {
      ul {
        grid-template-columns: repeat(3, 1fr);

        li {
          width: 100px;
        }
      }
    }
  }
  @media (max-width: 450px) {
    & > main {
      ul {
        grid-template-columns: repeat(3, 1fr);

        li {
          width: auto;
          padding: 10px 14px;
          font-size: 15px;
          font-weight: 400;
          line-height: 18px;
        }
      }
    }
  }
`;
