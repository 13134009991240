import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { v4 } from "uuid";

//
import Tab from "../../../../components/tab/Tab";
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import OperationCollapse from "../../../../components/operation-collapse/OperationCollapse";

const options = [
  {
    id: v4(),
    title: "Всё",
    value: "all",
  },
  {
    id: v4(),
    title: "Только доходы",
    value: "income",
  },
  {
    id: v4(),
    title: "Только расходы",
    value: "expense",
  },
];

export default function IncExpTable({ list, load }) {
  const [tab, setTab] = useState("all");
  const [arr, setArr] = useState([]);

  useEffect(() => {
    if (!list.length) return;
    let tempList = [...list];
    if (tab !== "all") {
      tempList = tempList.filter((i) => {
        if (tab === "income") return i.operation_sum >= 0;
        return i.operation_sum < 0;
      });
    }
    setArr([...tempList]);
  }, [list, tab]);

  return (
    <StyledIncExpTable load={load}>
      <Card mobile={1}>
        {load && <Loader />}
        <div className="iet__main">
          <nav>
            <h1>Операции</h1>
            <Tab options={options} value={tab} onChange={(t) => setTab(t)} />
          </nav>
          <div className="operation__list">
            {arr.map((i, idx) => (
              <OperationCollapse key={idx} operation={i} />
            ))}
          </div>
        </div>
      </Card>
    </StyledIncExpTable>
  );
}

const StyledIncExpTable = styled.div`
  ${({ load }) =>
    load &&
    css`
      overflow: hidden;
      height: 400px;
    `}

  .iet__main {
    padding: 30px;

    & > nav {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;

      h1 {
        font-size: 22px;
        font-weight: 500;
        line-height: 27px;
        letter-spacing: 0em;
        color: var(--dark);
        padding-left: 30px;
      }
    }

    .operation__list {
      margin-top: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      -ms-flex-direction: column;
      flex-direction: column;
      gap: 10px;
    }
  }

  @media (max-width: 768px) {
    .iet__main {
      padding: 30px 0;

      & > nav {
        padding: 0 0 0 30px;
        justify-content: center;

        h1 {
          display: none;
        }
      }
    }
  }
`;
