import React from "react";
import styled from "styled-components";

//
import haval from "../../../../asset/img/haval.png";
import CarNumber from "../../../../components/car-number/CarNumber";
import Badge from "../../../../components/badge/Badge";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import { statusTitle, statusColor, statusBg } from "../../../../util/lists";

export default function MobileCarCard({ car, load }) {
  return (
    <StyledMobileCarCard>
      {load && <Loader />}
      <div className="badge__wrapper">
        <Badge
          title={statusTitle[car?.status || "free"]}
          color={statusColor[car?.status || "free"]}
          bg={statusBg[car?.status || "free"]}
          p={[12, 16]}
          radius={12}
        />
      </div>
      <nav>
        <img src={car?.car_pic_url || haval} alt="carImg" width="227px" />
        <h1>{car?.brand_model || "Car model"}</h1>
        <CarNumber title={car?.gos_num} size='sm' />
      </nav>
      <ul className="info__list">
        <li>
          <h1>Пробег на 21.08.2022</h1>
          <p>
            <NumberFormat value={car?.card_info.run_km} suffix=" км" />
          </p>
        </li>
        <li>
          <h1>Двигатель</h1>
          <p>{car?.card_info.engine}</p>
        </li>
        <li>
          <h1>Привод</h1>
          <p>{car?.card_info.drive}</p>
        </li>
      </ul>
    </StyledMobileCarCard>
  );
}

const StyledMobileCarCard = styled.div`
  background: var(--light);
  box-shadow: 0px 0px 10px var(--primary-dark-5);
  border-radius: 0px 0px 30px 30px;
  padding: 60px 30px 30px;
  position: relative;

  .badge__wrapper {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 30px;
  }

  .info__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    overflow-y: scroll;
    gap: 10px;
    width: calc(100vw - 30px);
    padding-right: 30px;

    &::-webkit-scrollbar {
      display: none;
    }

    li {
      border: 1px solid var(--primary-dark-15);
      border-radius: 14px;
      padding: 14px 20px;
      white-space: nowrap;

      p {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      h1 {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-bottom: 5px;
      }
    }
  }
`;
