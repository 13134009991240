import { v4 } from "uuid";

export function getMarkModelList(list = []) {
  if (!list.length) return [];
  let tempList = {};

  list.forEach((i) => {
    let name = i.brand_model.split(" ");
    let mark = name.shift();
    name = name.join(" ");
    let obj = {
      name,
      isChecked: true,
      id: v4(),
    };
    if (!tempList[mark + "-temp0989"]?.includes(name)) {
      tempList[mark] = tempList[mark] ? [...tempList[mark], obj] : [obj];
      tempList[mark + "-temp0989"] = tempList[mark + "-temp0989"]
        ? [...tempList[mark + "-temp0989"], name]
        : [name];
    }
  });
  tempList = Object.entries(tempList)
    .filter((i) => !i[0].includes("-temp0989"))
    .map((i) => ({ id: v4(), mark: i[0], models: i[1] }));

  return tempList;
}

export function hidePhoneNumberGen(p) {
  if (!p) return "-";
  p = p.split("");

  return `+${p[0]} *** ${p[p.length - 4]}${p[p.length - 3]}-${p[p.length - 2]}${
    p[p.length - 1]
  }`;
}

export function formatPhoneNumber(p) {
  // +7 (999) 087 18-89
  if (!p) return "-";
  p = p.split("");
  p = ["+", ...p];
  p.splice(2, 0, "(");
  p.splice(2, 0, " ");
  p.splice(7, 0, ")");
  p.splice(8, 0, " ");
  p.splice(12, 0, " ");
  p.splice(15, 0, "-");
  return p.join("");
}
