import React, { useState } from "react";
import styled from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";

//
import carImg from "../../../../asset/img/sad.png";
import haval from "../../../../asset/img/haval.png";
import CarNumber from "../../../../components/car-number/CarNumber";
import Badge from "../../../../components/badge/Badge";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import Document from "../components/Document";
import Osago from "../components/Osago";
import JournalTo from "../components/JournalTo";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { msToNormTime } from "../../../../util/date";
import { statusBg, statusColor, statusTitle } from "../../../../util/lists";

//

export default function TableItem({
  selectedId,
  toggleSelectedId,
  car,
  addParams,
}) {
  const { width } = useWindowDimensions();
  const [tab, setTab] = useState(1);

  function getComponent() {
    let obj = {
      1: <Document sts={car?.car_docs?.sts} pts={car?.car_docs?.pts} />,
      2: <Osago osago={car?.car_docs?.osago} car={car} />,
      3: <JournalTo to={car?.maintenance_log} />,
    };
    return obj[tab];
  }

  return (
    <StyledTableItem>
      <Collapse
        isOpened={width > 768 ? !selectedId.includes(car.car_id) : true}
      >
        <section
          className="main__sect"
          onClick={() => {
            if (width < 769) {
              addParams("selected_car_id", car.car_id);
            } else {
              toggleSelectedId(car.car_id);
            }
          }}
        >
          <div className="main__sect__left">
            <div
              className={cl("car__img", {
                service: 0,
              })}
            >
              <img src={car?.car_pic_url || carImg} alt="car" width={140} />
              <div className="tool__i__wrap">
                <i className="icon icon-xs icon-tool"></i>
              </div>
            </div>
            <div className="car__info__wrap">
              <h1>{car?.brand_model || "Car model"}</h1>
              <div className="car__info__wrap__gos__badge">
                <CarNumber title={car?.gos_num} />
                <Badge
                  title={statusTitle[car?.status || "free"]}
                  bg={statusBg[car?.status || "free"]}
                  color={statusColor[car?.status || "free"]}
                  radius={6}
                  p={[4, 10]}
                  size="sm"
                />
              </div>
            </div>
          </div>
          <div className="main__sect__right">
            {width > 768 ? (
              <>
                <CarNumber title={car?.gos_num} minW={130} size="lg" />
                <Badge
                  title={statusTitle[car?.status || "free"]}
                  bg={statusBg[car?.status || "free"]}
                  color={statusColor[car?.status || "free"]}
                  p={[6, 16]}
                  minW={1}
                />
              </>
            ) : null}
            <i
              className="icon icon-chevron-right"
              style={{ transform: width > 768 ? "rotate(90deg)" : "" }}
            ></i>
          </div>
        </section>
      </Collapse>

      {width > 768 ? (
        <Collapse isOpened={selectedId.includes(car.car_id)}>
          <section className="inner__sect">
            <main className="inner__main">
              {width < 1201 && (
                <i
                  className="icon icon-chevron-right"
                  style={{
                    transform: "rotate(-90deg)",
                    background: "var(--dark)",
                  }}
                ></i>
              )}

              <div
                className="inner__main__top"
                onClick={() => toggleSelectedId(car.car_id)}
              >
                <div className="inner__main__info">
                  <div>
                    <img
                      src={car?.car_pic_url || haval}
                      alt="car"
                      width="265px"
                    />
                  </div>
                  <div>
                    <h1>{car?.brand_model || "Car model"}</h1>
                    <ul>
                      <li>
                        <h4>Двигатель</h4>
                        <p>{car?.card_info?.engine || "-"}</p>
                      </li>
                      <li>
                        <h4>Привод</h4>
                        <p>{car?.card_info?.drive || "-"}</p>
                      </li>
                      <li>
                        <h4>Год выпуска</h4>
                        <p>{car?.card_info?.year || "-"}</p>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="inner__main__gos">
                  <div>
                    <CarNumber title={car?.gos_num} size="lg" />
                    <Badge
                      p={[12, 16]}
                      radius={12}
                      title={statusTitle[car?.status || "free"]}
                      bg={statusBg[car?.status || "free"]}
                      color={statusColor[car?.status || "free"]}
                    />
                    {width > 1200 && (
                      <i
                        className="icon icon-chevron-right"
                        style={{
                          transform: "rotate(-90deg)",
                          background: "var(--dark)",
                        }}
                      ></i>
                    )}
                  </div>
                  <div>
                    <p>
                      Пробег на{" "}
                      {msToNormTime(
                        new Date(car?.card_info?.run_date).getTime() / 1000,
                        3
                      )}
                    </p>
                    <h1>
                      <NumberFormat
                        value={car?.card_info?.run_km}
                        suffix=" км"
                      />
                    </h1>
                  </div>
                </div>
              </div>

              <div className="inner__main__bottom">
                <ul className="inner__main__tabs">
                  <li
                    className={cl({
                      active: tab === 1,
                    })}
                    onClick={() => setTab(1)}
                  >
                    Документы
                  </li>
                  <li
                    className={cl({
                      active: tab === 2,
                    })}
                    onClick={() => setTab(2)}
                  >
                    ОСАГО
                  </li>
                  <li
                    className={cl({
                      active: tab === 3,
                    })}
                    onClick={() => setTab(3)}
                  >
                    Журнал ТО
                  </li>
                </ul>
                <ul className="inner__main__links">
                  <li onClick={() => addParams("download", car.car_id)}>
                    <p>Загрузка</p>
                    <i className="icon icon-sm icon-chevron-right"></i>
                  </li>
                  <li onClick={() => addParams("incexp", car.car_id)}>
                    <p>Доходы и расходы</p>
                    <i className="icon icon-sm icon-chevron-right"></i>
                  </li>
                </ul>
              </div>
            </main>
            <footer className="inner__footer">{getComponent()}</footer>
          </section>
        </Collapse>
      ) : null}
    </StyledTableItem>
  );
}

const StyledTableItem = styled.li`
  .main__sect {
    padding: 10px 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    cursor: pointer;

    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      .car__img {
        line-height: 0;
        position: relative;

        .tool__i__wrap {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: var(--danger);
          display: none;
          align-items: center;
          justify-content: center;

          i {
            background-color: var(--light);
          }
        }

        &.service {
          img {
            opacity: 0.7;
            mix-blend-mode: normal;
          }

          .tool__i__wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }
      }

      .car__info__wrap {
        h1 {
          font-size: 20px;
          font-weight: 500;
          line-height: 24px;
          letter-spacing: 0em;
          color: var(--dark);

          span {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            opacity: 0.7;
          }
        }

        &__gos__badge {
          display: none;
          align-items: center;
          margin-top: 5px;
          gap: 10px;
        }
      }
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      i {
        background-color: var(--dark);
      }
    }
  }

  .inner__sect {
    border: 3px solid #2c70dc;
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--bg);

    .inner__main {
      padding: 30px;
      background-color: var(--light);
      border-radius: 0 0 30px 30px;

      &__top {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 20px;
      }

      &__info {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 30px;
        padding-left: 30px;

        & > div {
          &:last-of-type {
            h1 {
              font-size: 20px;
              font-weight: 500;
              line-height: 24px;
              letter-spacing: 0em;
              color: var(--dark);

              span {
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0em;
                opacity: 0.7;
              }
            }

            ul {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              gap: 30px;
              margin-top: 30px;

              li {
                h4 {
                  font-size: 16px;
                  font-weight: 500;
                  line-height: 20px;
                  letter-spacing: 0em;
                  color: var(--dark);
                  margin-bottom: 5px;
                }

                p {
                  font-size: 16px;
                  font-weight: 400;
                  line-height: 20px;
                  letter-spacing: 0em;
                  color: var(--dark-70);
                }
              }
            }
          }
        }
      }

      &__gos {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
        align-items: flex-end;

        & > div {
          &:first-of-type {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 30px;
          }

          &:last-of-type {
            border: 1px solid var(--primary-dark-15);
            border-radius: 20px;
            padding: 18px 36px;
            text-align: center;

            p {
              font-size: 14px;
              font-weight: 400;
              line-height: 17px;
              letter-spacing: 0em;
              margin-bottom: 5px;
              color: var(--dark-70);
            }

            h1 {
              font-size: 20px;
              font-weight: 600;
              line-height: 24px;
              letter-spacing: 0em;
              color: var(--dark);
            }
          }
        }
      }

      &__bottom {
        margin-top: 30px;
        padding-top: 30px;
        border-top: 1px solid var(--primary-dark-15);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        gap: 20px;
        flex-wrap: wrap;
      }

      &__tabs {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        gap: 10px;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        li {
          padding: 14px 24px;
          border-radius: 14px;
          background-color: transparent;
          transition: 0.2s linear;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          cursor: pointer;

          &.active {
            background: #2c70dc;
            color: var(--light);
          }
        }
      }

      &__links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 10px;

        li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 10px;
          padding: 14px 24px;
          cursor: pointer;

          i {
            background-color: var(--dark);
          }

          p {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }
      }
    }

    .inner__footer {
      background-color: var(--bg);
      padding: 30px;
    }
  }

  @media (max-width: 1200px) {
    .inner__sect {
      .inner__main {
        position: relative;

        & > i.icon-chevron-right {
          position: absolute;
          top: 30px;
          right: 30px;
        }

        &__top {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
          -ms-flex-direction: column;
          flex-direction: column;
          gap: 30px;
        }

        &__info {
          gap: 20px;
          padding-left: 0;

          & > div {
            &:first-of-type {
              img {
                width: 200px;
              }
            }

            &:last-of-type {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              gap: 20px;

              ul {
                margin-top: 0;
                gap: 20px;
                align-items: flex-start;
              }
            }
          }
        }

        &__gos {
          flex-direction: row-reverse;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .inner__sect {
      .inner__main {
        &__info {
          & > div {
            &:last-of-type {
              gap: 10px;
              flex-direction: column;
            }
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    .main__sect {
      padding: 20px 30px;

      &__left {
        gap: 15px;

        .car__img {
          img {
            width: 80px;
          }
        }

        .car__info__wrap {
          h1 {
            font-size: 16px;
            line-height: 20px;
          }

          &__gos__badge {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
          }
        }
      }
    }
  }
`;
