import api from "../../service/axios";

class AnalyticApi {
  static getChartData(from, to) {
    let url = `/getGraphData/${from}/${to}`;

    return api.get(url);
  }

  static getCarTableData(from, to) {
    let url = `/getAnalTable/${from}/${to}`;

    return api.get(url);
  }
}

export default AnalyticApi;
