import api from "../../service/axios";

class NotifApi {
  static getNotifications() {
    let url = "/getNotifications";
    return api.get(url)
  }

  static putNotif(body) {
    let url = `/putNotification`
    return api.put(url, body)
  }

  static postNotif(body) {
    let url = `/postNotification`
    return api.post(url, body)
  }
}

export default NotifApi;
