import React, { useState, useEffect } from "react";
import styled from "styled-components";

//
import HeaderModal from "./HeaderModal";
import Button from "../../../../components/button/Button";
import Checkbox from "../../../../components/checkbox/Checkbox";
import Loader from "../../../../components/loader/Loader";

export default function DissmisModal({
  setShow,
  show,
  handlePostReport,
  isLoading,
  createdAt,
  ...props
}) {
  const [checked, setChecked] = useState(false);
  const [wrap, setWrap] = useState(false);


  const reportDate =new Intl.DateTimeFormat('ru',{ month: 'long', year: 'numeric' }).format(new Date(createdAt*1000));

  useEffect(() => {
    setWrap(show);
  }, [show]);

  return (
    <StyleDissmissModal wrap={wrap ? 1 : 0} {...props}>
      <HeaderModal setShow={setShow} marginBot="60px" />

      <section>
        <Checkbox checked={checked} onClick={() => setChecked((p) => !p)} />
        <p>Я ознакомился(лась) и потверждаю отчет {reportDate}</p>
      </section>

      <div className="btn__submit">
        <Button
          color="primary"
          p={[16, 30]}
          textUpperCase={1}
          disabled={!checked}
          onClick={() => handlePostReport("approved", "")}
        >
          {isLoading && (
            <Loader color="light" bg="primary" w={28} h={28} size={3} />
          )}
          Согласовать
        </Button>
      </div>
    </StyleDissmissModal>
  );
}

const StyleDissmissModal = styled.div`
  width: 478px;
  border-radius: 30px;
  background-color: var(--light);
  box-shadow: 0px 0px 20px var(--primary-dark-40);
  padding: 43px 24px 30px 24px;
  transition: 0.25s linear;
  transform: translateY(600px);
  transform: ${(props) => props.wrap && "translateY(0px)"};

  & > section {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    padding: 0 0 0 20px;
    margin-bottom: 60px;

    .check {
      width: 30px;
      height: 30px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      padding: 5px 5px;
      border-radius: 10px;
      background-color: var(--dark-5);
      transition: 0.125s linear;
      cursor: pointer;
      & > i {
        background-color: var(--primary-dark-15);
      }
    }
    .active__checked {
      background-color: var(--primary);
      & > i {
        background-color: var(--light);
      }
    }
    & > p {
      max-width: 300px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }

  .btn__submit {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }

  @media (max-width: 768px) {
    padding: 30px 24px 30px 24px;

    & > section {
      & > p {
        font-size: 14px;
      }
    }
    .btn__submit {
      justify-content: center;
    }
  }
`;
