import React from "react";
import styled from "styled-components";

export default function EmptyText({
  p = [150, 50],
  text = "Ничего не найдено",
}) {
  p = p.map((i) => i + "px").join(" ");
  return <StyledEmptyText p={p}>{text}</StyledEmptyText>;
}

const StyledEmptyText = styled.p`
  padding: ${({ p }) => p};
  font-size: 22px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--dark-50);
`;
