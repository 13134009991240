/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";

//
import Card from "../../../../components/card/Card";
import Switch from "../../../../components/switch/Switch";
import Loader from "../../../../components/loader/Loader";
import FilterByCarModel from "../../../../components/filter-car/FilterByCarModel";
import AlertModal from "../../../../components/alert-modal/AlertModal";
import TableItem from "../components/TableItem";
import Search from "../../../../components/search/Search";
import Input from "../../../../components/input/Input";
import EmptyText from "../../../../components/epmty-text/EmptyText";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

export default function Detailing({
  closed,
  setClosed,
  loading,
  fromToMs,
  cars,
  filterList,
  setFilterList,
  filter,
  search,
  setSearch,
  lastClosedTime,
}) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [alert, setAlert] = useState(false);
  const [sort, setSort] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  // method
  function changeSort(name) {
    if (!sort || sort.name !== name) {
      setSort({ name, type: "up" });
    } else {
      if (name === sort.name) {
        if (sort.type === "up") {
          setSort({ name, type: "down" });
        } else {
          setSort(null);
        }
      }
    }
  }

  let carList = useMemo(() => {
    let arr = [...cars];
    arr = arr.filter((i) => {
      if (search) {
        search = search.toLowerCase();
        let ex =
          i.brand_model.toLowerCase().includes(search) ||
          i.gos_num.toLowerCase().includes(search);
        return filter.includes(i.brand_model) && ex;
      }
      return filter.includes(i.brand_model);
    });

    if (sort) {
      arr = arr.sort((a, b) => {
        if (sort.type === "up") {
          return b[sort.name] - a[sort.name];
        }
        return a[sort.name] - b[sort.name];
      });
    }
    return arr;
  }, [cars, search, sort, filter]);

  return (
    <StyledDetailing loading={loading ? 1 : 0} isOpen={isOpen}>
      <Card bgHide={1}>
        {width > 992 && loading && <Loader />}
        <main className="det__content">
          <section className="det__header">
            <div className="mobile__top__wrap">
              {isOpen ? (
                <div>
                  <Input
                    fill={1}
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                  />
                </div>
              ) : (
                <h1 className="det__title">Детализация по автомобилям</h1>
              )}
              {width < 769 && (
                <Search
                  isOpen={isOpen}
                  changeSearchIsOpen={(v) => {
                    setSearch("");
                    setIsOpen(v);
                  }}
                />
              )}
            </div>
            {width < 993 && (
              <FilterByCarModel marks={filterList} setMarks={setFilterList} />
            )}
            <div className="det__switch">
              <p>Только закрытые акты</p>
              <Switch value={closed} onClick={() => setClosed((p) => !p)} />
              <div
                className={cl("danger__icon__wrapper", {
                  show: closed,
                })}
                onMouseOver={() => setAlert(true)}
                onMouseOut={() => setAlert(false)}
              >
                <i className="icon icon-alert-circle"></i>
                <AlertModal show={alert}>
                  Теперь учитываются реализации за текущий <br /> период,
                  которые ещё не закрыты актами.
                  <br />
                  <br />
                  Суммы, которые будут выплачены после закрытия <br /> периода
                  могут отличаться, просим иметь это в виду.
                </AlertModal>
              </div>
            </div>
          </section>
          <section className="det__nav">
            <ul className="table__head">
              <li>
                <FilterByCarModel marks={filterList} setMarks={setFilterList} />
              </li>
              <li onClick={() => changeSort("income")}>
                <p>Доход</p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== "income",
                    "icon-chevron-right rotate--90 active":
                      sort?.name === "income" && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === "income" && sort?.type === "down",
                  })}
                ></i>
              </li>
              <li onClick={() => changeSort("expense")}>
                <p>Расход</p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== "expense",
                    "icon-chevron-right rotate--90 active":
                      sort?.name === "expense" && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === "expense" && sort?.type === "down",
                  })}
                ></i>
              </li>
              <li onClick={() => changeSort("comission")}>
                <p>Комиссия</p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== "comission",
                    "icon-chevron-right rotate--90 active":
                      sort?.name === "comission" && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === "comission" && sort?.type === "down",
                  })}
                ></i>
              </li>
              <li onClick={() => changeSort("pay")}>
                <p>К выплате</p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== "pay",
                    "icon-chevron-right rotate--90 active":
                      sort?.name === "pay" && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === "pay" && sort?.type === "down",
                  })}
                ></i>
              </li>
              <li onClick={() => changeSort("load")}>
                <p>загрузка</p>
                <i
                  className={cl("icon icon-xs", {
                    "icon-chevron-selector": sort?.name !== "load",
                    "icon-chevron-right rotate--90 active":
                      sort?.name === "load" && sort?.type === "up",
                    "icon-chevron-right rotate-90 active":
                      sort?.name === "load" && sort?.type === "down",
                  })}
                ></i>
              </li>
            </ul>
            <ul className="table__body">
              {width < 993 && loading && <Loader />}
              {!carList.length ? (
                <EmptyText />
              ) : (
                carList.map((i) => (
                  <TableItem
                    key={i.car_id}
                    car={i}
                    fromToMs={fromToMs}
                    closed={closed}
                    list={cars}
                    lastClosedTime={lastClosedTime}
                  />
                ))
              )}
            </ul>
          </section>
          <section className="det__table"></section>
        </main>
      </Card>
    </StyledDetailing>
  );
}

const StyledDetailing = styled.section`
  margin-top: 20px;

  .det {
    &__content {
      padding: 30px;
      min-height: 400px;
    }

    &__header {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 0 20px;
      margin-bottom: 40px;
    }

    &__title {
      font-size: 22px;
      font-weight: 500;
      line-height: 27px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    &__switch {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;
      min-height: 40px;

      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      .danger__icon__wrapper {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--danger-20);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        cursor: pointer;
        position: relative;
        visibility: hidden;
        opacity: 0;
        transition: 0.2s linear;

        i {
          background-color: var(--danger);
        }

        &.show {
          visibility: visible;
          opacity: 1;
        }
      }
    }

    &__nav {
      .table__head {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 2fr repeat(5, 1fr);
        gap: 20px;
        padding: 0 20px;

        & > li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: center;
          -ms-flex-pack: center;
          justify-content: center;
          &:nth-child(1) {
            display: unset;
          }

          p {
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            text-transform: uppercase;
            color: var(--dark);
            cursor: pointer;
            padding-right: 10px;
          }

          & > i {
            background-color: var(--dark-70);

            &.active {
              background-color: var(--dark);
            }
          }
        }
      }

      .table__body {
        margin-top: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 10px;
        position: relative;

        & > li {
          &:nth-child(2n) {
            background-color: var(--dark-3);
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .det {
      &__nav {
        .table__head {
          grid-template-columns: 20% repeat(5, 15%);
          gap: 10px;
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .det {
      &__content {
        padding: 0px;
      }

      &__header {
        display: -ms-grid;
        display: grid;
        grid-template-columns: 1fr;
        padding: 30px;
        margin-bottom: 20px;
        background-color: var(--light);
        box-shadow: 0px 0px 10px var(--primary-dark-5);
        border-radius: 30px 30px 20px 20px;
        gap: 20px;

        .mobile__top__wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          min-height: 54px;
          gap: 10px;

          ${({ isOpen }) =>
            isOpen &&
            css`
              display: -ms-grid;
              display: grid;
              grid-template-columns: 1fr 48px;
              gap: 20px;

              input {
                min-width: auto;
                width: 100%;
              }
            `}
        }
      }

      &__title {
        font-size: 16px;
        line-height: 20px;
        font-weight: 600;
      }

      &__switch {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
      }

      &__nav {
        .table__head {
          display: none;
        }

        .table__body {
          margin-top: 0;
          background: var(--light);
          box-shadow: 0px 0px 10px var(--primary-dark-5);
          border-radius: 30px;
          gap: 0;
          padding: 30px 0;
          min-height: 250px;
        }
      }
    }
  }
`;
