import React, { useState } from "react";

//
import Input from "../input/Input";

const phoneValues = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];

export default function InputPhone({ value, setValue, onResetErr, ...props }) {
  const [showValue, setShowValue] = useState("");

  function changePhone(e) {
    // +7 (999) 087 18 89
    let entertype = e.nativeEvent.inputType;
    let val = e.target.value;
    val = filterNumber(val);
    setValue(val);
    onResetErr();
    if (val.length > 11) return;
    if (entertype === "insertText" && val.length === 1) {
      if (val === "7" || val === '8') {
        val = "";
      }
      val = "7" + val;
    }

    if (val) {
      val = getFormattedNum(val);
    }
    setShowValue(val);
  }

  function filterNumber(num) {
    return num
      .split("")
      .filter((i) => phoneValues.includes(i))
      .join("");
  }

  function getFormattedNum(num) {
    // +7 (999) 087 18 89
    let str = "+";
    for (let i = 0; i < num.length; i++) {
      if (i === 1) {
        str += " (" + num[i];
        continue;
      }
      if (i === 4) {
        str += ") " + num[i];
        continue;
      }
      if (i === 7) {
        str += " " + num[i];
        continue;
      }
      if (i === 9) {
        str += " " + num[i];
        continue;
      }

      str += num[i];
    }

    return str;
  }

  return <Input value={showValue} onChange={changePhone} {...props} />;
}
