import React from "react";
import styled from "styled-components";

//
import ProgressBar from "../../preogress-bar/ProgressBar";
import CarNumber from "../../car-number/CarNumber";
import Badge from "../../badge/Badge";
import NumberFormat from "../../number-format/NumberFormat";
import { dash_cars_colors } from "../../../util/lists";

export default function CarTable({
  hoverId,
  changeHoverId,
  getIncomeAmount,
  showType,
  changeModal,
  cars,
  changeCurCar,
  tab,
  analytic,
}) {
  function getClassName(id) {
    if (!hoverId) return "";
    if (hoverId === id) return "active";
    return "disabled";
  }

  let alfa = null;

  return (
    <StyledCarTable>
      <ul>
        {cars.map((i, idx) => (
          <li
            key={i.car_id}
            className={getClassName(i.car_id)}
            onMouseOver={() => {
              changeHoverId(i.car_id);
              if (alfa) {
                clearTimeout(alfa);
              }
            }}
            onMouseOut={() => {
              alfa = setTimeout(() => {
                changeHoverId(null);
              }, 500);
            }}
            onClick={() => {
              changeModal(true);
              changeCurCar(i);
            }}
          >
            <section className="li__left">
              <div>
                <ProgressBar
                  vertical={1}
                  value={100}
                  bg={dash_cars_colors[idx]}
                  size="lg"
                />
              </div>
              <div>
                <img src={i.car_pic_url} alt="car__img" width={125} />
              </div>
              <div>
                <h1>{i.brand_model}</h1>
                <div className="car__badge__wrap">
                  <CarNumber title={i.gos_num} size='sm' />
                  {!analytic && (
                    <div>
                      <Badge
                        icon="icon-circle-line"
                        title={i.load + "%"}
                        p={[4, 6]}
                        size="sm"
                        bg={i.load < 60 ? "warning-10" : "success-15"}
                        color={i.load < 60 ? "warning-dark" : "success-dark"}
                      />
                    </div>
                  )}
                </div>
              </div>
            </section>
            <section className="li__right">
              {tab !== "load" && (
                <div className="income__text">
                  {showType === "%" ? (
                    <>
                      {(
                        ((i[analytic ? tab : "profit"] -
                          (tab === "income" && analytic
                            ? i["comission"] || 0
                            : 0) || 0) /
                          getIncomeAmount) *
                        100
                      ).toFixed(1)}{" "}
                      %
                    </>
                  ) : (
                    <>
                      <NumberFormat
                        value={(
                          i[analytic ? tab : "profit"] -
                            (tab === "income" && analytic
                              ? i["comission"] || 0
                              : 0) || 0
                        ).toFixed()}
                        suffix={tab === "load" ? "%" : "₽"}
                      />
                    </>
                  )}
                </div>
              )}
              {((analytic && tab === "load") || !analytic) && (
                <div>
                  <Badge
                    icon="icon-circle-line"
                    title={i.load + "%"}
                    p={[10, 14]}
                    bg={i.load < 60 ? "warning-10" : "success-15"}
                    color={i.load < 60 ? "warning-dark" : "success-dark"}
                  />
                </div>
              )}
            </section>
          </li>
        ))}
      </ul>
    </StyledCarTable>
  );
}

const StyledCarTable = styled.section`
  & > ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;

    li {
      padding: 15px 20px;
      border-radius: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      cursor: pointer;
      border: 2px solid transparent;
      transition: 0.2s linear;

      section {
        &:nth-child(1) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;

          h1 {
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          .car__badge__wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 10px;

            & > div {
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }

        &:nth-child(2) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;
          
          .income__text {
            font-size: 20px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }
      }

      &:nth-child(2n) {
        background-color: var(--dark-2);
      }

      &.active {
        border-color: var(--primary-dark-15);
      }

      &.disabled {
        opacity: 0.3;
      }
    }
  }

  @media (max-width: 768px) {
    & > ul {
      gap: 5px;

      li {
        border-radius: 0;
        padding: 15px 30px;

        section {
          &:nth-child(1) {
            gap: 20px;

            & > div {
              &:nth-child(2) {
                display: none;
              }
            }

            .car__badge__wrap {
              & > div {
                &:nth-child(2) {
                  display: block;
                }
              }
            }
          }

          &:nth-child(2) {
            .income__text {
              font-size: 18px;
              line-height: 22px;
            }

            & > div {
              &:nth-child(2) {
                display: none;
              }
            }
          }
        }

        &:nth-child(2n) {
          background-color: var(--dark-2);
        }
      }
    }
  }
`;
