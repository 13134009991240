import { useQuery } from "react-query";

//
import Api from "./api";
import { GET_PARK_CARS, GET_PARK_CAR_LOAD } from "./type";

export function useGetParkCars() {
  return useQuery(GET_PARK_CARS, Api.getCar);
}

export function useGetParkCarLoad(id) {
  return useQuery([GET_PARK_CAR_LOAD, id], () => Api.getLoad(id), {
    enabled: !!id,
  });
}
