import React from "react";
import styled from "styled-components";

//
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import PayCard from "../../../../components/pay-card/PayCard";
import BackTitleNum from "../components/BackTitleNum";
import IncExpTable from "../components/IncExpTable";
import AlertIcon from "../../../../components/alert-icon/AlertIcon";
import { useGetCarOperations } from "../../../../store/car/hook";
import { getFromTo } from "../../../../util/date";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

export default function IncomeExpense({ car, removeParam, params }) {
  const { width } = useWindowDimensions();
  const { from, to } = getFromTo(1);
  const car_id = params.get(width > 768 ? "incexp" : "selected_car_id");
  let { data: operation, isLoading: load } = useGetCarOperations(
    car_id,
    from,
    to,
    !!car_id
  );
  operation = operation?.data?.data;

  function goBack() {
    removeParam("incexp");
  }

  return (
    <StyledIncExp>
      <BackTitleNum
        gos={car?.gos_num}
        model={car?.brand_model}
        onBack={goBack}
      />

      <section className="inc__exp">
        <Card mobile={1}>
          {load && <Loader />}
          <div className="inc__exp__main">
            <div className="income__paycard__wrap">
              <PayCard
                icon="icon-currency-ruble"
                text="Доход"
                title={operation?.income || 0}
                park={1}
              />
              <div className="alert__icon__wrap">
                <AlertIcon
                  text="Весь доход по авто за вычетом КВ по договору"
                  color="green"
                />
              </div>
            </div>
            <div>
              <PayCard
                icon="icon-currency-ruble"
                text="Расходы"
                title={operation?.expense || 0}
                park={1}
                color="danger"
              />
            </div>
          </div>
        </Card>
      </section>

      <IncExpTable list={operation?.operation_list || []} load={load} />
    </StyledIncExp>
  );
}

const StyledIncExp = styled.div`
  & > header {
  }

  .inc__exp {
    margin-bottom: 20px;

    &__main {
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 30px;
      padding: 30px;

      .income__paycard__wrap {
        position: relative;

        .alert__icon__wrap {
          display: inline-block;
          position: absolute;
          top: 7px;
          right: 7px;
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .inc__exp {
      &__main {
        grid-template-columns: 1fr;
      }
    }
  }
`;
