import React from "react";
import styled, { css } from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";

export default function FilterModal({
  children,
  isOpen,
  onClose,
  month,
  year,
}) {
  if (!isOpen) return <></>;

  return (
    <OutsideClickHandler onOutsideClick={onClose}>
      <StyledFilterModal month={month} year={year}>
        {children}
        <div className="romb__rect"></div>
      </StyledFilterModal>
    </OutsideClickHandler>
  );
}

const StyledFilterModal = styled.div`
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translate(-50%, 100%);
  background: var(--light);
  border: 1px solid var(--primary-dark-15);
  box-shadow: 0px 0px 20px var(--dark-10);
  border-radius: 30px;
  cursor: default;
  z-index: 10;

  .romb__rect {
    position: absolute;
    left: 50%;
    top: -3px;
    transform: rotate(45deg) translate(-50%, 0%);
    width: 12px;
    height: 12px;
    background: var(--light);
    border-width: 1px 0px 0px 1px;
    border-style: solid;
    border-color: var(--primary-dark-15);
  }

  @media (max-width: 1200px) {
    ${({ year }) =>
      year &&
      css`
        left: auto;
        right: 0;
        transform: translate(0%, 100%);

        .romb__rect {
          left: auto;
          right: 20%;
        }
      `}
  }

  @media (max-width: 500px) {
    ${({ year, month }) =>
      (year || month) &&
      css`
        width: 100%;
        .romb__rect {
          left: 50%;
          right: auto;
        }
      `}
  }
`;
