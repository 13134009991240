import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useMutation, useQueryClient } from "react-query";

//
import contact from "../../../asset/img/contact.svg";
import organization from "../../../asset/img/organization.svg";
import location from "../../../asset/img/location.svg";
import Avatar from "../../avatar/Avatar";
import Button from "../../button/Button";
import UserApi from "../../../store/user/api";
import Loader from "../../../components/loader/Loader";
import IconWrapper from "../../../components/icon-wrapper/IconWrapper";
import { formatPhoneNumber } from "../../../util/formatters";
import { GET_USER_INFO } from "../../../store/user/type";
import { useWindowDimensions } from "../../../hooks/useWindowDimensions";

export default function UserInfoModal({
  user: {
    name = "User User User",
    avatar,
    phone,
    email,
    org = ["-"],
    address = "-",
  },
  onLogOut,
  logout_loading,
}) {
  // helper
  const { width } = useWindowDimensions();

  // state
  const [img_url, setImgUrl] = useState("");

  // query
  const queryClient = useQueryClient();
  const mutation = useMutation(() => UserApi.postAvatar(img_url), {
    onSuccess,
  });

  // watcher
  useEffect(() => {
    if (img_url) {
      mutation.mutate();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [img_url]);

  // method
  function onSuccess() {
    queryClient.setQueryData(GET_USER_INFO, (user_info) => {
      let tempObj = { ...user_info.data.data, avatar: img_url };

      return {
        ...user_info,
        data: {
          data: tempObj,
        },
      };
    });
  }

  function changeImgUrl(url) {
    setImgUrl(url);
  }

  return (
    <StyledUserInfoModal>
      <main>
        <section>
          <div className="user__server__infos">
            <Avatar avatar={avatar} changeImgUrl={changeImgUrl} />
            <h1>
              <span>{org[0].split(" ")[0]}</span> <br /> {org[0].split(" ")[1]}{" "}
              {org[0].split(" ")[2]}
            </h1>
          </div>
          <Button color="danger" onClick={onLogOut}>
            {logout_loading && (
              <Loader
                radius={width > 768 ? 16 : 24}
                bg="danger"
                color="light"
                w={24}
                h={24}
                size={3}
              />
            )}
            {width > 768 ? "Выйти" : ""}
            <i
              className="icon icon-sm icon-logout"
              style={{ background: "var(--light)" }}
            ></i>
          </Button>
        </section>
        <section>
          <ul className="info__list">
            <li>
              <img src={contact} alt="contact" />
              <div>
                <h1>Контакты</h1>
                <p>
                  {formatPhoneNumber(phone)} <br /> {email || "-"}
                </p>
              </div>
            </li>
            <li>
              <img src={organization} alt="organization" />
              <div>
                <h1>Организация</h1>
                <ul className="organition__list">
                  {org?.map((i) => (
                    <li key={i}>{i}</li>
                  ))}
                </ul>
              </div>
            </li>
            <li>
              <img src={location} alt="location" />
              <div>
                <h1>Контакты</h1>
                <p>{address}</p>
              </div>
            </li>
          </ul>
        </section>
      </main>

      <footer>
        <div className="footer__top">
          <IconWrapper
            icon="pencil"
            w={60}
            h={60}
            bg="primary-10"
            color="primary"
            rounded={16}
          />
          <div>
            <h1>Внести изменения</h1>
            <p>
              Для изменения личных данных просим связаться с вашим менеджером
            </p>
          </div>
        </div>
        <div className="footer__bottom">
          <div className="phone">
            <i className="icon icon-sm icon-phone"></i>
            <p>{formatPhoneNumber(phone)}</p>
          </div>
          <div className="gmail">
            <i className="icon icon-sm icon-gmail"></i>
            <p>{email}</p>
          </div>
        </div>
      </footer>
    </StyledUserInfoModal>
  );
}

const StyledUserInfoModal = styled.div`
  padding: 30px 60px 60px;
  min-height: calc(100vh - 112px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;

  section {
    &:nth-child(1) {
      margin-bottom: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      background-color: var(--bg);
      width: calc(100% + 60px);
      transform: translateX(-30px);
      padding: 20px;
      border: 1px solid var(--primary-dark-15);
      border-radius: 30px;

      .user__server__infos {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 30px;
      }

      h1 {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;

        span {
          font-size: 22px;
          font-weight: 600;
          line-height: 27px;
        }

        color: var(--dark);
      }
    }

    &:nth-child(2) {
      .info__list {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 70px;

        & > li {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: start;
          -ms-flex-align: start;
          align-items: flex-start;
          gap: 20px;

          h1 {
            font-size: 22px;
            font-weight: 600;
            line-height: 27px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          p {
            font-size: 18px;
            font-weight: 400;
            line-height: 22px;
            letter-spacing: 0em;
            color: var(--dark-70);
          }

          .organition__list {
            li {
              font-size: 18px;
              font-weight: 400;
              line-height: 22px;
              letter-spacing: 0em;
              color: var(--dark-70);
            }
          }
        }
      }
    }
  }

  footer {
    width: calc(100% + 60px);
    transform: translateX(-30px);
    border: 1px solid var(--primary-dark-15);
    border-radius: 20px;
    margin-top: 60px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;

    .footer__top {
      display: flex;
      align-items: center;
      gap: 20px;

      h1 {
        font-size: 18px;
        font-weight: 600;
        line-height: 22px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 5px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
      }
    }

    .footer__bottom {
      display: flex;
      align-items: center;
      gap: 20px;
      margin-left: 80px;

      .phone,
      .gmail {
        background: var(--dark-5);
        border-radius: 17px;
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark);

        i {
          background-color: var(--dark);
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0 30px;
    min-height: calc(100vh - 90px);

    section {
      &:nth-child(1) {
        border: none;
        border-radius: 0;
        padding: 20px 30px;

        button {
          width: 48px;
          height: 48px;
          padding: 0;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;

          i {
            width: 24px;
            height: 24px;
          }
        }
      }

      &:nth-child(2) {
        ul {
          gap: 60px;

          li {
            h1 {
              font-size: 20px;
              line-height: 24px;
            }

            p {
              font-size: 16px;
              line-height: 20px;
            }
          }
        }
      }
    }

    footer {
      background-color: var(--bg);
      border: 0;
      padding: 20px 30px;

      .footer__bottom {
        flex-direction: column;
        align-items: flex-start;
        gap: 5px;
      }
    }
  }
`;
