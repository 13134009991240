import React from "react";
import styled from "styled-components";
import { v4 } from "uuid";

//
import docImg from "../../../../asset/img/doc-icon.svg";
import osagoImg from "../../../../asset/img/osago-icon.svg";
import toImg from "../../../../asset/img/garage-icon.svg";
import downloadImg from "../../../../asset/img/download-icon.svg";
import incexpImg from "../../../../asset/img/inc-exp-icon.svg";

const list = [
  { id: v4(), title: "Документы", img: docImg, value: "doc" },
  { id: v4(), title: "ОСАГО", img: osagoImg, value: "osago" },
  { id: v4(), title: "Журнал ТО", img: toImg, value: "journal" },
  { id: v4(), title: "Загрузка", img: downloadImg, value: "download" },
  {
    id: v4(),
    title: "Доходы и расходы",
    img: incexpImg,
    value: "incexp",
  },
];

export default function MobileTabList({ addParams, car_id }) {
  return (
    <StyledMobileTabList>
      <ul>
        {list.map((i) => (
          <li key={i.id} onClick={() => addParams("tab", i.value)}>
            <div>
              <img src={i.img} alt={i.title + "-icon"} />
              <p>{i.title}</p>
            </div>
            <i className="icon icon-sm icon-chevron-right"></i>
          </li>
        ))}
      </ul>
    </StyledMobileTabList>
  );
}

const StyledMobileTabList = styled.div`
  background: var(--light);
  box-shadow: 0px 0px 10px var(--primary-dark-5);
  border-radius: 30px;
  padding: 30px 0;
  margin-top: 22px;

  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;

    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 10px;
      padding: 20px 30px;
      cursor: pointer;
      transition: 0.2s linear;

      div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
      }

      i {
        background-color: var(--dark-50);
      }

      &:hover {
        background-color: var(--dark-3);
      }
    }
  }
`;
