import { useState } from "react";
import styled, { css } from "styled-components";
import { Collapse } from "react-collapse";

//
import NumberFormat from "../number-format/NumberFormat";
import { msToNormTime } from "../../util/date";

export default function OperationCollapse({ operation }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <StyledOperationCollapse
      isOpen={isOpen}
      danger={(operation?.operation_sum || 0) < 0}
    >
      <main className="oc__main" onClick={() => setIsOpen((p) => !p)}>
        <section className="oc__left">
          <div className="oc__icon">
            <i
              className={"icon icon-" + operation.operation_type || "tool"}
            ></i>
          </div>
          <div>
            <h1>{operation?.operation_name || "-"}</h1>
            <p>От {msToNormTime(operation?.operation_date, 2)}</p>
          </div>
        </section>
        <section className="oc__right">
          <p>От {msToNormTime(operation?.operation_date, 2)}</p>
          <h4>
            <NumberFormat value={operation?.operation_sum} />
          </h4>
          <i
            className="icon icon-chevron-right"
            style={{ transform: !isOpen ? "rotate(90deg)" : "rotate(-90deg)" }}
          ></i>
        </section>
      </main>
      <Collapse isOpened={isOpen}>
        <ul className="oc__list">
          {(operation?.operation_details || []).map((i, idx) => (
            <InnerItem key={idx} detail={i} />
          ))}
        </ul>
      </Collapse>
    </StyledOperationCollapse>
  );
}

function InnerItem({ detail }) {
  return (
    <StyledInnerItem danger={(detail?.detail_sum || 0) < 0}>
      <section className="inner__left">
        <div className="circle" />
        <div>
          <h1>{detail?.detail_name || "-"}</h1>
          {detail?.detail_period !== undefined && (
            <p>Кол-во дней: {detail?.detail_period}</p>
          )}
        </div>
      </section>
      <section className="inner__right">
        <p>
          <NumberFormat value={detail?.detail_sum} />
        </p>
      </section>
    </StyledInnerItem>
  );
}

const StyledOperationCollapse = styled.div`
  background: var(--light);
  border: 3px solid transparent;
  border-radius: 20px;
  transition: 0.2s linear;

  .oc {
    &__main {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      cursor: pointer;
      padding: 24px 30px;
    }

    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      h1 {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-top: 5px;
        display: none;
      }
    }

    &__icon {
      width: 52px;
      height: 52px;
      border-radius: 50%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      background-color: var(--success-20);

      i {
        background-color: var(--success-dark);
      }

      ${({ danger }) =>
        danger &&
        css`
          background-color: var(--danger-10);

          i {
            background-color: var(--danger-dark);
          }
        `}
    }

    &__right {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 30px;

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
        margin-right: 80px;
      }

      h4 {
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      i {
        background-color: var(--dark-50);
      }
    }

    &__list {
      padding: 0 30px 30px;
    }
  }

  ${({ isOpen }) =>
    isOpen &&
    css`
      border-color: var(--primary);
    `}

  @media (max-width : 768px) {
    border-radius: 20px;

    &:nth-child(2n) {
      background-color: var(--dark-3);
    }

    .oc {
      &__main {
        padding: 20px 30px;
      }

      &__left {
        p {
          display: block;
        }
      }

      &__right {
        p {
          display: none;
        }

        i {
          display: none;
        }
      }
    }
  }
`;

const StyledInnerItem = styled.li`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;

  .inner {
    &__left {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 50px;

      .circle {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--success);
        min-width: 10px;

        ${({ danger }) =>
          danger &&
          css`
            background-color: var(--danger);
          `}
      }

      div {
        h1 {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark-70);
        }
      }
    }

    &__right {
      p {
        font-size: 16px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark-70);
        white-space: nowrap;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 10px 0;
    gap: 10px;

    .inner {
      &__left {
        gap: 20px;
      }
    }
  }
`;
