import React from "react";
import styled, { css } from "styled-components";

export default function CurrencyTab({
  showType,
  setShowType,
  analytic,
  tab,
  position,
}) {
  return (
    <StyledCurTab position={position}>
      <li
        className={showType === "rub" ? "active" : ""}
        onClick={() => setShowType("rub")}
      >
        {analytic && tab === "load" ? "%" : "₽"}
      </li>
      {analytic && tab === "load" ? null : (
        <li
          className={showType === "%" ? "active" : ""}
          onClick={() => setShowType("%")}
        >
          %
        </li>
      )}
    </StyledCurTab>
  );
}

const StyledCurTab = styled.ul`
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 5px;
  padding: 5px;
  background: var(--dark-5);
  border-radius: 20px;

  li {
    width: 40px;
    height: 30px;
    background-color: transparent;
    border-radius: 15px;
    transition: 0.2s linear;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-weight: 600;
    cursor: pointer;

    &.active {
      background: var(--light);
      box-shadow: 0px 0px 4px var(--dark-10);
    }

    i {
      background-color: var(--dark);
    }
  }

  ${({ position }) =>
    position &&
    css`
      position: absolute;
      bottom: -22px;
      left: 50%;
      transform: translate(-50%, 100%);

      @media (max-width: 1200px) {
        transform: translate(-50%, -100%);
        bottom: auto;
        top: -30px;
      }
    `}
`;
