import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cl from "classnames";

//
import HeaderModal from "./HeaderModal";
import Button from "../../../../components/button/Button";
import Loader from "../../../../components/loader/Loader";

//
export default function ApproveModal({
  setShow,
  show,
  handlePostReport,
  isLoading,
  ...props
}) {
  const [input, setInput] = useState("");
  const [wrap, setWrap] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setWrap(show);
  }, [show]);

  function sendMessage(e) {
    setInput(e.target.value);
    setError(false);
  }

  function onSubmit() {
    if (input.length < 10) {
      setError(true);
    } else {
      handlePostReport("declined", input);
    }
  }

  return (
    <StyleApproveModal wrap={wrap ? 1 : 0} {...props}>
      <HeaderModal marginBot="30px" setShow={setShow} />

      <section>
        <p>Просим указать причины, по которым вы отклоняете отчёт</p>
        <div className="input__textarea">
          <textarea
            value={input}
            className={cl("input__area ", {
              active__border: input.length > 9,
              error: error && input.length < 10,
            })}
            cols="30"
            placeholder="Сообщение"
            rows="10"
            onChange={sendMessage}
          >
            {input}
          </textarea>
          {error && <p>Пароль должен быть не меньше 10 символов</p>}
        </div>
      </section>

      <div className="approve__btn">
        <Button
          color="danger"
          p={[16, 30]}
          textUpperCase={1}
          disabled={!input}
          onClick={onSubmit}
        >
          {isLoading && (
            <Loader color="light" bg="danger" w={28} h={28} size={3} />
          )}
          Отклонить
        </Button>
      </div>
    </StyleApproveModal>
  );
}

const StyleApproveModal = styled.div`
  width: 478px;
  border-radius: 30px;
  background-color: var(--light);
  box-shadow: 0px 0px 20px var(--primary-dark-40);
  padding: 43px 24px 30px 24px;
  transition: 0.25s linear;
  transform: translateY(600px);
  transform: ${(props) => props.wrap && "translateY(0px)"};
  & > section {
    & > p {
      max-width: 326px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0em;
      color: var(--dark);
      margin: 0 0 30px 20px;
    }
    & > .input__textarea {
      .input__area {
        width: 100%;
        height: 128px;
        padding: 14px 14px 14px 20px;
        box-sizing: border-box;
        width: 100%;
        height: 128px;
        border: 1px solid var(--primary-dark-15);
        border-radius: 14px;
        resize: none;
        color: var(--dark);
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        transition: 0.2s linear;

        &.error {
          border: 1px solid var(--danger);
        }

        &.active__border {
          border: 1px solid var(--primary);
        }

        &::placeholder {
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark-50);
        }
        &:active,
        :focus {
          outline: none;
        }
      }

      p {
        font-size: 12px;
        font-weight: 400;
        line-height: 15px;
        letter-spacing: 0em;
        padding-left: 10px;
        color: var(--danger);
      }
    }
  }
  .approve__btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-top: 30px;

    &__item {
      background-color: var(--danger);
      opacity: 0.5;
      border-radius: 16px;
      border: none;
      color: var(--light);
      padding: 16px 30px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      letter-spacing: 0em;
      text-transform: uppercase;
      &.active__btn {
        opacity: 1;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 26px 30px 30px 30px;
    & > section {
      & > p {
        max-width: 268px;
        font-size: 15px;
        margin: 0 0 30px 0;
      }
      & > .input__textarea {
      }
    }
    & > .approve__btn {
      justify-content: center;
    }
  }
`;
