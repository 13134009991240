import { months } from "./lists";

// (type = 1) => 01.01.2022 - now
// (type = 2) => last six months
// (type = 3) => current month
export function getFromTo(type) {
  const now = new Date();
  let to =
    new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      23,
      59,
      59
    ).getTime() / 1000;
  let from;

  if (type === 1) {
    from = new Date(2022, 0, 1).getTime() / 1000;
  }

  if (type === 2) {
    from =
      new Date(
        now.getFullYear(),
        now.getMonth() - 5,
        1,
        "00",
        "00",
        "00"
      ).getTime() / 1000;
  }

  if (type === 3) {
    to =
      new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        1,
        "00",
        "00",
        "00"
      ).getTime() /
        1000 -
      1;
    from =
      new Date(
        now.getFullYear(),
        now.getMonth(),
        1,
        "00",
        "00",
        "00"
      ).getTime() / 1000;
  }

  return { to, from };
}

// e.x => сен. 2022
export function getCurrentMonthYear() {
  const now = new Date();

  return `${months[now.getMonth()]
    .slice(0, 3)
    .toLowerCase()}. ${now.getFullYear()}`;
}

// (type = 1) => 1 апреля 2022 в 13:30
// (type = 2) => 01.11.2021 12:00
// (type = 3) => 01.11.2021
// (type = 4) => 6 сен
// (type = 5) => 1 ноя. 2021 12:00
// (type = 6) => Июнь 2022
export function msToNormTime(t, type) {
  if (!t) return "-";
  t = new Date(t * 1000);

  let date = t.getDate();
  let month = t.getMonth();
  let year = t.getFullYear();
  let hour = t.getHours();
  hour = addZeroBefore(hour);
  let min = t.getMinutes();
  min = addZeroBefore(min);

  // 1 апреля 2022 в 13:30
  if (type === 1) {
    return `${date} ${months[month]} ${year} в ${hour}:${min}`;
  }

  // 01.11.2021 12:00
  if (type === 2) {
    month = month + 1;
    month = addZeroBefore(month);
    return `${date}.${month}.${year} ${hour}:${min}`;
  }

  // 01.11.2021
  if (type === 3) {
    month += 1;
    date = addZeroBefore(date);
    month = addZeroBefore(month);
    return `${date}.${month}.${year}`;
  }

  // 6 сен
  if (type === 4) {
    return `${date} ${months[month].slice(0, 3).toLowerCase()}`;
  }

  // 1 ноя. 2021 12:00
  if (type === 5) {
    return `${date} ${months[month]
      .slice(0, 3)
      .toLowerCase()}. ${year} ${hour}:${min}`;
  }

  // Июнь 2022
  if (type === 6) {
    return `${months[month]} ${year}`;
  }
}

function addZeroBefore(v) {
  return v < 10 ? "0" + v : v;
}
