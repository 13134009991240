import React, { useState } from "react";
import styled from "styled-components";

//
import CarTableChart from "../../../../components/car-table-doughunt/CarTableChart";
import {
  useGetDashboardCars,
  useGetCarOperations,
} from "../../../../store/car/hook";
import { getFromTo } from "../../../../util/date";

export default function TableCar() {
  // state
  const [curCar, setCurCar] = useState(null);

  // query
  const { isLoading, data } = useGetDashboardCars();
  let cars = data?.data?.data;
  let { from, to } = getFromTo(3);
  let { isLoading: car_loading, data: car } = useGetCarOperations(
    curCar?.car_id,
    from,
    to,
    !!(curCar?.car_id && from && to)
  );
  car = car?.data?.data;

  // method
  function changeCurCar(car) {
    setCurCar(car);
  }

  return (
    <StyledTableCar>
      <CarTableChart
        car_list={cars?.dash_car_list || []}
        curCar={curCar}
        changeCurCar={changeCurCar}
        car_operation={car}
        loading={isLoading}
        car_oper_loading={car_loading}
        tab="income"
        title="За текущий месяц"
      />
    </StyledTableCar>
  );
}

const StyledTableCar = styled.section``;
