import React from "react";
import styled from "styled-components";
import { v4 } from "uuid";

//
import CarNumber from "../../../../components/car-number/CarNumber";
import ProgressBar from "../../../../components/preogress-bar/ProgressBar";
import Tab from "../../../../components/tab/Tab";
import EmptyText from "../../../../components/epmty-text/EmptyText";
import {
  statusBg,
  statusColor,
  statusTitle,
  stateCarTitle,
} from "../../../../util/lists";

export default function StateModal({ type, changeCurCar, data, changeModal }) {
  // method
  function getPercent(p) {
    if (type === "rented") {
      return p || 0;
    } else {
      return 100;
    }
  }

  return (
    <StyledStateModal color={statusColor[type]} bg={statusBg[type]}>
      <nav>
        <h1>{data?.count || 0}</h1>
        <p dangerouslySetInnerHTML={{ __html: stateCarTitle[type] }} />
      </nav>

      <section className="state__modal__tab">
        <Tab
          options={[
            {
              id: v4(),
              title: "Свободно",
              value: "free",
            },
            {
              id: v4(),
              title: "В аренде",
              value: "rented",
            },
            {
              id: v4(),
              title: "В броне",
              value: "booked",
            },
            {
              id: v4(),
              title: "В сервисе",
              value: "off",
            },
            {
              id: v4(),
              title: "В перегоне",
              value: "peregon",
            },
          ]}
          value={type}
          onChange={changeModal}
        />
      </section>

      {data?.count === 0 ? (
        <EmptyText text='Список пуст' />
      ) : (
        <ul className="state__modal__car__list">
          {data?.statused_car_list.map((i) => (
            <li
              key={i.car_id}
              onClick={() => {
                if (type === "peregon") return;
                changeCurCar(i);
              }}
            >
              <section>
                <ProgressBar
                  vertical={1}
                  value={getPercent(i.progress)}
                  bg={statusColor[type]}
                />
                <div>
                  <h1>{i.brand_model || "Car name"}</h1>
                  <CarNumber title={i.gos_num} size="sm" />
                </div>
              </section>
              <section>
                <p
                  className="type__indicator"
                  dangerouslySetInnerHTML={{ __html: statusTitle[type] }}
                />
                {type !== "transit" && (
                  <i className="icon icon-chevron-right"></i>
                )}
              </section>
            </li>
          ))}
        </ul>
      )}
    </StyledStateModal>
  );
}

const StyledStateModal = styled.div`
  padding-bottom: 30px;

  nav {
    padding: 30px 60px 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 20px;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 40px;

    h1 {
      font-size: 40px;
      font-weight: 600;
      line-height: 49px;
      letter-spacing: 0em;
      color: var(--dark);
    }

    p {
      font-size: 14px;
      font-weight: 400;
      line-height: 17px;
      letter-spacing: 0em;
      color: var(--dark-70);
    }
  }

  .state__modal__tab {
    padding: 0 30px;
    margin-bottom: 30px;
  }

  .state__modal__car__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    padding: 0 30px;

    li {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: justify;
      -ms-flex-pack: justify;
      justify-content: space-between;
      padding: 25px 20px;
      cursor: pointer;
      transition: 0.2s linear;
      border-radius: 20px;

      &:hover {
        background-color: var(--dark-3);
      }

      section {
        &:nth-child(1) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 20px;

          & > div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 15px;
          }

          h1 {
            font-size: 18px;
            font-weight: 500;
            line-height: 22px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }

        &:nth-child(2) {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 20px;

          p {
            color: ${({ color }) => `var(--${color})`};
            background-color: ${({ bg }) => `var(--${bg})`};
            padding: 6px 16px;
            border-radius: 16px;
            font-size: 14px;
            font-weight: 600;
            line-height: 17px;
            letter-spacing: 0em;
            text-transform: uppercase;
          }

          i {
            background-color: var(--dark);
            opacity: 0.5;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: 0;

    nav {
      background-color: var(--light);
      padding: 30px;
      background: var(--light);
      box-shadow: 0px 0px 10px var(--primary-dark-5);
      border-radius: 0px 0px 30px 30px;
      margin-bottom: 20px;
    }

    .state__modal__tab {
      background: var(--light);
      box-shadow: 0px 0px 10px var(--primary-dark-5);
      border-radius: 30px;
      padding: 30px;
    }

    .state__modal__car__list {
      background: var(--light);
      box-shadow: 0px 0px 10px var(--primary-dark-5);
      border-radius: 30px;
      padding: 30px 0;

      li {
        padding: 17px 30px;

        section {
          &:nth-child(1) {
            & > div {
              flex-direction: column;
              align-items: flex-start;
              gap: 5px;

              h1 {
                font-size: 16px;
                line-height: 20px;
              }
            }
          }

          &:nth-child(2) {
            p {
              font-size: 10px;
              line-height: 12px;
            }

            i {
              display: none;
            }
          }
        }
      }
    }
  }
`;
