import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";

// layout
import CabinetLayout from "../layout/cabinet/CabinetLayout";

// page
import Dashboard from "../module/cabinet/dashboard/Dashboard";
import RentBook from "../module/cabinet/rent-book/RentBook";
import CarParking from "../module/cabinet/car-parking/CarParking";
import Notification from "../module/cabinet/notification/Notification";
import NotifItem from "../module/cabinet/notification/NotifItem.js";
import Analytic from "../module/cabinet/analytic/Analytic";
import Doc from "../module/cabinet/doc/Doc";
import DocItem from "../module/cabinet/doc/OneDoc";
import Login from "../module/auth/login/Login";
import { useAppContext } from "../store/Context";

export default function Router() {
  const {
    state: { isAuth },
  } = useAppContext();

  if (!isAuth)
    return (
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    );

  return (
    <Routes>
      <Route path="cabinet" element={<CabinetLayout />}>
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="analytics" element={<Analytic />} />
        <Route path="rent-book" element={<RentBook />} />
        <Route path="parking" element={<CarParking />} />
        <Route path="doc" element={<Doc />} />
        <Route path="doc/:id" element={<DocItem />} />
        <Route path="notif" element={<Notification />} />
        <Route path="notif/:id" element={<NotifItem />} />
      </Route>
      <Route path="*" element={<Navigate to="/cabinet/dashboard" />} />
    </Routes>
  );
}
