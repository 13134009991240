import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

//
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import NumberFormat from "../../../../components/number-format/NumberFormat";
import AlertIcon from "../../../../components/alert-icon/AlertIcon";
import { getCurrentMonthYear, msToNormTime } from "../../../../util/date";
import { useGetDashboardProfits } from "../../../../store/dash-profit/hook";

export default function ProfitDownload() {
  // helper
  const navigate = useNavigate();

  // query
  let { isLoading, data } = useGetDashboardProfits();
  data = data?.data?.data;

  // method
  function goToDocs() {
    navigate("/cabinet/doc", {
      state: {
        data: "act",
      },
    });
  }

  return (
    <StyledProfitDownload>
      <Card mobile={true}>
        {isLoading && <Loader />}
        <ul className="pd__list">
          <li className="pd__item">
            <div className="pd__card profit">
              <section>
                <i className="icon icon-lg icon-currency-ruble"></i>
              </section>
              <section>
                <h1>
                  <NumberFormat value={data?.profit} />
                </h1>
                <p>Текущая прибыль за {getCurrentMonthYear()}</p>
              </section>
              <div className="alert__wrapper">
                <AlertIcon
                  text="Disclaimer: это предварительный расчёт, по итогам месяца сумма может измениться. 
                        Прибыль считается так: (выручка с аренды и перепробега) -  (комиссия по договору) -  (расходы, которые ложатся на партнера).
                        Фактически, из прибыли формируется будущая выплата.
                        "
                />
              </div>
            </div>
          </li>
          <li className="pd__item">
            <div className="pd__card download">
              <section>
                <i className="icon icon-lg icon-circle-line"></i>
              </section>
              <section>
                <h1>{data?.avg_load || 0}%</h1>
                <p>Сред. загрузка автопарка </p>
              </section>
            </div>
          </li>
          <li className="pd__item">
            <div className="pd__card saldo">
              <section>
                <i className="icon icon-lg icon-flip-forward-right"></i>
              </section>
              <section>
                <h1>
                  <NumberFormat value={data?.saldo} />
                </h1>
                <p>Сальдо на {msToNormTime(data?.saldo_date, 4)}</p>
              </section>
              <section onClick={goToDocs}>
                <i className="icon icon-chevron-right"></i>
              </section>
            </div>
          </li>
        </ul>
      </Card>
    </StyledProfitDownload>
  );
}

const StyledProfitDownload = styled.section`
  margin-top: 20px;

  .pd {
    &__list {
      padding: 30px;
      display: -ms-grid;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
    }

    &__item {
      position: relative;
    }

    &__card {
      border-radius: 30px;
      padding: 20px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      height: 100%;
      min-height: 150px;

      & > section {
        &:nth-child(2) {
          h1 {
            font-size: 30px;
            font-weight: 600;
            line-height: 37px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
          }
        }
      }

      &.profit {
        background: var(--success-15);
        position: relative;
        padding-left: 90px;
        position: relative;

        .alert__wrapper {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        section {
          &:nth-child(1) {
            background-color: var(--success);
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            border-radius: 30px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            width: 70px;
          }
        }
      }

      &.download {
        background: var(--light);
        border: 2px solid var(--success);

        section {
          &:nth-child(1) {
            width: 70px;
            height: 110px;
            background-color: var(--success-15);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            border-radius: 20px;
            margin-right: 30px;

            i {
              background-color: var(--success-dark);
            }
          }
        }
      }

      &.saldo {
        border: 2px solid var(--primary);
        border-radius: 30px;

        section {
          &:nth-child(1) {
            width: 70px;
            height: 110px;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            background-color: var(--primary-15);
            border-radius: 20px;
            margin-right: 30px;

            i {
              background-color: var(--primary);
            }
          }

          &:nth-child(3) {
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid var(--primary-dark-15);
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            justify-content: center;
            cursor: pointer;
            margin-left: auto;

            i {
              background-color: var(--dark);
            }
          }
        }
      }
    }
  }

  @media (max-width: 1200px) {
    .pd {
      &__list {
        grid-template-columns: 1fr;
      }
    }
  }
  @media (max-width: 768px) {
    .pd {
      &__card {
        min-height: 110px;
        padding: 0 20px 0 0;

        section {
          &:nth-child(1) {
            width: 60px !important;
          }

          &:nth-child(2) {
            h1 {
              font-size: 26px;
              line-height: 31px;
              margin-bottom: 0px;
            }

            p {
              font-size: 14px;
              line-height: 17px;
            }
          }
        }
      }
    }
  }
`;
