import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Map, Placemark } from "react-yandex-maps";

//
import StateCarCard from "./StateCarCard";
import Button from "../../../../components/button/Button";
import location from "../../../../asset/img/location.svg";

export default function FreeModal({ car }) {
  const [isDanger, setIsDanger] = useState(false);

  useEffect(() => {
    const hour = new Date().getHours();
    if (hour > 8 && hour < 22) {
      setIsDanger(false);
    } else {
      setIsDanger(true);
    }
  }, []);

  // render
  if (!(car && car.status === "free")) return null;

  return (
    <StyledFreeModal isDanger={isDanger}>
      <main>
        <section className="top__state__wrap">
          <StateCarCard
            icon="icon-home"
            title="Автомобиль свободен"
            color="primary"
          />
        </section>
        <section className="info__location__car">
          <h1 id="itsh1">Автомобиль находится на точке</h1>
          <div className="location__wrap">
            <div>
              <img src={location} alt="location" />
              <p>{car?.point_address || "-"}</p>
            </div>
            <p className="working__time">
              Режим работы: <span>{car?.point_working_hours}</span>
            </p>
          </div>
        </section>
      </main>
      <footer>
        <section className="btns__wrap">
          <a href={"tel:" + (car?.point_phone_num || "+7 (777) 777 77-77")}>
            <Button>
              <i className="icon icon-sm icon-phone"></i>{" "}
              {car?.point_phone_num || "-"}
            </Button>
          </a>
        </section>
        <Map
          defaultState={{
            center: [car?.latitude || 55.684758, car?.longitude || 37.738521],
            zoom: 15,
          }}
        >
          <Placemark
            geometry={[car?.latitude || 55.684758, car?.longitude || 37.738521]}
          />
        </Map>
      </footer>
    </StyledFreeModal>
  );
}

const StyledFreeModal = styled.div`
  padding: 30px;
  min-height: calc(100vh - 152px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--light);

  main {
    section {
      &.top__state__wrap {
        margin-bottom: 60px;
      }

      &.info__location__car {
        padding: 0 20px;

        h1 {
          font-size: 22px;
          font-weight: 500;
          line-height: 27px;
          letter-spacing: 0em;
          margin-bottom: 30px;
          color: var(--dark);
        }

        .location__wrap {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;

          .working__time {
            background-color: var(
              --${({ isDanger }) => (isDanger ? "danger-10" : "success-15")}
            );
            color: var(
              --${({ isDanger }) => (isDanger ? "danger" : "success-dark")}
            );
            font-size: 14px;
            font-weight: 500;
            line-height: 17px;
            letter-spacing: 0em;
            padding: 6px 16px;
            border-radius: 16px;

            span {
              font-weight: 600;
            }
          }

          div {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            gap: 30px;

            p {
              font-size: 16px;
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0em;
              color: var(--dark-70);
              max-width: 250px;
            }
          }

          .icons__wrap {
            display: none;
          }
        }
      }
    }
  }

  footer {
    margin-top: 40px;

    section {
      &.btns__wrap {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 20px;
        margin-bottom: 20px;

        i {
          background-color: var(--dark);
        }
      }

      img {
        width: 100%;
      }
    }

    & > div {
      width: 100% !important;
      height: 400px !important;
      border-radius: 30px;
      overflow: hidden;

      /* .ymaps-2-1-79-gotoymaps, */
      .ymaps-2-1-79-gototech,
      .ymaps-2-1-79-gototaxi,
      .ymaps-2-1-79-copyright.ymaps-2-1-79-copyright_logo_no {
        visibility: hidden !important;
      }
    }
  }

  @media (max-width: 768px) {
    padding: 30px;
    min-height: calc(100vh - 54px);

    main {
      section {
        &.info__location__car {
          padding: 0;

          h1 {
            font-size: 16px;
            line-height: 20px;
            text-align: center;
          }

          .location__wrap {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            gap: 20px;
            /* display: -ms-grid;
            display: grid;
            grid-template-columns: 1fr;
            gap: 40px; */

            /* .icons__wrap {
              display: -webkit-box;
              display: -ms-flexbox;
              display: flex;
              -webkit-box-align: center;
              -ms-flex-align: center;
              align-items: center;
              gap: 10px;

              .icon__wrap {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                border: 1px solid var(--primary-dark-15);
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                -webkit-box-pack: center;
                -ms-flex-pack: center;
                justify-content: center;
                cursor: pointer;

                i {
                  background-color: var(--dark);
                }
              }
            } */
          }
        }
      }
    }

    footer {
      & > div {
        height: 300px !important;
      }
    }
  }
`;
