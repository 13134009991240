import React from "react";
import styled from "styled-components";

//
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";

export default function TarifCard({ title = "-", all = 0, day = 0, booking }) {
  const { width } = useWindowDimensions();

  return (
    <StyledTarifCard>
      <div>
        <h1>{title}</h1>
        <p>Тариф</p>
      </div>
      <div>
        {width < 769 ? (
          <p>
            {booking ? `Срок аренды ${all} дн.` : `${day}/${all} дн. осталось`}
          </p>
        ) : (
          <>
            <h1>
              {booking ? all : day}
              <span>{booking ? "дн." : "/" + all}</span>
            </h1>
            <p>{booking ? "Срок аренды" : "Осталось дней"}</p>
          </>
        )}
      </div>
    </StyledTarifCard>
  );
}

const StyledTarifCard = styled.div`
  background-color: var(--dark-3);
  border: 1px solid var(--primary-dark-15);
  border-radius: 30px;
  padding: 30px 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 30px;

  div {
    &:nth-child(1) {
      h1 {
        font-size: 30px;
        font-weight: 600;
        line-height: 37px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 10px;
      }

      p {
        font-size: 18px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        color: var(--dark-70);
        text-transform: uppercase;
      }
    }
    &:nth-child(2) {
      border-left: 1px solid var(--primary-dark-15);
      text-align: center;
      padding: 10px 0 10px 40px;

      h1 {
        font-size: 40px;
        font-weight: 600;
        line-height: 48px;
        letter-spacing: 0em;
        color: var(--dark);
        margin-bottom: 10px;

        span {
          font-size: 20px;
          line-height: 24px;
        }
      }

      p {
        font-size: 14px;
        font-weight: 400;
        line-height: 17px;
        letter-spacing: 0em;
        color: var(--dark-70);
        text-transform: uppercase;
      }
    }
  }

  @media (max-width: 768px) {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;
    padding: 40px;

    div {
      &:nth-child(1) {
        text-align: center;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;

        h1 {
          font-size: 26px;
          line-height: 31px;
          margin-bottom: 0;
        }

        p {
          font-size: 14px;
          line-height: 17px;
        }
      }

      &:nth-child(2) {
        border: none;
        text-align: center;
        padding: 0;

        p {
          font-family: Montserrat;
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          text-transform: none;
        }
      }
    }
  }
`;
