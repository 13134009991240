import React from "react";
import styled from "styled-components";

//
import TarifCard from "./TarifCard";
import PregressSection from "../../../../components/rent-book-progress/PregressSection";
import PayCard from "../../../../components/pay-card/PayCard";
import InfoCard from "./InfoCard";

export default function BookingModal({ car }) {
  // render
  if (!(car && car.status === "booked")) return null;

  return (
    <StyledBookingModal>
      <main>
        <section className="tarif__card__wrap">
          <TarifCard
            title={car?.tariff || "-"}
            all={car?.rent_days_total || "0"}
            booking={1}
          />
        </section>
        <section className="prg__date__wrap">
          <PregressSection
            start={car?.rent_start}
            end={car?.rent_end}
            bg="warning"
            booked={1}
          />
        </section>
        <section className="parofit__pay__sect">
          <PayCard
            title={car?.rent_price_total}
            text="Прогноз дохода"
            icon="icon-currency-ruble"
            color="warning"
          />
          <PayCard
            title={car?.rent_price_day}
            text="Платеж в сутки"
            icon="icon-wallet"
            color="primary"
          />
        </section>
      </main>
      <footer>
        <InfoCard
          icon="icon-calendar"
          color="primary"
          title="Дата бронирования"
          text={car?.book_date}
        />
        <InfoCard
          icon="icon-car"
          color="success"
          title="Дата выдачи автомобиля"
          text={car?.rent_start}
        />
      </footer>
    </StyledBookingModal>
  );
}

const StyledBookingModal = styled.div`
  padding: 30px;
  min-height: calc(100vh - 152px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background-color: var(--light);

  main {
    section {
      &.tarif__card__wrap {
        margin-bottom: 60px;
      }

      &.prg__date__wrap {
        margin-bottom: 60px;
        padding: 0 20px;
      }

      &.parofit__pay__sect {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        padding: 0 20px;
      }
    }
  }

  footer {
    margin-top: 80px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 20px;
  }

  @media (max-width: 768px) {
    padding: 30px;
    min-height: calc(100vh - 54px);

    main {
      section {
        &.prg__date__wrap {
          padding: 0;
        }

        &.parofit__pay__sect {
          flex-direction: column;
          gap: 20px;
          padding: 0;
        }
      }
    }
  }
`;
