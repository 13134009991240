import api from "../../service/axios";

class CarApi {
  static getCarsStatusesCount() {
    let url = "/getCarsStatusesCount";
    return api.get(url);
  }
  static getCarsByStatus(type) {
    let url = "/getCarListByStatus/" + type;
    return api.get(url);
  }
  static getCarByStatus(type, id) {
    const obj = {
      free: "getFreeCar",
      rented: "getRentedCar",
      booked: "getBookedCar",
      off: "getOffCar",
    };

    let url = `/${obj[type]}/${id}`;

    return api.get(url);
  }

  static getDashCars() {
    let url = "/getDashCars";

    return api.get(url);
  }

  static getCarOperation(id, from, to) {
    let url = `/getCarOperations/${id}/${from}/${to}`;

    return api.get(url);
  }
}

export default CarApi;
