import React from "react";
import styled, { css } from "styled-components";

export default function CarNumber({
  title = "А000AA000",
  size,
  minW,
  mobileSize,
}) {
  function getMain(n) {
    n = n.split("").slice(0, 6);
    n.splice(1, 0, " ");
    n.splice(5, 0, " ");

    return n;
  }

  function getSup(n) {
    return n.split("").slice(6);
  }

  return (
    <StyledCarNumber size={size} minW={minW} mobileSize={mobileSize}>
      {getMain(title)} <sup>{getSup(title)}</sup>
    </StyledCarNumber>
  );
}

const StyledCarNumber = styled.div`
  display: inline-block;
  background: var(--light);
  border: 1px solid var(--primary-dark-15);
  border-radius: 6px;
  padding: 1px 6px 4px;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0em;
  color: var(--dark-70);
  white-space: nowrap;
  text-align: center;

  ${({ size }) =>
    size === "lg"
      ? css`
          font-size: 16px;
          line-height: 20px;
        `
      : size === "sm"
      ? css`
          font-size: 12px;
          line-height: 15px;
        `
      : null}

  ${({ minW }) =>
    minW &&
    css`
      min-width: ${minW + "px"};
    `}

  @media (max-width: 768px) {
    font-size: 12px;
    line-height: 15px;

    ${({ mobileSize }) =>
      mobileSize === "lg" &&
      css`
        font-size: 16px;
        line-height: 20px;
      `}
  }
`;
