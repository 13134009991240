import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cl from "classnames";
import { useMutation } from "react-query";

//
import ProgressSection from "../../../../components/rent-book-progress/PregressSection";
import Card from "../../../../components/card/Card";
import Loader from "../../../../components/loader/Loader";
import NotifApi from "../../../../store/notif/api";
import { msToNormTime } from "../../../../util/date";

export default function Osago({ osago, load, car }) {
  // state
  const [color, setColor] = useState("warning");

  // query
  const mutation = useMutation((body) => NotifApi.postNotif(body));

  // watcher
  useEffect(() => {
    if (!osago) return;
    const now = Date.now() / 1000;
    let dif = Math.floor((osago.date_end - now) / 86400);
    if (dif > 14) {
      setColor("success");
    } else if (dif > 7) {
      setColor("warning");
    } else {
      setColor("danger");
      postNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [osago]);

  // method
  function postNotification() {
    let body = {
      car_id: car.car_id,
      type: "file-shield",
      name: "Кончается ОСАГО",
      note:
        "У автомобиля кончается ОСАГО через 7 дней - " +
        car.brand_model +
        " " +
        car.gos_num,
      read: false,
    };
    mutation.mutateAsync(body);
  }

  return (
    <StyledOsago>
      <section className="osago__info">
        <Card mobile={1}>
          {load && <Loader />}
          <ul>
            <li>
              <h1>Серия и номер</h1>
              <p>{osago?.num || "-"}</p>
            </li>
            <li>
              <h1>Страховая компания </h1>
              <p>{osago?.company || "-"}</p>
            </li>
          </ul>
        </Card>
      </section>
      <section className="osago__progress">
        <Card mobile={1}>
          {load && <Loader />}
          <div className="osago__progress__content">
            <div
              className={cl("osago__alert", {
                danger: color === "danger",
                warning: color === "warning",
              })}
            >
              <div className="i__alert__wrap">
                <i
                  className={cl("icon", {
                    "icon-alert-circle":
                      color === "danger" || color === "warning",
                    "icon-check": color === "success",
                  })}
                ></i>
              </div>
              <p>
                {color === "success" ? (
                  <>
                    Страховой полис ОСАГО действителен до{" "}
                    {msToNormTime(osago?.date_end, 3)} г.
                  </>
                ) : (
                  <>
                    Страховой полис ОСАГО заканчивается{" "}
                    {msToNormTime(osago?.date_end, 3)} г. необходимо продлить
                    его.
                  </>
                )}
              </p>
            </div>
            <ProgressSection
              start={osago?.date_start || 1646946000}
              end={osago?.date_end || 1678395600}
              bg={color}
              startText="Дата заключения"
              endText="Дата окончания"
            />
          </div>
        </Card>
      </section>
    </StyledOsago>
  );
}

const StyledOsago = styled.div`
  display: -ms-grid;
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 20px;

  .osago {
    &__info {
      ul {
        padding: 60px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 20px;
        li {
          h1 {
            font-size: 20px;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          p {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark-70);
          }
        }
      }
    }

    &__progress {
      &__content {
        padding: 30px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        gap: 30px;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        height: 100%;
      }
    }

    &__alert {
      background-color: var(--success-20);
      border-radius: 20px;
      padding: 15px 30px 15px 15px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      gap: 20px;

      .i__alert__wrap {
        background-color: var(--light);
        width: 40px;
        height: 40px;
        min-width: 40px;
        border-radius: 50%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;

        i {
          background-color: var(--success-dark);
        }
      }

      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--success-dark);
      }

      &.danger {
        background-color: var(--danger-10);

        .i__alert__wrap {
          i {
            background-color: var(--danger-dark);
          }
        }

        p {
          color: var(--danger);
        }
      }

      &.warning {
        background-color: var(--warning-10);

        .i__alert__wrap {
          i {
            background-color: var(--warning-dark);
          }
        }

        p {
          color: var(--warning-dark);
        }
      }
    }
  }

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media (max-width: 768px) {
    .osago {
      &__info {
        ul {
          padding: 30px 40px;
          gap: 24px;
        }
      }
    }
  }
`;
