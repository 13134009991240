import api from "../../service/axios";
class DashProfitApi {
  static getDashboardProfitInfos() {
    let url = "/getMainDashNumbers";

    return api.get(url);
  }
}

export default DashProfitApi;
