import React, { useState } from "react";
import styled, { css } from "styled-components";
import cl from "classnames";
import { Collapse } from "react-collapse";
import { useNavigate } from "react-router-dom";

//
import Card from "../../../../components/card/Card";
import IconWrapper from "../../../../components/icon-wrapper/IconWrapper";
import Loader from "../../../../components/loader/Loader";
import EmptyText from "../../../../components/epmty-text/EmptyText";
import DocumentModal from "../../../../components/doc-modal/DocumentModal";
import Modal from "../../../../components/modal/Modal";
import Consts from "../../../../util/const";
import { useWindowDimensions } from "../../../../hooks/useWindowDimensions";
import { msToNormTime } from "../../../../util/date";

export default function NotifList({
  title,
  list = [],
  isLoading,
  onPutNotifAsRead,
  notif__cur,
  setNotif,
  staticReads = [],
}) {
  // helper
  const { width } = useWindowDimensions();
  const navigate = useNavigate();

  // state
  const [modal, setModal] = useState(false);

  // method
  function handleNotif(n) {
    if (n.type === "report") {
      localStorage.setItem(Consts.DOC_DATE, n.date);
      navigate("/cabinet/doc/" + n.notification_id);
    } else {
      setNotif((p) => (p === n.notification_id ? null : n.notification_id));
      if (n.read || staticReads.includes(n.notification_id)) return;
      onPutNotifAsRead(n.notification_id);
    }
  }

  return (
    <StyledNotifList isLoading={isLoading}>
      <nav>
        <h1>{title}</h1>
      </nav>
      <Card>
        {isLoading && <Loader />}
        {list.length > 0 ? (
          <ul>
            {list.map((i) => (
              <li
                className={cl("notif__item", {
                  active: notif__cur === i.notification_id,
                })}
                key={i.notification_id}
                onClick={() => handleNotif(i)}
              >
                <div
                  className="hidden__el"
                  id={"notif-item-" + i.notification_id}
                ></div>
                <div className="item__main">
                  <section className="notif__item__left">
                    <div>
                      <IconWrapper
                        icon={"shield " + (width > 768 ? "icon-lg" : "")}
                        w={width > 768 ? 60 : 40}
                        h={width > 768 ? 60 : 40}
                        rounded={14}
                        bg={
                          i.read || staticReads.includes(i.notification_id)
                            ? "success-20"
                            : "success"
                        }
                        color={
                          i.read || staticReads.includes(i.notification_id)
                            ? "success-dark"
                            : "light"
                        }
                        redCircle={
                          !(i.read || staticReads.includes(i.notification_id))
                        }
                      />
                      <div>
                        <h1>Одобрить отчёт</h1>
                        <p>От {msToNormTime(i.date, 2)}</p>
                      </div>
                    </div>
                    <i
                      className="icon icon-chevron-right"
                      style={
                        notif__cur === i.notification_id
                          ? { transform: "rotate(90deg)" }
                          : {}
                      }
                    ></i>
                  </section>
                  <section className="notif__item__right">
                    <div>
                      <h1>{i.name || "-"}</h1>
                      <p title={i.note}>{i.note || "-"}</p>
                    </div>
                    <i className="icon icon-chevron-right"></i>
                  </section>
                </div>
                <Collapse isOpened={notif__cur === i.notification_id}>
                  <p className="item__collapse">{i.note}</p>
                </Collapse>
              </li>
            ))}
          </ul>
        ) : (
          <EmptyText />
        )}
      </Card>
      <Modal
        title="АКТ"
        isOpen={modal}
        onClose={() => setModal(false)}
        topSm={1}
      >
        <DocumentModal act={{}} />
      </Modal>
    </StyledNotifList>
  );
}

const StyledNotifList = styled.div`
  nav {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 20px;
    margin-bottom: 30px;

    h1 {
      font-size: 26px;
      font-weight: 600;
      line-height: 32px;
      letter-spacing: 0em;
      color: var(--dark);
    }
  }

  ul {
    padding: 30px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 10px;

    ${({ isLoading }) =>
      isLoading &&
      css`
        min-height: 300px;
      `}

    .notif__item {
      border: 3px solid transparent;
      border-radius: 20px;
      position: relative;

      .hidden__el {
        width: 1px;
        height: 1px;
        position: absolute;
        top: -90px;
        left: 0;
        pointer-events: none;
      }

      &.active {
        border-color: var(--primary);
      }

      .item__main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        gap: 40px;
        cursor: pointer;
        padding: 20px;
      }

      .item__collapse {
        padding: 10px 30px 20px;
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        color: var(--dark);
      }

      &__left {
        padding-right: 83px;
        border-right: 1px solid var(--primary-dark-15);

        & > div {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          gap: 30px;

          h1 {
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0em;
            color: var(--dark);
            margin-bottom: 5px;
          }

          p {
            font-size: 14px;
            font-weight: 400;
            line-height: 17px;
            letter-spacing: 0em;
            color: var(--dark-30);
          }
        }

        & > i {
          display: none;
        }
      }

      &__right {
        flex-grow: 1;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;

        h1 {
          font-size: 16px;
          font-weight: 600;
          line-height: 20px;
          letter-spacing: 0em;
          color: var(--dark);
          margin-bottom: 5px;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 17px;
          letter-spacing: 0em;
          color: var(--dark-50);
          width: 320px;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }

        & > i {
          background-color: var(--dark-50);
        }
      }
    }
  }

  @media (max-width: 768px) {
    nav {
      display: none;
    }

    ul {
      padding: 0;

      .notif__item {
        background-color: var(--light);
        box-shadow: 0px 0px 10px var(--dark-5);
        border-radius: 30px;

        .item__main {
          padding: 20px 30px;

          flex-direction: column;
          align-items: flex-start;
          gap: 20px;
        }

        &__left {
          width: 100%;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
          -ms-flex-align: center;
          align-items: center;
          -webkit-box-pack: justify;
          -ms-flex-pack: justify;
          justify-content: space-between;
          padding-right: 0;
          padding-bottom: 23px;
          border: none;
          border-bottom: 1px solid var(--primary-dark-15);

          & > i {
            display: inline-block;
            background-color: var(--dark-50);
          }
        }

        &__right {
          i {
            display: none;
            width: 100%;
          }
        }
      }
    }
  }
`;
